export const JobsListFields = [
	'id',
	'client',
	'title',
	'method',
	'questionnaires',
	'interview',
	'applicationsNumber',
	'startDate',
	'jobClass',
	'interviewsNumber',
	'questionnairesNumber',
];
