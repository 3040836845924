import React, { type FC, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Spin } from 'antd';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FormSwitch from 'components/FormSwitch';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { GenericType } from 'types';

import { Styled } from '../ATSSettings.styled';
import { SettingsPanelMenuList } from '../ATSSettings.types';

export type ATSAutoPopProps = {
	autoPop: boolean;
	getClientAutoPop: () => void;
	updateClientAutoPop: (values: boolean) => void;
	loading: GenericType;
};

const ATSAutoPop: FC<ATSAutoPopProps> = ({
	autoPop,
	getClientAutoPop,
	updateClientAutoPop,
	loading,
}) => {
	const onSubmit = (values: boolean) => {
		updateClientAutoPop(values);
	};

	useMount(() => {
		getClientAutoPop();
	});

	const initialValues = useMemo(
		() => ({
			autoPop,
		}),
		[autoPop],
	);

	const isUpdating = !!loading?.updateClientAutoPopLoad;

	return (
		<Styled.Root>
			{loading.getClientAutoPopLoad ? (
				<Spinner fullWidth />
			) : (
				<>
					<Form
						onSubmit={onSubmit}
						initialValues={initialValues}
						render={({ handleSubmit, dirty }) => (
							<form onSubmit={handleSubmit}>
								<Box noSpaces fullWidth style={{ flex: '1 1 auto' }}>
									<h2>{SettingsPanelMenuList.AutoPop}</h2>
									<p>
										After rejecting an application, the system can automaticaly pop-up the next
										candidate for faster sorting.
									</p>
									<FormBlockGrid columns={1}>
										<Field
											name={'autoPop'}
											initialValue={initialValues.autoPop}
											style={{ marginBottom: '24px' }}
										>
											{({ input }) => <FormSwitch {...input} title='Auto-Pop' />}
										</Field>
									</FormBlockGrid>
								</Box>

								<Styled.ButtonWrapper>
									<ThemedButton
										buttonType={ButtonTypes.primary}
										type='submit'
										disabled={isUpdating}
									>
										Save
										{isUpdating ? <Spin style={{ marginLeft: '12px' }} /> : ''}
									</ThemedButton>
								</Styled.ButtonWrapper>
							</form>
						)}
					/>
				</>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		autoPop: atsDucks.atsSelectors.getClientAutoPop(state)?.autoPop,
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getClientAutoPop: atsDucks.atsActions.getClientAutoPopRequested,
		updateClientAutoPop: atsDucks.atsActions.updateClientAutoPopRequested,
	},
)(ATSAutoPop);
