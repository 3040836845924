import { message } from 'antd';
import Cookies from 'js-cookie';
import { pick, omit } from 'lodash';
import { all, call, put, select } from 'redux-saga/effects';

import API from 'api';
import { atsDucks } from 'modules/ATS/ducks/atsDucks';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, MESSAGES } from 'modules/Common/constants';
import {
	AtsBrandingAllowedFields,
	CareerPageBrandingAllowedFields,
} from 'modules/Common/constants/clientChangesForm.constants';
import { AllowedJobFields } from 'modules/Common/constants/postJobChangesForm';
import { JobClassesEnum } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CookiesType } from 'types';
import { LocalStorageType } from 'types/storage';

export const createSagas = (TYPES) => {
	function* getCurrentSubscriptionSaga({ callback }) {
		try {
			const currentSubscription = yield call(API.subscriptionsService.getCurrentSubscriptionInfo);

			yield put({ type: TYPES.GET_CLIENT_SUBSCRIPTION.SUCCESS, payload: currentSubscription });
			if (callback) {
				yield call(callback, {
					isPremiumPlan: currentSubscription.premium || currentSubscription.trial,
				});
			}
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_SUBSCRIPTION.FAILED, payload: error });
		}
	}

	function* getClientSocialNetworksSaga() {
		try {
			const socialNetworkData = yield call(API.socialNetworksService.getATSClientSocialNetwork);

			yield put({ type: TYPES.GET_CLIENT_SOCIAL_NETWORKS.SUCCESS, payload: socialNetworkData });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_SOCIAL_NETWORKS.FAILED, payload: error });
		}
	}

	function* updateClientSocialNetworksSaga({ payload, callback }) {
		try {
			yield call(API.socialNetworksService.updateATSClientSocialNetwork, payload);

			yield put({ type: TYPES.UPDATE_CLIENT_SOCIAL_NETWORKS.SUCCESS });

			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CLIENT_SOCIAL_NETWORKS.FAILED, payload: error });
		}
	}

	function* getClientEmailSignatureSaga() {
		try {
			const data = yield call(API.settingsService.getClientEmailSignature);

			yield put({ type: TYPES.GET_CLIENT_EMAIL_SIGNATURE.SUCCESS, payload: data });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_EMAIL_SIGNATURE.FAILED, payload: error });
		}
	}

	function* updateClientEmailSignatureSaga({ payload }) {
		try {
			yield call(API.settingsService.updateClientEmailSignature, payload);

			yield put({ type: TYPES.UPDATE_CLIENT_EMAIL_SIGNATURE.SUCCESS });

			yield message.success(MESSAGES.successfullyUpdated);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CLIENT_EMAIL_SIGNATURE.FAILED, payload: error });
		}
	}

	function* getClientAutoPopSaga() {
		try {
			const autoPopData = yield call(API.settingsService.getClientsAutoPop);

			yield put({ type: TYPES.GET_CLIENT_AUTO_POP.SUCCESS, payload: autoPopData });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_AUTO_POP.FAILED, payload: error });
		}
	}

	function* updateClientAutoPopSaga({ payload }) {
		try {
			yield call(API.settingsService.updateClientsAutoPop, payload);

			yield put({ type: TYPES.UPDATE_CLIENT_AUTO_POP.SUCCESS });

			yield call(message.success, MESSAGES.successfullyUpdated);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CLIENT_AUTO_POP.FAILED, payload: error });
		}
	}

	function* getClientTwoFASaga() {
		try {
			const twoFAData = yield call(API.settingsService.getClientsTwoFA);

			yield put({ type: TYPES.GET_CLIENT_2FA.SUCCESS, payload: twoFAData });
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_2FA.FAILED, payload: error });
		}
	}

	function* updateClientTwoFASaga({ payload }) {
		try {
			yield call(API.settingsService.updateClientsTwoFA, payload);

			yield put({ type: TYPES.UPDATE_CLIENT_2FA.SUCCESS });

			yield call(message.success, MESSAGES.successfullyUpdated);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CLIENT_2FA.FAILED, payload: error });
		}
	}

	function* getClientSMSNotificationsSaga({ payload }) {
		try {
			const smsNotificationsData = yield call(
				API.settingsService.getClientApplicationNotifications,
				payload,
			);

			yield put({
				type: TYPES.GET_CLIENT_SMS_NOTIFICATIONS.SUCCESS,
				payload: smsNotificationsData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_CLIENT_SMS_NOTIFICATIONS.FAILED, payload: error });
		}
	}

	function* updateClientSMSNotificationsSaga({ payload }) {
		try {
			yield call(API.settingsService.updateClientApplicationNotifications, payload);

			yield put({ type: TYPES.UPDATE_CLIENT_SMS_NOTIFICATIONS.SUCCESS });

			yield call(message.success, MESSAGES.successfullyUpdated);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CLIENT_SMS_NOTIFICATIONS.FAILED, payload: error });
		}
	}

	function* getUserEmailNotificationsSaga() {
		try {
			const emailNotificationsData = yield call(API.settingsService.getUserEmailNotifications);

			yield put({
				type: TYPES.GET_USER_EMAIL_NOTIFICATIONS.SUCCESS,
				payload: emailNotificationsData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_USER_EMAIL_NOTIFICATIONS.FAILED, payload: error });
		}
	}

	function* updateUserEmailNotificationsSaga({ payload }) {
		try {
			const emailNotificationsData = yield call(
				API.settingsService.updateUserEmailNotifications,
				payload,
			);

			yield put({
				type: TYPES.UPDATE_USER_EMAIL_NOTIFICATIONS.SUCCESS,
				payload: emailNotificationsData,
			});

			yield call(message.success, MESSAGES.successfullyUpdated);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_USER_EMAIL_NOTIFICATIONS.FAILED, payload: error });
		}
	}

	function* changeClientUserPasswordSaga({ payload, callback }) {
		try {
			yield call(API.usersService.updateATSUserPassword, payload);

			yield put({ type: TYPES.CHANGE_CLIENT_USER_PASSWORD.SUCCESS });

			yield call(message.success, MESSAGES.successfullyUpdated);

			yield call(callback);
		} catch (error) {
			yield put({ type: TYPES.CHANGE_CLIENT_USER_PASSWORD.FAILED, payload: error });
		}
	}

	function* getATSUserPersonalDataSaga() {
		try {
			const data = yield call(API.settingsService.getATSUserPersonalData);

			yield put({ type: TYPES.GET_ATS_USER_PERSONAL_DATA.SUCCESS, payload: data });
		} catch (error) {
			yield put({ type: TYPES.GET_ATS_USER_PERSONAL_DATA.FAILED, payload: error });
		}
	}

	function* updateATSUserPersonalDataSaga({ payload }) {
		try {
			const userData = yield call(API.settingsService.updateATSUserPersonalData, payload);

			yield put({ type: TYPES.UPDATE_ATS_USER_PERSONAL_DATA.SUCCESS });

			if (userData?.token) {
				// Update token in cookies after change Personal Data
				yield call(Cookies.set, CookiesType.JWT, userData?.token);
			}

			message.success(MESSAGES.successfullySavedPersonalData);
		} catch (error) {
			yield put({ type: TYPES.UPDATE_ATS_USER_PERSONAL_DATA.FAILED, payload: error });
		}
	}

	function* getSubscriptionTypesSaga() {
		try {
			const subscriptionTypes = yield call(API.subscriptionsService.getSubscriptionTypes);

			yield put({ type: TYPES.GET_SUBSCRIPTION_TYPES.SUCCESS, payload: subscriptionTypes });
		} catch (error) {
			yield put({ type: TYPES.GET_SUBSCRIPTION_TYPES.FAILED, payload: error });
		}
	}

	function* assignCandidateToVacancySaga(action) {
		try {
			const res = yield call(
				API.candidateApplicationService.assignCandidateToVacancy,
				action.payload,
			);

			action.cb && (yield call(action.cb, res));

			yield call(message.success, MESSAGES.successfullyAssigned);
			yield put({ type: TYPES.ASSIGN_CANDIDATE_TO_VACANCY.SUCCESS, payload: res });
		} catch (error) {
			yield put({ type: TYPES.ASSIGN_CANDIDATE_TO_VACANCY.FAILED, payload: error });
		}
	}

	function* getUnappliedCandidateJobsSaga(action) {
		try {
			const jobsData = yield call(
				API.candidateApplicationService.getUnappliedCandidateJobs,
				action.payload,
			);

			yield put({ type: TYPES.GET_UNAPPLIED_CANDIDATE_JOBS.SUCCESS, payload: jobsData });
		} catch (error) {
			yield put({ type: TYPES.GET_UNAPPLIED_CANDIDATE_JOBS.FAILED, payload: error });
		}
	}

	function* archiveAndUnarchiveSaga(action) {
		try {
			const { id, active } = action.payload;

			const res = yield call(API.jobsService.archiveAndUnarchiveJob, id, { active });

			message.success(
				!res.active ? MESSAGES.successfullyArchived : MESSAGES.successfullyUnarchived,
			);
		} catch (error) {
			console.error(error);
		}
	}

	function* deleteAssessmentFormSaga(action) {
		try {
			yield call(API.assessmentService.deleteAssessmentFormByJobId, { job: action.payload });

			message.success(MESSAGES.successfullyDeleted);
		} catch (error) {
			console.error(error);
		}
	}

	function* copyPostJobChangesFormSaga(action) {
		try {
			let restrictedUsers;
			const jobData = yield call(API.jobsService.getJobById, action.payload);

			const { isAtsSuperUser } = yield select(unregisteredDucks.unregisteredSelectors.getUserRoles);

			if (isAtsSuperUser) {
				const res = yield call(API.jobsService.getJobRestrictedUsers, action.payload);
				restrictedUsers = res?.restrictedUsers;
			}

			const allowedFields = { ...pick(jobData, AllowedJobFields), restrictedUsers };

			yield put({ type: TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.SUCCESS, payload: allowedFields });
		} catch (error) {
			yield put({ type: TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.FAILED });
		}
	}

	function* getAndSavePostJobChangesFormSaga(action) {
		try {
			let assessmentQuestions = [];
			let restrictedUsers;
			const jobData = yield call(API.jobsService.getJobById, action.payload);

			if (
				jobData.jobClass === JobClassesEnum.FreeJob ||
				jobData.jobClass === JobClassesEnum.PaidJob ||
				jobData.jobClass === JobClassesEnum.PendingJob
			) {
				assessmentQuestions = yield call(
					API.assessmentService.getAssessmentByJobId,
					action.payload,
				);
			}

			const { isAtsSuperUser } = yield select(unregisteredDucks.unregisteredSelectors.getUserRoles);

			if (isAtsSuperUser) {
				const res = yield call(API.jobsService.getJobRestrictedUsers, action.payload);
				restrictedUsers = res?.restrictedUsers;
			}

			const postJobChangesFormData = {
				...pick(jobData, AllowedJobFields),
				assessmentQuestions,
				restrictedUsers,
			};

			yield put({
				type: TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.SUCCESS,
				payload: postJobChangesFormData,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_AND_SAVE_POST_JOB_CHANGES_FORM.FAILED });
		}
	}

	function* getAllAtsJobsSaga(action) {
		try {
			const clientJobs = yield call(API.jobsService.getAllAtsJobs, action.payload);

			yield put({ type: TYPES.GET_ALL_ATS_JOBS.SUCCESS, payload: clientJobs });
		} catch (error) {
			yield put({ type: TYPES.GET_ALL_ATS_JOBS.FAILED, payload: error });
		}
	}

	function* getAtsActiveJobsSaga(action) {
		try {
			const activeJobs = yield call(API.jobsService.getAtsJobActive, action.payload);

			yield put({ type: TYPES.GET_ATS_ACTIVE_JOBS.SUCCESS, payload: activeJobs });
		} catch (error) {
			yield put({ type: TYPES.GET_ATS_ACTIVE_JOBS.FAILED, payload: error });
		}
	}
	function* createOrderInvoiceSaga(action) {
		try {
			const invoice = yield call(API.creditsService.createOrderInvoice, action.payload);

			yield put({ type: TYPES.CREATE_ORDER_INVOICE.SUCCESS, payload: invoice });
			yield put({
				type: TYPES.GET_ATS_INVOICES.REQUESTED,
				payload: { page: DEFAULT_CURRENT_PAGE, size: DEFAULT_PAGE_SIZE },
			});
		} catch (error) {
			yield put({ type: TYPES.CREATE_ORDER_INVOICE.FAILED, payload: error });
		}
	}

	function* getCurrentInvoiceSaga(action) {
		try {
			const invoiceId = action.payload;
			const invoice = yield call(API.invoicesService.getAtsInvoiceById, invoiceId);

			yield put({ type: TYPES.GET_ATS_INVOICE_BY_ID.SUCCESS, payload: invoice });
		} catch (e) {
			const res = e?.response?.data;

			if (res && res.statusCode === 403) {
				yield put({ type: TYPES.GET_ATS_INVOICE_BY_ID.FAILED, payload: res.exceptionMessage });
			}
		}
	}

	function* getAtsInvoicesSaga(action) {
		try {
			const params = action.payload;
			const invoices = yield call(API.invoicesService.getAtsInvoices, params);

			yield put({ type: TYPES.GET_ATS_INVOICES.SUCCESS, payload: invoices });
		} catch (e) {
			const res = e?.response?.data;

			if (res && res.statusCode === 403) {
				yield put({ type: TYPES.GET_ATS_INVOICES.FAILED, payload: res.exceptionMessage });
			}
		}
	}

	function* getATSBrandingByClientIdSaga(action) {
		try {
			const [brandingData] = yield call(
				API.brandingService.getAtsBrandingByClientId,
				action.payload,
			);
			yield put({ type: TYPES.GET_ATS_BRANDING_BY_ID.SUCCESS, payload: brandingData });
		} catch (error) {
			yield put({ type: TYPES.GET_ATS_BRANDING_BY_ID.FAILED, payload: error });
		}
	}

	function* getATSCareerBrandingByClientIdSaga(action) {
		try {
			const [brandingData] = yield call(
				API.brandingService.getCareerBrandingByClient,
				action.payload,
			);
			yield put({ type: TYPES.GET_ATS_CAREEER_BRANDING_BY_ID.SUCCESS, payload: brandingData });
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_CAREEER_BRANDING_BY_ID.FAILED,
				payload: error,
			});
		}
	}

	function* updateAtsBrandingSaga({ payload, sendState = false }) {
		try {
			const savedAtsBrandingData = yield select(
				atsDucks.atsSelectors.getClientAtsPremiumBrandingData,
			);

			const brandingData = yield call(
				API.brandingService.updateAtsBranding,
				sendState ? pick(savedAtsBrandingData, [...AtsBrandingAllowedFields, 'id']) : payload,
			);

			yield put({ type: TYPES.UPDATE_ATS_BRANDING.SUCCESS, payload: brandingData });
			yield call(message.success, 'branding was successfully updated');
		} catch (error) {
			yield put({ type: TYPES.UPDATE_ATS_BRANDING.FAILED, payload: error });
		}
	}

	function* getAtsTicketsSaga(action) {
		try {
			const params = action.payload;
			const tickets = yield call(
				API.ticketsService.getAtsTickets,
				params || {
					active: true,
				},
			);

			yield put({ type: TYPES.GET_ATS_TICKETS.SUCCESS, payload: tickets });
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_ATS_TICKETS.FAILED, payload: e });
		}
	}

	function* getAtsTicketDetailsSaga(action) {
		try {
			const ticketId = action.payload;
			const ticket = yield call(API.ticketsService.getAtsTicketById, ticketId);
			let assessmentQuestions = null;

			if (ticket?.jobId) {
				assessmentQuestions = yield call(API.assessmentService.getAssessmentByJobId, ticket.jobId);
			}

			yield put({
				type: TYPES.GET_ATS_TICKET_DETAILS.SUCCESS,
				payload: { ...ticket, assessmentQuestions },
			});
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_ATS_TICKET_DETAILS.FAILED, payload: e });
		}
	}

	function* getJobUpgrades() {
		try {
			const jobUpgrades = yield call(API.jobsService.getJobUpgrades);

			yield put({ type: TYPES.GET_JOB_UPGRAGED.SUCCESS, payload: jobUpgrades });
		} catch (e) {
			const res = e?.response?.data;

			if (res && res.statusCode === 403) {
				yield put({ type: TYPES.GET_JOB_UPGRAGED.FAILED, payload: res.exceptionMessage });
			}
		}
	}

	function* getAtsUsersSaga({ payload }) {
		try {
			const atsUsers = yield call(API.usersService.getAtsUsers, payload);

			yield put({ type: TYPES.GET_ATS_USERS.SUCCESS, payload: atsUsers });
		} catch (e) {
			const res = e?.response?.data;

			if (res && res.statusCode === 403) {
				yield put({ type: TYPES.GET_ATS_USERS.FAILED, payload: res.exceptionMessage });
			}
		}
	}

	function* getAtsUserRolesSaga() {
		try {
			const atsUserRoles = yield call(API.usersService.getAtsUserRole);

			yield put({ type: TYPES.GET_ATS_USER_ROLES.SUCCESS, payload: atsUserRoles });
		} catch (e) {
			yield put({ type: TYPES.GET_ATS_USER_ROLES.FAILED });
		}
	}

	function* getAtsUserByIdSaga(action) {
		try {
			const user = yield call(API.usersService.getAtsUserById, action.payload);

			yield put({ type: TYPES.GET_ATS_USER_BY_ID.SUCCESS, payload: user });
		} catch (e) {
			yield put({ type: TYPES.GET_ATS_USER_BY_ID.FAILED });

			if (e === `User with id ${action.payload} not found`) {
				yield call(action.callback);
			}
		}
	}

	function* createAtsUserSaga({ payload, callback }) {
		try {
			yield call(API.usersService.createAtsUser, payload);

			yield put({ type: TYPES.CREATE_ATS_USER.SUCCESS });

			message.success(MESSAGES.successfullyCreated);

			yield call(callback);
		} catch (e) {
			yield put({ type: TYPES.CREATE_ATS_USER.FAILED });
		}
	}

	function* deleteAtsUserSaga(action) {
		try {
			yield call(API.usersService.deleteAtsUser, action.payload);

			message.success(MESSAGES.userDeleted);
			yield put({ type: TYPES.DELETE_ATS_USER.SUCCESS });

			const atsUsers = yield call(API.usersService.getAtsUsers, {
				page: 0,
				size: DEFAULT_PAGE_SIZE,
			});
			yield put({ type: TYPES.GET_ATS_USERS.SUCCESS, payload: atsUsers });
		} catch (e) {
			yield put({ type: TYPES.DELETE_ATS_USER.FAILED });
		}
	}

	function* updateAtsUserSaga({ payload, callback }) {
		try {
			yield call(API.usersService.updateAtsUser, payload);

			yield put({ type: TYPES.UPDATE_ATS_USER.SUCCESS });

			message.success(MESSAGES.successfullyUpdated);

			yield call(callback);
		} catch (e) {
			yield put({ type: TYPES.UPDATE_ATS_USER.FAILED });
		}
	}

	function* getCreditsSaga(action) {
		try {
			const credits = yield call(API.backOfficeService.getCreditsByClientId, action.payload);

			yield put({ type: TYPES.GET_CLIENT_CREDITS.SUCCESS, payload: credits });
		} catch (e) {
			const res = e?.response?.data;

			if (res && res.statusCode === 403) {
				yield put({ type: TYPES.GET_CLIENT_CREDITS.FAILED, payload: res.exceptionMessage });
			}
		}
	}

	function* updateAtsCareerBrandingSaga({ payload, sendState = false }) {
		try {
			const savedAtsBrandingData = yield select(atsDucks.atsSelectors.getClientCareerBrandingData);

			const preparedCareerData = sendState
				? pick(savedAtsBrandingData, CareerPageBrandingAllowedFields)
				: omit(payload, ['benefits', 'removedBenefits']);
			const brandingData = yield call(API.brandingService.updateCareerBranding, preparedCareerData);

			const { benefits, removedBenefits, id, client } = payload;

			const newBenefits = benefits.reduce((acc, b) => {
				if (`${b.id}`.startsWith('tempId')) {
					return [
						...acc,
						{
							benefitText: b.benefitText,
							career: id,
							img: b.img,
						},
					];
				}

				return acc;
			}, []);

			if (newBenefits.length) {
				yield call(API.brandingService.createClientBenefits, newBenefits);
			}

			for (const removedBenefit of removedBenefits) {
				yield call(API.brandingService.removeBenefit, removedBenefit.id);
			}

			yield put({ type: TYPES.UPDATE_ATS_CAREER_BRANDING.SUCCESS, payload: brandingData });
			yield call(getATSCareerBrandingByClientIdSaga, { payload: client });
			message.success('Branding was successfully updated');
		} catch (error) {
			yield put({ type: TYPES.UPDATE_ATS_CAREER_BRANDING.FAILED, payload: error });
		}
	}

	function* getClientATSApplicationMessagesByClientIdSaga(action) {
		try {
			const settingsData = yield call(
				API.settingsService.getClientsAtsApplicationMessageByClientId,
				action.payload,
			);

			yield put({
				type: TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID.SUCCESS,
				payload: settingsData,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CLIENT_APPLICATION_MESSAGES_BY_ID.FAILED,
				payload: error,
			});
		}
	}

	function* getClientApplicationNotificationSaga(action) {
		try {
			const clientReminderData = yield call(
				API.clientsService.getClientApplicationNotification,
				action.payload,
			);

			yield put({
				type: TYPES.GET_CLIENT_APPLICATION_NOTIFICATION.SUCCESS,
				payload: clientReminderData,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CLIENT_APPLICATION_NOTIFICATION.FAILED,
				payload: error,
			});
		}
	}

	function* updateClientATSApplicationMessagesSaga(action) {
		try {
			const requestData = yield call(
				API.settingsService.updateClientsAtsApplicationMessageByClientId,
				action.payload,
			);

			yield put({ type: TYPES.UPDATE_CLIENT_APPLICATION_MESSAGES.SUCCESS, payload: requestData });
			requestData && message.success(MESSAGES.successfullySaved);
		} catch (error) {
			yield put({
				type: TYPES.UPDATE_CLIENT_APPLICATION_MESSAGES.FAILED,
				payload: error,
			});
		}
	}

	function* updateRejectionMessagePerJobSaga(action) {
		try {
			const { id, messageRejection } = action.payload;
			yield call(API.jobsService.updateRejectionMessagePerJob, id, {
				messageRejection,
			});

			message.success(MESSAGES.successfullyUpdatedRejectionMessage);
		} catch (error) {
			console.error(error);
		}
	}

	function* getAtsCandidateApplicationByJobIdSaga(action) {
		try {
			const { id } = action.payload;
			const candidateApplication = yield call(
				API.candidateApplicationService.getCandidateApplicationInterviewById,
				id,
			);

			yield put({
				type: TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID.SUCCESS,
				payload: candidateApplication,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CANDIDATE_APPLICATION_BY_JOB_ID.FAILED,
				payload: error,
			});
		} finally {
			if (action.payload.cb) {
				action.payload.cb();
			}
		}
	}

	function* getCandidateAssessmentApplicationSaga(action) {
		try {
			const candidateApplication = yield call(
				API.assessmentService.getCandidateAssessmentApplication,
				action.payload,
			);

			yield put({
				type: TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION.SUCCESS,
				payload: candidateApplication,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CANDIDATE_ASSESSMENT_APPLICATION.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsInterviewTypeSaga() {
		try {
			const interviewTypes = yield call(API.interviewService.getAtsInterviewType);

			yield put({
				type: TYPES.GET_ATS_INTERVIEW_TYPE.SUCCESS,
				payload: interviewTypes,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_INTERVIEW_TYPE.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsUserInterviewSaga() {
		try {
			const interviewUsers = yield call(API.interviewService.getAtsUserInterview);

			yield put({
				type: TYPES.GET_ATS_USER_INTERVIEW.SUCCESS,
				payload: interviewUsers,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_USER_INTERVIEW.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsInterviewByIdSaga(action) {
		try {
			const interview = yield call(API.interviewService.getAtsInterviewById, action.payload);

			yield put({
				type: TYPES.GET_ATS_INTERVIEW_BY_ID.SUCCESS,
				payload: interview,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_INTERVIEW_BY_ID.FAILED,
				payload: error,
			});
		}
	}

	function* cancelAtsInterviewByIdSaga({ payload, callback }) {
		try {
			yield call(API.interviewService.deleteAtsInterviewById, payload);

			yield put({ type: TYPES.CANCEL_ATS_INTERVIEW_BY_ID.SUCCESS });
			message.success(MESSAGES.successfullyCanceled);

			yield call(callback);
		} catch (error) {
			yield put({
				type: TYPES.CANCEL_ATS_INTERVIEW_BY_ID.FAILED,
				payload: error,
			});
		}
	}

	function* saveAtsInterviewSaga({ payload, callback }) {
		try {
			yield call(API.interviewService.saveAtsInterview, payload);

			yield put({ type: TYPES.SAVE_ATS_INTERVIEW.SUCCESS });
			message.success(MESSAGES.successfullySent);

			yield call(callback);
		} catch (error) {
			yield put({
				type: TYPES.SAVE_ATS_INTERVIEW.FAILED,
				payload: error,
			});
		}
	}

	function* updateAtsInterviewSaga({ payload, callback }) {
		try {
			yield call(API.interviewService.updateAtsInterview, payload);

			// Check and delete already resend interview invite
			const InterviewResentTimes = localStorage.getItem(LocalStorageType.InterviewResentTimes);
			const savedInterviewTimes = InterviewResentTimes ? JSON.parse(InterviewResentTimes) : [];
			const isIndexExist =
				savedInterviewTimes?.length && savedInterviewTimes?.findIndex((i) => i.id === payload?.id);

			if (isIndexExist !== -1) {
				const newInterviewTime = savedInterviewTimes.splice(isIndexExist, 0);
				newInterviewTime?.length &&
					localStorage.setItem(
						LocalStorageType.InterviewResentTimes,
						JSON.stringify([...newInterviewTime]),
					);
			}

			yield put({ type: TYPES.UPDATE_ATS_INTERVIEW.SUCCESS });
			message.success(MESSAGES.successfullyUpdated);

			yield call(callback);
		} catch (error) {
			yield put({
				type: TYPES.UPDATE_ATS_INTERVIEW.FAILED,
				payload: error,
			});
		}
	}

	function* orderCreditsSaga({ payload }) {
		try {
			const { values, callback } = payload;

			const order = yield call(API.creditsService.orderCredits, values);

			yield put({ type: TYPES.ORDER_CREDITS.SUCCESS });

			yield call(callback, order.id);
		} catch (error) {
			yield put({
				type: TYPES.ORDER_CREDITS.FAILED,
				payload: error,
			});

			message.error(error);
		}
	}

	function* orderSmsSaga({ payload }) {
		try {
			const { values, callback } = payload;

			const order = yield call(API.creditsService.orderSMS, values);

			yield put({ type: TYPES.ORDER_SMS.SUCCESS });

			yield call(callback, order.id);
		} catch (error) {
			yield put({
				type: TYPES.ORDER_SMS.FAILED,
				payload: error,
			});

			message.error(error);
		}
	}

	function* orderInterviewsSaga({ payload }) {
		try {
			const { values, callback } = payload;

			const order = yield call(API.creditsService.orderInterviews, values);

			yield put({ type: TYPES.ORDER_INTERVIEWS.SUCCESS });

			yield call(callback, order.id);
		} catch (error) {
			yield put({
				type: TYPES.ORDER_INTERVIEWS.FAILED,
				payload: error,
			});

			message.error(error);
		}
	}

	function* orderByIdSaga({ payload }) {
		try {
			const order = yield call(API.creditsService.getOrderById, payload);

			yield put({ type: TYPES.GET_ORDER_BY_ID.SUCCESS, payload: order });
		} catch (error) {
			yield put({
				type: TYPES.GET_ORDER_BY_ID.FAILED,
				payload: error,
			});

			message.error(error);
		}
	}

	function* getAtsInterviewJobsListSaga(action) {
		try {
			const interviewJobs = yield call(
				API.interviewService.getAtsInterviewJobsList,
				action.payload,
			);

			yield put({ type: TYPES.GET_ATS_INTERVIEW_JOBS_LIST.SUCCESS, payload: interviewJobs });
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_INTERVIEW_JOBS_LIST.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsEditInterviewJobsListSaga(action) {
		try {
			const interviewJobs = yield call(
				API.interviewService.getAtsEditInterviewJobsList,
				action.payload,
			);

			yield put({ type: TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST.SUCCESS, payload: interviewJobs });
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_EDIT_INTERVIEW_JOBS_LIST.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsInterviewCalendarSaga(action) {
		try {
			const interviewData = yield call(
				API.interviewService.getAtsInterviewCalendar,
				action.payload,
			);

			yield put({ type: TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST.SUCCESS, payload: interviewData });
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_INTERVIEW_CALENDAR_LIST.FAILED,
				payload: error,
			});
		}
	}

	function* resendAtsInterviewLinkSaga(action) {
		try {
			const { id, candidate } = action.payload;

			yield call(API.interviewService.resendAtsInterviewLink, id);

			// Saving sent time to Local Storage
			const storedInterviewResendTimes = localStorage.getItem(
				LocalStorageType.InterviewResentTimes,
			);
			const interviewResendTimes = storedInterviewResendTimes
				? JSON.parse(storedInterviewResendTimes)
				: [];

			const isExistInterviewTime =
				interviewResendTimes?.length && interviewResendTimes?.findIndex((i) => i.id === id);

			const updatedInterviewsResendTime =
				isExistInterviewTime !== -1
					? interviewResendTimes?.splice(isExistInterviewTime, 0)
					: interviewResendTimes;

			const newInterviewResendTimes = [...updatedInterviewsResendTime, { id, date: new Date() }];

			localStorage.setItem(
				LocalStorageType.InterviewResentTimes,
				JSON.stringify(newInterviewResendTimes),
			);

			message.success(`${candidate} has been sent a video interview link`);

			yield put({ type: TYPES.RESEND_ATS_INTERVIEW_LINK.SUCCESS });
		} catch (error) {
			yield put({
				type: TYPES.RESEND_ATS_INTERVIEW_LINK.FAILED,
				payload: error,
			});
		}
	}

	function* createATSTicketSaga({ payload, callback }) {
		try {
			yield call(API.ticketsService.postATSTicket, payload);

			yield call(message.success, MESSAGES.successfullyCreatedATSTicket);

			yield put({ type: TYPES.CREATE_ATS_TICKET.SUCCESS });

			callback && (yield call(callback));
		} catch (error) {
			yield call(message.error, MESSAGES.genericErrorMessage);
			yield put({ type: TYPES.CREATE_ATS_TICKET.FAILED });
		}
	}

	function* getCandidateNotesByIdSaga(action) {
		try {
			const notes = yield call(
				API.candidateApplicationService.getCandidateNotesById,
				action.payload,
			);

			yield put({ type: TYPES.GET_CANDIDATE_NOTES_BY_ID.SUCCESS, payload: notes });
		} catch (error) {
			yield put({ type: TYPES.GET_CANDIDATE_NOTES_BY_ID.FAILED, payload: error });
		}
	}

	function* addNoteToCandidateSaga({ payload, candidateAppId, cb }) {
		try {
			const noteData = yield call(API.candidateApplicationService.addNoteToCandidate, {
				text: payload,
				candidateAppId,
			});

			yield put({
				type: TYPES.ADD_NOTE_TO_CANDIDATE.SUCCESS,
				payload: noteData,
			});
			cb && (yield call(cb));
		} catch (error) {
			yield put({ type: TYPES.ADD_NOTE_TO_CANDIDATE.FAILED, payload: error });
		}
	}

	function* getCandidatesDatabaseSaga(action) {
		try {
			const params = action.payload;
			const candidatesList = yield call(
				API.candidateApplicationService.getAtsCandidatesDatabase,
				params || {
					active: true,
				},
			);

			yield put({ type: TYPES.GET_CANDIDATES_DATABASE.SUCCESS, payload: candidatesList });
		} catch (e) {
			console.error(e);
			yield put({ type: TYPES.GET_CANDIDATES_DATABASE.FAILED, payload: e });
		}
	}

	function* getCandidateDatabaseByIdSaga(action) {
		try {
			const applicationId = action.payload;
			const candidateFromDatabase = yield call(
				API.candidateApplicationService.getAtsCandidateDatabaseById,
				applicationId,
			);

			yield put({
				type: TYPES.GET_CANDIDATE_DATABASE_BY_ID.SUCCESS,
				payload: candidateFromDatabase,
			});
		} catch (e) {
			yield put({ type: TYPES.GET_CANDIDATE_DATABASE_BY_ID.FAILED, payload: e });
		}
	}

	function* getCandidateSMSInformationSaga({ payload }) {
		try {
			const { candidates: candidatesSMSInformation } = yield call(
				API.candidateApplicationService.getCandidatesSMSInformation,
				payload,
			);

			yield put({
				type: TYPES.GET_CANDIDATE_SMS_INFORMATION.SUCCESS,
				payload: candidatesSMSInformation,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_CANDIDATE_SMS_INFORMATION.FAILED, payload: error });
		}
	}

	function* updateCandidatePhoneNumberSaga({ payload, callback }) {
		try {
			const updatedCandidate = yield call(
				API.candidateApplicationService.updateCandidatePhoneNumber,
				payload,
			);

			yield put({ type: TYPES.UPDATE_CANDIDATE_PHONE_NUMBER.SUCCESS });

			callback && (yield call(callback, updatedCandidate));
		} catch (error) {
			yield put({ type: TYPES.UPDATE_CANDIDATE_PHONE_NUMBER.FAILED, payload: error });
		}
	}

	function* sendSMSToCandidateSaga({ payload, callback }) {
		try {
			yield call(API.candidateApplicationService.sendSMSToCandidate, payload);

			yield put({ type: TYPES.SEND_SMS_TO_CANDIDATE.SUCCESS });

			callback && (yield call(callback));
		} catch (error) {
			callback && (yield call(callback, error));
			yield put({ type: TYPES.SEND_SMS_TO_CANDIDATE.FAILED, payload: error });
		}
	}

	function* getAtsVideoInterviewCountSaga({ payload, callback }) {
		try {
			let responses = [];

			responses = yield all(
				payload.map((dateStr) => call(API.questionnairesService.getVideoInterviewCount, dateStr)),
			);

			const counterData = payload.reduce((acc, dateStr, index) => {
				acc[dateStr] = responses[index];

				return acc;
			}, {});

			yield put({ type: TYPES.GET_ATS_VIDEO_INTERVIEW_COUNT.SUCCESS, payload: counterData });

			if (callback) {
				yield call(callback);
			}
		} catch (error) {
			yield put({ type: TYPES.GET_ATS_VIDEO_INTERVIEW_COUNT.FAILED, payload: error });
		}
	}

	function* showHideCandidateDatabaseSaga({ payload, callback }) {
		try {
			const updatedCandidate = yield call(
				API.candidateApplicationService.showHideCandidateInDatabase,
				payload,
			);

			yield put({ type: TYPES.SHOW_HIDE_CANDIDATE_DATABASE.SUCCESS, payload: updatedCandidate });

			callback && (yield call(callback));
		} catch (error) {
			yield put({ type: TYPES.SHOW_HIDE_CANDIDATE_DATABASE.FAILED, payload: error });
		}
	}

	function* assignCandidateToVacancyDatabaseSaga({ payload, callback }) {
		try {
			const responseData = yield call(
				API.candidateApplicationService.assignCandidateToVacancyDatabase,
				payload,
			);

			yield put({ type: TYPES.ASSIGN_CANDIDATE_TO_VACANCY_DATABASE.SUCCESS });

			callback && (yield call(callback, responseData));
		} catch (error) {
			yield put({ type: TYPES.ASSIGN_CANDIDATE_TO_VACANCY_DATABASE.FAILED, payload: error });
		}
	}

	function* forwardInvoiceSaga({ payload }) {
		try {
			yield call(API.invoicesService.forwardInvoice, payload);

			yield put({ type: TYPES.FORWARD_INVOICE.SUCCESS });
		} catch (error) {
			yield put({ type: TYPES.FORWARD_INVOICE.FAILED, payload: error });
		}
	}

	function* getApplicationMonthStatistic() {
		try {
			const data = yield call(API.statisticsService.getATSLastMonthApplications);

			yield put({ type: TYPES.GET_JOBS_MONTH_STATISTIC.SUCCESS, payload: data });
		} catch (error) {
			yield put({ type: TYPES.GET_JOBS_MONTH_STATISTIC.FAILED, payload: error });
		}
	}

	function* getCandidateStatisticsSaga(action) {
		try {
			const { periodType, timeFrameInDays, month } = action.payload;
			const requestFunc =
				periodType === 'days'
					? API.statisticsService.getATSCandidateStatistics
					: API.statisticsService.getATSCandidateStatisticsMonth;
			const requestFuncPayload = periodType === 'days' ? timeFrameInDays : month;
			const candidateStatistics = yield call(requestFunc, requestFuncPayload);

			yield put({
				type: TYPES.GET_CANDIDATE_STATISTICS.SUCCESS,
				payload: candidateStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CANDIDATE_STATISTICS.FAILED,
				payload: error,
			});
		}
	}

	function* getApplicationsStatisticsForLastMonthSaga(action) {
		try {
			const applicationsStatistics = yield call(
				API.statisticsService.getATSApplicationsStatisticsForLastMonth,
				action.payload,
			);

			yield put({
				type: TYPES.GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH.SUCCESS,
				payload: applicationsStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH.FAILED,
				payload: error,
			});
		}
	}

	function* getCandidateApplicationPerStateStatisticsSaga(action) {
		try {
			const applicationsCandidatePerStateStatistics = yield call(
				API.statisticsService.getATSCandidatesTimePerStage,
				action.payload,
			);

			yield put({
				type: TYPES.GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS.SUCCESS,
				payload: applicationsCandidatePerStateStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS.FAILED,
				payload: error,
			});
		}
	}

	function* getAcceptedOffersStatisticsSaga(action) {
		try {
			const applicationsCandidatePerStateStatistics = yield call(
				API.statisticsService.getATSStatisticsForAcceptedOffers,
				action.payload,
			);

			yield put({
				type: TYPES.GET_ACCEPTED_OFFERS_STATISTICS.SUCCESS,
				payload: applicationsCandidatePerStateStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ACCEPTED_OFFERS_STATISTICS.FAILED,
				payload: error,
			});
		}
	}

	function* getApplicationSourcesStatisticsSaga(action) {
		try {
			const applicationsCandidatePerStateStatistics = yield call(
				API.statisticsService.getATSStatisticsApplicationSources,
				action.payload,
			);

			yield put({
				type: TYPES.GET_APPLICATION_SOURCES_STATISTICS.SUCCESS,
				payload: applicationsCandidatePerStateStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_APPLICATION_SOURCES_STATISTICS.FAILED,
				payload: error,
			});
		}
	}

	function* getTimeToHireMonthSaga(action) {
		try {
			const timeToHireMonthStatistics = yield call(
				API.statisticsService.getTimeToHireMonth,
				action.payload,
			);

			yield put({
				type: TYPES.GET_TIME_TO_HIRE_MONTH.SUCCESS,
				payload: timeToHireMonthStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_TIME_TO_HIRE_MONTH.FAILED,
				payload: error,
			});
		}
	}

	function* getTimeToHireYearSaga() {
		try {
			const timeToHireYearStatistics = yield call(API.statisticsService.getTimeToHireYear);

			yield put({
				type: TYPES.GET_TIME_TO_HIRE_YEAR.SUCCESS,
				payload: timeToHireYearStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_TIME_TO_HIRE_YEAR.FAILED,
				payload: error,
			});
		}
	}

	function* getApplicationYearStatisticsSaga() {
		try {
			const applicationsYearStatistic = yield call(API.statisticsService.getATSYearApplications);

			yield put({
				type: TYPES.GET_APPLICATION_YEAR_STATISTICS.SUCCESS,
				payload: applicationsYearStatistic,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_APPLICATION_YEAR_STATISTICS.FAILED,
				payload: error,
			});
		}
	}

	function* getApplicationMontStatisticSaga(action) {
		try {
			const applicationsCandidatePerStateStatistics = yield call(
				API.statisticsService.getATSMonthApplications,
				action.payload,
			);

			yield put({
				type: TYPES.GET_APPLICATION_MONTH_STATISTICS.SUCCESS,
				payload: applicationsCandidatePerStateStatistics,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_APPLICATION_MONTH_STATISTICS.FAILED,
				payload: error,
			});
		}
	}

	function* getDashboardUnreadEmailsSaga() {
		try {
			const unreadEmails = yield call(API.emailService.getAtsDashboardsEmailUnreadList);

			yield put({
				type: TYPES.GET_DASHBOARD_UNREAD_EMAILS.SUCCESS,
				payload: unreadEmails,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_DASHBOARD_UNREAD_EMAILS.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsAllJobsListSaga() {
		try {
			const allJobsList = yield call(API.jobsService.getAtsAllJobs);

			yield put({
				type: TYPES.GET_ATS_ALL_JOBS_LIST.SUCCESS,
				payload: allJobsList,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_ALL_JOBS_LIST.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsAllCandidateApplicationByJobIdSaga({ payload }) {
		try {
			const allCandidateApplication = yield call(
				API.candidateApplicationService.getAtsAllCandidateApplicationsByJobId,
				payload,
			);

			yield put({
				type: TYPES.GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID.SUCCESS,
				payload: allCandidateApplication,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID.FAILED,
				payload: error,
			});
		}
	}

	function* createAtsEmailThreadSaga({ payload, callback }) {
		try {
			const result = yield call(API.emailService.createAtsEmailThread, payload);

			yield put({ type: TYPES.CREATE_ATS_EMAIL_THREAD.SUCCESS });

			callback && (yield call(callback, result));
		} catch (error) {
			yield put({ type: TYPES.CREATE_ATS_EMAIL_THREAD.FAILED, payload: error });
		}
	}

	function* getAtsEmailTemplatesSaga() {
		try {
			const emailTemplates = yield call(API.emailService.getAtsEmailTemplates);

			yield put({
				type: TYPES.GET_ATS_EMAIL_TEMPLATES.SUCCESS,
				payload: emailTemplates,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_EMAIL_TEMPLATES.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsEmailInboxSaga({ payload }) {
		try {
			const emailThreads = yield call(API.emailService.getAtsInboxEmailInfo, payload);

			yield put({
				type: TYPES.GET_ATS_EMAIL_INBOX.SUCCESS,
				payload: emailThreads,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_EMAIL_INBOX.FAILED,
				payload: error,
			});
		}
	}

	function* getAtsEmailThreadByIdSaga({ payload }) {
		try {
			const currentEmailThread = yield call(API.emailService.getAtsEmailThreadById, payload);

			yield put({
				type: TYPES.GET_ATS_EMAIL_THREAD_BY_ID.SUCCESS,
				payload: currentEmailThread,
			});
		} catch (error) {
			yield put({
				type: TYPES.GET_ATS_EMAIL_THREAD_BY_ID.FAILED,
				payload: error,
			});
		}
	}

	function* postAtsToExistingEmailThreadSaga({ payload, callback }) {
		try {
			const emailThread = yield call(API.emailService.replyToExistentEmailThreadInAtsPage, payload);

			yield put({
				type: TYPES.POST_ATS_TO_EXISTING_EMAIL_THREAD.SUCCESS,
				payload: emailThread,
			});
			message.success('The email has been sent');

			callback && (yield call(callback));
		} catch (error) {
			yield put({
				type: TYPES.POST_ATS_TO_EXISTING_EMAIL_THREAD.FAILED,
				payload: error,
			});
			message.error('The email has not been sent');
		}
	}

	function* getGravityJobsSaga(action) {
		try {
			const gravityJobs = yield call(API.jobsService.getAllGravityJobs, action.payload);

			yield put({ type: TYPES.GET_GRAVITY_JOBS.SUCCESS, payload: gravityJobs });
		} catch (error) {
			yield put({ type: TYPES.GET_GRAVITY_JOBS.FAILED, payload: error });
		}
	}

	function* getListOfGravityCandidatesByJobIdSaga(action) {
		try {
			const currentGravityJobCandidates = yield call(
				API.jobsService.getGravityCandidatesForJob,
				action.payload,
			);

			yield put({
				type: TYPES.GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID.SUCCESS,
				payload: currentGravityJobCandidates,
			});
		} catch (error) {
			yield put({ type: TYPES.GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID.FAILED, payload: error });
		}
	}

	function* assignGravityCandidateToVacancySaga({ payload, callback }) {
		try {
			const responseData = yield call(
				API.candidateApplicationService.assignGravityCandidateToVacancy,
				payload,
			);

			yield put({ type: TYPES.ASSIGN_GRAVITY_CANDIDATE_TO_VACANCY.SUCCESS });

			callback && (yield call(callback, responseData));
		} catch (error) {
			yield put({ type: TYPES.ASSIGN_GRAVITY_CANDIDATE_TO_VACANCY.FAILED, payload: error });
		}
	}

	return {
		getCurrentSubscriptionSaga,
		getClientSocialNetworksSaga,
		updateClientSocialNetworksSaga,
		getClientEmailSignatureSaga,
		updateClientEmailSignatureSaga,
		getClientAutoPopSaga,
		updateClientAutoPopSaga,
		getClientSMSNotificationsSaga,
		updateClientSMSNotificationsSaga,
		getUserEmailNotificationsSaga,
		updateUserEmailNotificationsSaga,
		changeClientUserPasswordSaga,
		getATSUserPersonalDataSaga,
		updateATSUserPersonalDataSaga,
		assignCandidateToVacancySaga,
		orderByIdSaga,
		getUnappliedCandidateJobsSaga,
		archiveAndUnarchiveSaga,
		getAtsUserByIdSaga,
		getAtsUsersSaga,
		getAtsUserRolesSaga,
		createAtsUserSaga,
		deleteAtsUserSaga,
		updateAtsUserSaga,
		copyPostJobChangesFormSaga,
		deleteAssessmentFormSaga,
		getAndSavePostJobChangesFormSaga,
		getATSBrandingByClientIdSaga,
		getATSCareerBrandingByClientIdSaga,
		getClientATSApplicationMessagesByClientIdSaga,
		getClientApplicationNotificationSaga,
		getCurrentInvoiceSaga,
		getAtsInvoicesSaga,
		getJobUpgrades,
		getCreditsSaga,
		getAtsTicketsSaga,
		getAtsTicketDetailsSaga,
		getAllAtsJobsSaga,
		getAtsActiveJobsSaga,
		updateAtsBrandingSaga,
		updateAtsCareerBrandingSaga,
		updateClientATSApplicationMessagesSaga,
		updateRejectionMessagePerJobSaga,
		getAtsCandidateApplicationByJobIdSaga,
		getCandidateAssessmentApplicationSaga,
		getAtsInterviewTypeSaga,
		getAtsUserInterviewSaga,
		getAtsInterviewByIdSaga,
		cancelAtsInterviewByIdSaga,
		saveAtsInterviewSaga,
		updateAtsInterviewSaga,
		getAtsInterviewJobsListSaga,
		getAtsEditInterviewJobsListSaga,
		getAtsInterviewCalendarSaga,
		orderCreditsSaga,
		resendAtsInterviewLinkSaga,
		createOrderInvoiceSaga,
		getSubscriptionTypesSaga,
		createATSTicketSaga,
		getCandidateNotesByIdSaga,
		addNoteToCandidateSaga,
		getCandidatesDatabaseSaga,
		getCandidateDatabaseByIdSaga,
		getCandidateSMSInformationSaga,
		updateCandidatePhoneNumberSaga,
		sendSMSToCandidateSaga,
		getAtsVideoInterviewCountSaga,
		orderSmsSaga,
		orderInterviewsSaga,
		showHideCandidateDatabaseSaga,
		assignCandidateToVacancyDatabaseSaga,
		forwardInvoiceSaga,
		getCandidateStatisticsSaga,
		getApplicationsStatisticsForLastMonthSaga,
		getCandidateApplicationPerStateStatisticsSaga,
		getApplicationMonthStatistic,
		getAcceptedOffersStatisticsSaga,
		getDashboardUnreadEmailsSaga,
		getApplicationSourcesStatisticsSaga,
		getTimeToHireMonthSaga,
		getTimeToHireYearSaga,
		getApplicationYearStatisticsSaga,
		getApplicationMontStatisticSaga,
		getAtsAllJobsListSaga,
		getAtsAllCandidateApplicationByJobIdSaga,
		createAtsEmailThreadSaga,
		getAtsEmailTemplatesSaga,
		getAtsEmailInboxSaga,
		getAtsEmailThreadByIdSaga,
		postAtsToExistingEmailThreadSaga,
		getClientTwoFASaga,
		updateClientTwoFASaga,
		getGravityJobsSaga,
		getListOfGravityCandidatesByJobIdSaga,
		assignGravityCandidateToVacancySaga,
	};
};
