import React, { type FC, useState, useCallback, useMemo } from 'react';

import { message, Modal } from 'antd';

import API from 'api';
import { ButtonTypes } from 'components/Button/Button.types';
import CheckCircleIcon from 'components/SVG/CheckCircleIcon';
import CrossCircleIcon from 'components/SVG/CrossCircleIcon';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { ICurrentSubscription } from 'modules/Common/types';
import colors from 'theme/colors';
import { DataFormatEnum } from 'types';
import { getDateFormat } from 'utils/helpers';

import { Styled as StyledCommon } from '../Subscriptions.styled';

import { getSubscritionStandartBenefits } from './SubscriptionStandartBenefits';
import { Styled } from './SubscriptionStandartBlock.styled';

export type SubscriptionStandartBlockProps = {
	isActive: boolean;
	internalLoading: boolean;
	getCurrentSubscriptionInfo: () => void;
	isTrial: boolean;
	currency: string;
	region: number;
	currentSubscription: ICurrentSubscription;
};

const SubscriptionStandartBlock: FC<SubscriptionStandartBlockProps> = ({
	isActive,
	internalLoading,
	isTrial,
	currency,
	region,
	getCurrentSubscriptionInfo,
	currentSubscription,
}) => {
	const SubscritionStandartBenefits = useMemo(
		() => getSubscritionStandartBenefits(region),
		[region],
	);
	const [loadDiscard, seLoadtDiscard] = useState(false);
	const [applyModalOpen, setApplyModalOpen] = useState(false);
	const handleCancel = () => setApplyModalOpen(false);
	const handleBackToStandard = () => setApplyModalOpen(true);

	const handleDiscardPremium = useCallback(async () => {
		try {
			const useTrialFlow = isTrial && currentSubscription.premiumStatus !== 'SUCCESS';

			setApplyModalOpen(false);
			seLoadtDiscard(true);

			if (useTrialFlow) {
				await API.subscriptionsService.cancelClientPremiumTrial();
			} else {
				await API.subscriptionsService.discardClientPremium();
			}

			message.success(
				useTrialFlow
					? 'You have successfully switched to the standard subscription.'
					: 'You have successfully switched to the standard subscription. You\'ll continue to enjoy access to your premium features until the end of the current subscription period',
			);
			getCurrentSubscriptionInfo();
		} catch (e) {
			message.error('error');
		} finally {
			seLoadtDiscard(() => false);
		}
	}, [isTrial]);

	return (
		<StyledCommon.SubscriptionBlock isActive={isActive}>
			<StyledCommon.SubscriptionBlockTitle>Standard</StyledCommon.SubscriptionBlockTitle>
			<StyledCommon.SubscriptionPrice>{currency} 0</StyledCommon.SubscriptionPrice>
			<StyledCommon.SubscriptionPriceDesc>per month </StyledCommon.SubscriptionPriceDesc>
			<StyledCommon.SubscriptionBenefits>
				{SubscritionStandartBenefits.map((b) => {
					return (
						<StyledCommon.SubscriptionBenefit key={b.name}>
							{b.value ? <CheckCircleIcon /> : <CrossCircleIcon fill={colors.lightGray1} />}
							<StyledCommon.SubscriptionBenefitValue>
								{b.name}
							</StyledCommon.SubscriptionBenefitValue>
						</StyledCommon.SubscriptionBenefit>
					);
				})}
			</StyledCommon.SubscriptionBenefits>

			<StyledCommon.SubscriptionButtonWrapper>
				{isActive ? (
					<ThemedButton isFullWidth>Current Plan</ThemedButton>
				) : (
					<>
						{(currentSubscription.premiumStatus === 'SUCCESS' ||
							(currentSubscription.trial && currentSubscription.premiumStatus !== 'CANCELLED')) && (
							<>
								<ThemedButton
									isFullWidth
									disabled={loadDiscard || internalLoading}
									buttonType={ButtonTypes.primary}
									onClick={handleBackToStandard}
								>
									Back to Standard
								</ThemedButton>
								<Modal
									open={applyModalOpen}
									footer={null}
									centered
									onCancel={handleCancel}
									width={500}
								>
									<Styled.ConfirmModalTitle>
										Are you sure? By reverting to the standard subscription, you will no longer have
										access to premium features
									</Styled.ConfirmModalTitle>
									<Styled.ConfirmModalButtons>
										<ThemedButton buttonType={ButtonTypes.secondary} onClick={handleCancel}>
											No
										</ThemedButton>
										<ThemedButton buttonType={ButtonTypes.primary} onClick={handleDiscardPremium}>
											Yes
										</ThemedButton>
									</Styled.ConfirmModalButtons>
								</Modal>
							</>
						)}
						{currentSubscription.premiumStatus === 'CANCELLED' && (
							<StyledCommon.SubscriptionFooterDescWrapper>
								<StyledCommon.SubscriptionFooterDesc>
									You have successfully cancelled your subscription, you will be able to use all pro
									features until{' '}
									{getDateFormat(currentSubscription.premiumEndDate, DataFormatEnum.Full)}
								</StyledCommon.SubscriptionFooterDesc>
							</StyledCommon.SubscriptionFooterDescWrapper>
						)}
					</>
				)}
			</StyledCommon.SubscriptionButtonWrapper>
			{currentSubscription.premiumStatus !== 'CANCELLED' && (
				<StyledCommon.SubscriptionFooterDescWrapper>
					<StyledCommon.SubscriptionFooterDesc>
						{isActive
							? 'Upgrade to PRO today and get better result'
							: 'You can back to standard plan any time'}
					</StyledCommon.SubscriptionFooterDesc>
				</StyledCommon.SubscriptionFooterDescWrapper>
			)}
		</StyledCommon.SubscriptionBlock>
	);
};

export default SubscriptionStandartBlock;
