import { type FC, useState, useEffect, useMemo } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';

import { InboxOutlined } from '@ant-design/icons';
import { UploadFile } from 'antd';
import { EditorState } from 'draft-js';
import createDecorator from 'final-form-calculate';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import DownloadIcon from 'components/SVG/DownloadIcon';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import FieldEditorControlled from 'modules/Common/components/FieldEditorControlled';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedTicketUploadFormFileFormat } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { convertContentFromHtml } from 'modules/Common/utils/editorHelper';
import { COLORS } from 'theme';
import { convertFieldInputToHtml, handleFileDownload } from 'utils/helpers';

import { Styled } from './ReplyEmailForm.styled';

type ReplyEmailFormProps = {
	submitButtonLoading?: boolean;
	hideMessageLabel?: boolean;
	clientEmailSignature?: string;
	handleSubmitReply: (values: { content: string; attachments: string[] }, cb: () => void) => void;
};

const ReplyEmailForm: FC<ReplyEmailFormProps> = ({
	submitButtonLoading,
	hideMessageLabel = true,
	clientEmailSignature,
	handleSubmitReply,
}) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [formValidationError, setFormValidationError] = useState<boolean>(false);
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const emailSignatureValid = clientEmailSignature && typeof clientEmailSignature === 'string';

	const editorInitState = emailSignatureValid
		? convertContentFromHtml(clientEmailSignature)
		: EditorState.createEmpty();

	const onSubmit = (values: { content: string; attachments: string[] }, form: unknown) => {
		if (!values.attachments?.length && !editorState.getCurrentContent().hasText())
			return setFormValidationError(true);

		const valuesToSend = {
			content: convertFieldInputToHtml(editorState),
			attachments: values.attachments,
		};

		const resetForm = () => {
			form?.reset();
			setFileList([]);
			setEditorState(editorInitState);
		};

		handleSubmitReply(valuesToSend, () => resetForm());
	};

	useEffect(() => {
		setFormValidationError(false);
	}, [fileList]);

	useEffect(() => {
		editorInitState && setEditorState(editorInitState);
	}, [clientEmailSignature]);

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				onChange([...value, responseData]);
			},
			{
				fileList,
				accept: acceptedTicketUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					onChange([...value.filter((item) => item !== file?.response?.data)]);
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Email,
				maxCount: undefined,
			},
			setFileList,
		);

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const handleEditorChange = (newState: EditorState) => {
		setEditorState(newState);
	};

	const formDecorator = useMemo(
		() =>
			createDecorator({
				field: 'content',
				updates: () => {
					setFormValidationError(false);

					return {};
				},
			}),
		[],
	) as never;

	return (
		<Styled.Root>
			<Form
				onSubmit={(values, form) => onSubmit(values, form)}
				initialValues={{}}
				autoComplete='off'
				decorators={[formDecorator]}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<FormBlock style={{ borderRadius: '0 0 10px 10px', boxShadow: 'none' }}>
							<FormBlockLine columns={1}>
								<Field name='content'>
									{({ input, meta }) => {
										return (
											<FieldWrapper
												name='content'
												label={hideMessageLabel ? '' : 'Message'}
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												isFixed
											>
												<>
													<FieldEditorControlled
														editorState={editorState}
														handleChange={handleEditorChange}
														placeholder='Enter your message'
														minRaws={20}
													/>
												</>
											</FieldWrapper>
										);
									}}
								</Field>
							</FormBlockLine>
							<FormBlockLine columns={1}>
								<Field name='attachments'>
									{({ input, meta }) => (
										<FieldWrapper
											isFixed
											name='attachments'
											label='Attachments'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Styled.Dragger
												{...draggerProps(input)}
												listType='text'
												onPreview={handleDownload}
												showUploadList={{
													showDownloadIcon: true,
													downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
												}}
											>
												<p className='ant-upload-drag-icon'>
													<InboxOutlined />
												</p>
												<p className='ant-upload-text'>Upload a files or drag and drop it</p>
												<p className='ant-upload-hint'>
													{' '}
													PDF, DOC, DOCX, XLS, XLSX, CSV, JPEG, PNG up to 15MB{' '}
												</p>
											</Styled.Dragger>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							{formValidationError && (
								<Styled.ValidationError>
									You must provide either some content or an attachment.
								</Styled.ValidationError>
							)}
							<Styled.ButtonBox>
								<ThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									loading={false}
									disabled={submitButtonLoading}
								>
									{'Send Message'}
								</ThemedButton>
							</Styled.ButtonBox>
						</FormBlock>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		jobApplyState: commonDucks.commonSelectors.getJobApply(state),
		applyOnJobLoading: commonDucks.commonSelectors.commonLoading(state).applyOnJobLoad,
	}),
	{
		getJobAssessmentRequested: commonDucks.commonActions.getJobAssessmentRequested,
		applyOnJobRequested: commonDucks.commonActions.applyOnJobRequested,
		setJobApplyState: commonDucks.commonActions.setJobApplyState,
	},
)(ReplyEmailForm);
