import React, { type FC, ReactElement, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { Button, Dropdown, Tabs, Tooltip } from 'antd';

import { getJobCandidatesCsvAts } from 'api/endpoints/jobs';
import Spinner from 'components/Spinner';
import CalendarIcon from 'components/SVG/CalendarIcon';
import ClockIcon from 'components/SVG/ClockIcon';
import DownloadIcon from 'components/SVG/DownloadIcon';
import EmailFilledIcon from 'components/SVG/EmailFilledIcon';
import EmailIcon from 'components/SVG/EmailIcon';
import MessageIcon from 'components/SVG/MessageIcon';
import PhoneIcon from 'components/SVG/PhoneIcon';
import RemainderIcon from 'components/SVG/RemainderIcon';
import Notes from 'modules/ATS/components/Notes/Notes';
import { atsDucks } from 'modules/ATS/ducks';
import CandidateAssessmentForm from 'modules/Common/components/JobViewComponents/CandidateAssessmentForm';
import QuestionnaireReview from 'modules/Common/components/JobViewComponents/QuesitionnaireReview';
import VideoQuestionnaireForm from 'modules/Common/components/JobViewComponents/VideoQuestionnaireForm';
import PDFViewer from 'modules/Common/components/PDFViewer';
import Ranking from 'modules/Common/components/Ranking';
import SendQuestionnaireModal from 'modules/Common/components/SendQuestionnareModal';
import { commonDucks } from 'modules/Common/ducks';
import {
	CandidateStatusType,
	IAssessmentQuestions,
	ICandidateNotes,
	ICandidatesData,
	IQuestionnairesPaginated,
	IUser,
	JobDetailsViewTabIds,
	JobDetailsViewTabValues,
	SendCandidateQuestionnaireType,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { COLORS } from 'theme';
import { DataFormatEnum, GenericType, Routes, SettingsRoutes, SubscriptionPlansType } from 'types';
import { downloadCsv, getCVCLDownloadUrls, getDateFormat, truncateString } from 'utils/helpers';

import { statusOptions } from '../CandidatesApplication/CandidatesApplication.constants';
import {
	CandidatesStatusEnum,
	QuestionnaireStatusEnum,
} from '../CandidatesApplication/CandidatesApplication.types';
import { DownloadAttachmentsOptions } from '../DownloadAttachments/DownloadAttachments';

import { Styled } from './CandidateApplicationDetails.styled';

type CandidateApplicationDetailsProps = {
	user: IUser;
	candidateNotes: ICandidateNotes[];
	currentJobId: string;
	currentCandidate: Partial<ICandidatesData>;
	panelMenuComponent: () => ReactElement;
	showInfoList?: boolean;
	sendQuestionnairesData: IQuestionnairesPaginated;
	getSendQuestionnairesList: (
		jobId: number | undefined,
		params: { size: number | undefined; page: number | undefined },
	) => void;
	getCandidateAssessmentRequested: (id: number) => void;
	getCandidateNotesByIdRequested: (id: number) => void;
	sendCandidateQuestionnaire: SendCandidateQuestionnaireType;
	candidateAssessment: IAssessmentQuestions[];
	updateCandidateStatus: CandidateStatusType;
	updateCandidateRank: (value: number, cb: () => void) => void;
	addNoteToCandidateRequested: (id: string | number, noteText: string, cb?: () => void) => void;
	subscriptionPlans: SubscriptionPlansType;
	defaultTab?: JobDetailsViewTabIds;
	loadingAts: GenericType;
	loading: GenericType;
};

const baseUrl = `${process.env.REACT_APP_API_URL}`;

const CandidateApplicationDetails: FC<CandidateApplicationDetailsProps> = ({
	user,
	candidateNotes,
	currentJobId,
	currentCandidate,
	panelMenuComponent,
	showInfoList,
	sendQuestionnairesData,
	getSendQuestionnairesList,
	sendCandidateQuestionnaire,
	updateCandidateStatus,
	updateCandidateRank,
	getCandidateAssessmentRequested,
	getCandidateNotesByIdRequested,
	addNoteToCandidateRequested,
	candidateAssessment,
	subscriptionPlans,
	defaultTab,
	loadingAts,
	loading,
}) => {
	const navigate = useNavigate();
	const location = useLocation();

	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [showMore, setShowMore] = useState<boolean>(false);
	const [tab, setTab] = useState<string>(defaultTab || JobDetailsViewTabIds.CV);

	const showMoreLength =
		!!currentCandidate?.jobsApplied?.length && currentCandidate?.jobsApplied?.length > 2;

	const handleShowMore = () => {
		setShowMore((prevState) => !prevState);
	};
	const appliedJobs = currentCandidate?.jobsApplied
		?.filter((f) => f.jobId !== +currentJobId)
		.slice(0, showMore ? currentCandidate?.jobsApplied?.length : 2);

	useEffect(() => {
		if (currentCandidate?.id) {
			getCandidateAssessmentRequested(currentCandidate.id);
			getCandidateNotesByIdRequested(currentCandidate.id);
		}
	}, [currentCandidate]);

	const { CV, CL } = getCVCLDownloadUrls(currentCandidate?.candidateAttachments);
	const CVReferenceUuid = CV?.s3FileReference?.referenceUuid || CV?.referenceUuid;
	const CLReferenceUuid = CL?.s3FileReference?.referenceUuid || CL?.referenceUuid;

	const handleDownloadCandidates = downloadCsv(
		() => getJobCandidatesCsvAts(currentJobId),
		`job-${currentJobId}-candidates`,
	);

	const handleAddNoteRequest = (noteText: string, cb: () => void) => {
		currentCandidate?.id && addNoteToCandidateRequested(currentCandidate.id, noteText, cb);
	};

	const handleOpenModal = useCallback(() => {
		if (subscriptionPlans?.isPremiumPlan) {
			getSendQuestionnairesList(currentCandidate?.job, {
				page: sendQuestionnairesData?.pageIndex,
				size: sendQuestionnairesData?.pageSize,
			});
		}
		setModalOpen(() => true);
	}, [sendQuestionnairesData]);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleTableChange = useCallback(
		(page: number, size: number) => {
			currentCandidate?.job &&
				getSendQuestionnairesList(currentCandidate?.job, {
					page,
					size: size ?? sendQuestionnairesData?.pageIndex,
				});
		},
		[currentCandidate?.job, sendQuestionnairesData?.pageIndex],
	);

	const handleSendQuestionnaire = useCallback(
		(questionnaireId: number) => {
			currentCandidate?.job &&
				questionnaireId &&
				sendCandidateQuestionnaire(
					{ questionnaireId, candidateIds: [currentCandidate?.id] },
					+currentCandidate?.job,
					() => {
						setModalOpen(false);
					},
				);
		},
		[currentCandidate?.id],
	);

	const handleChangeTabs = (id: JobDetailsViewTabIds | string) => {
		setTab(id);
	};

	const handleSendSMSAction = useCallback((id: number) => {
		navigate(`${Routes.ATS}${Routes.CandidatesSendSMS}`, {
			state: { selectedCandidateIds: [id], prevPage: location.pathname },
		});
	}, []);

	const allowInterviewButton =
		currentCandidate?.candidateState?.name === CandidatesStatusEnum.Interviews;

	const isSentQuestionnaire =
		currentCandidate?.videoQuestionnaireInfo?.videoQuestionnaireStatus ===
		QuestionnaireStatusEnum.Sent;

	const isQuestionnaireFinished =
		currentCandidate?.videoQuestionnaireInfo?.videoQuestionnaireStatus ===
		QuestionnaireStatusEnum.Finished;

	const isDisabledQuestionnaire =
		currentCandidate?.videoQuestionnaireInfo === null ||
		currentCandidate?.videoQuestionnaireInfo?.videoQuestionnaireStatus ===
			QuestionnaireStatusEnum.Failed ||
		currentCandidate?.videoQuestionnaireInfo?.videoQuestionnaireStatus ===
			QuestionnaireStatusEnum.Expired;

	const DownloadTypeData = currentCandidate?.candidateAttachments
		? DownloadAttachmentsOptions(
			currentCandidate?.candidateAttachments,
			currentCandidate.name || '',
		  )
		: [];

	const items = [
		{
			label: JobDetailsViewTabValues.CV,
			key: JobDetailsViewTabIds.CV,
			children: (
				<>
					{CVReferenceUuid && <PDFViewer pdfUrl={`${baseUrl}/file/${CVReferenceUuid}`} isBorder />}
					<Notes
						notes={candidateNotes}
						user={user}
						noteLoading={loadingAts.addNoteToCandidateLoad as boolean}
						handleAddNoteRequest={handleAddNoteRequest}
					/>
				</>
			),
		},
		{
			label: JobDetailsViewTabValues.AssessmentForm,
			key: JobDetailsViewTabIds.AssessmentForm,
			children: candidateAssessment?.length ? (
				<CandidateAssessmentForm data={candidateAssessment} />
			) : loadingAts.getCandidateAssessmentApplicationLoad ? (
				<Spinner />
			) : null,
		},
		{
			label: JobDetailsViewTabValues.CoverLetter,
			key: JobDetailsViewTabIds.CoverLetter,
			children: (
				<>
					{currentCandidate?.coverLetter && (
						<Styled.InfoText>{currentCandidate?.coverLetter}</Styled.InfoText>
					)}
					{CLReferenceUuid && <PDFViewer pdfUrl={`${baseUrl}/file/${CLReferenceUuid}`} />}
				</>
			),
		},
		...(!isDisabledQuestionnaire
			? [
				{
					label: (
						<Tooltip
							placement='top'
							trigger={'hover'}
							title={
								currentCandidate?.videoQuestionnaireInfo?.restricted
									? 'Questionnaire details are restricted.'
									: null
							}
						>
							<>{JobDetailsViewTabValues.VideoQuestionnaire}</>
						</Tooltip>
					),
					key: JobDetailsViewTabIds?.VideoQuestionnaire,
					children: subscriptionPlans?.isPremiumPlan ? (
						<>
							{isSentQuestionnaire && (
								<Styled.HintText>Candidate has not yet submitted the answers.</Styled.HintText>
							)}
							{isQuestionnaireFinished ? (
								<QuestionnaireReview candidateId={currentCandidate?.id} />
							) : (
								<VideoQuestionnaireForm candidateId={currentCandidate?.id} />
							)}
						</>
					) : (
						<>
							<p>This feature require a pro membership, upgrade or try free here</p>
							<Button type='primary' onClick={() => navigate(SettingsRoutes.Subscription)}>
									Upgrade Plan
							</Button>
						</>
					),
				},
			  ]
			: []),
	];

	return (
		<Styled.Root>
			<div>
				<Styled.DownloadButtonWrapper>
					<div>All</div>
					<Button type='link' htmlType='button' onClick={handleDownloadCandidates}>
						Download CSV
					</Button>
				</Styled.DownloadButtonWrapper>
				<Styled.PanelMenu>{panelMenuComponent()}</Styled.PanelMenu>
			</div>
			{loading?.getJobByIdLoad ? (
				<Spinner />
			) : (
				<>
					<Styled.DetailsInfo>
						<Styled.DetailsInfoHead>
							<Styled.HeadWrapper>
								<Styled.HeadTitle>
									<Styled.Title>{currentCandidate?.name}</Styled.Title>
									<Ranking value={currentCandidate?.rank ?? 0} onChange={updateCandidateRank} />
								</Styled.HeadTitle>
								<Styled.HeadActions>
									<Tooltip placement='top' title='Send Questionnaire'>
										<Button
											icon={<RemainderIcon width='24' height='24' />}
											size='large'
											onClick={handleOpenModal}
											disabled={
												currentCandidate?.videoQuestionnaireInfo?.videoQuestionnaireStatus ===
												QuestionnaireStatusEnum.Sent
											}
										/>
									</Tooltip>
									<Link
										to={`${Routes.ATS}${Routes.ArrangeInterviewCreate}`}
										state={{ candidate: currentCandidate?.id, jobId: currentCandidate?.job }}
									>
										<Tooltip placement='top' title='Arrange Interview'>
											<Button
												icon={<CalendarIcon width='24' height='24' />}
												size='large'
												disabled={!allowInterviewButton}
											/>
										</Tooltip>
									</Link>
									<Tooltip placement='top' title='Send Message'>
										<Link
											to={`${Routes.ATS}${Routes.CreateMessage}`}
											state={{
												candidateList: [
													{ id: currentCandidate?.id, fullName: currentCandidate?.name },
												],
											}}
										>
											<Button
												icon={<EmailIcon fill={COLORS.black} width='24' height='24' />}
												size='large'
												onClick={() =>
													currentCandidate?.id && handleSendSMSAction(currentCandidate.id)
												}
											/>
										</Link>
									</Tooltip>
									<Tooltip placement='top' title='Send SMS'>
										<Button
											icon={<MessageIcon fill={COLORS.black} width='24' height='24' />}
											size='large'
											onClick={() =>
												currentCandidate?.id && handleSendSMSAction(currentCandidate.id)
											}
										/>
									</Tooltip>
									<Styled.DownloadPopup>
										<Dropdown
											disabled={!DownloadTypeData?.length}
											menu={{
												items: DownloadTypeData,
											}}
											placement='bottom'
										>
											<Button icon={<DownloadIcon width='24' height='24' />} size='large' />
										</Dropdown>
									</Styled.DownloadPopup>
									<Styled.Select
										value={statusOptions.find(
											(so) => so.value === currentCandidate?.candidateState?.value,
										)}
										onChange={(e) =>
											currentCandidate?.id &&
											updateCandidateStatus &&
											updateCandidateStatus([+currentCandidate?.id], e, currentJobId)
										}
									>
										{statusOptions.map((option) => (
											<Styled.SelectOption key={option.id} value={option.value}>
												{option.name}
											</Styled.SelectOption>
										))}
									</Styled.Select>
								</Styled.HeadActions>
							</Styled.HeadWrapper>
							<Styled.CandidateInfo>
								{currentCandidate?.phoneNumber && (
									<a href={`tel:${currentCandidate?.phoneNumber}`}>
										<PhoneIcon fill={COLORS.gray} width='16' height='16' />
										<span>{currentCandidate?.phoneNumber}</span>
									</a>
								)}
								{currentCandidate?.email && (
									<a href={`mailto:${currentCandidate?.email}`}>
										<EmailFilledIcon fill={COLORS.gray} width='16' height='16' />
										<span>{truncateString(currentCandidate?.email, 35)?.truncated}</span>
									</a>
								)}
								{currentCandidate?.created && (
									<Styled.CandidateInfoDate>
										<ClockIcon fill={COLORS.gray} width='16' height='16' />
										<span>
											Applied on {getDateFormat(currentCandidate.created, DataFormatEnum.Full)}
										</span>
									</Styled.CandidateInfoDate>
								)}
							</Styled.CandidateInfo>
							<Styled.AdditionalInfo>
								{showInfoList && !!appliedJobs?.length && (
									<>
										<Styled.AdditionalInfoListHeading>
											Also applied:{' '}
										</Styled.AdditionalInfoListHeading>
										<Styled.AdditionalInfoList>
											{appliedJobs.map((job) => (
												<Styled.AdditionalInfoListTitle key={job.jobId}>
													<Styled.AdditionalInfoListTitleLink
														to={`${Routes.ATS}${Routes.Jobs}/${job.jobId}`}
													>
														{job.title}
													</Styled.AdditionalInfoListTitleLink>
													<Styled.Tag>
														{/* TODO Change this on backend */}
														{statusOptions.find((status) => status.value === job.status)?.name}
													</Styled.Tag>
												</Styled.AdditionalInfoListTitle>
											))}
										</Styled.AdditionalInfoList>
										{showMoreLength && (
											<Styled.ShowButton onClick={handleShowMore}>
												{!showMore ? 'Show more' : 'Hide'}
											</Styled.ShowButton>
										)}
									</>
								)}
							</Styled.AdditionalInfo>
						</Styled.DetailsInfoHead>
						<Styled.DetailsInfoMain>
							<Tabs
								defaultActiveKey={tab}
								items={items}
								tabBarStyle={{ fontFamily: 'Inter' }}
								onChange={handleChangeTabs}
							/>
						</Styled.DetailsInfoMain>
					</Styled.DetailsInfo>
					<SendQuestionnaireModal
						open={modalOpen}
						subscriptionPlans={subscriptionPlans}
						sendQuestionnaireLink={`${Routes.ATS}${Routes.QuestionnairesSend}/${currentCandidate?.id}`}
						sendQuestionnairesData={sendQuestionnairesData}
						handleSendQuestionnaire={handleSendQuestionnaire}
						onTableChange={handleTableChange}
						onTablePageSizeChange={handleTableChange}
						onClose={handleCloseModal}
						loading={
							!!loading?.sendCandidateQuestionnaireLoad || !!loading?.getSendQuestionnairesListLoad
						}
					/>
				</>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		candidateAssessment: atsDucks.atsSelectors.getCandidateAssessmentApplicationData(state),
		candidateNotes: atsDucks.atsSelectors.getCandidateNotes(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		sendQuestionnairesData: commonDucks.commonSelectors.getSendQuestionnaires(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
		loadingAts: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getSendQuestionnairesList: commonDucks.commonActions.getSendQuestionnairesListRequested,
		getCandidateAssessmentRequested: atsDucks.atsActions.getCandidateAssessmentApplicationRequested,
		sendCandidateQuestionnaire: commonDucks.commonActions.sendCandidateQuestionnaireRequested,
		addNoteToCandidateRequested: atsDucks.atsActions.addNoteToCandidateRequested,
		getCandidateNotesByIdRequested: atsDucks.atsActions.getCandidateNotesByIdRequested,
	},
)(CandidateApplicationDetails);
