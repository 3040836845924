import React, { type FC, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { InboxOutlined } from '@ant-design/icons';
import { Input, Select, UploadFile } from 'antd';
import TextArea from 'antd/es/input/TextArea';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import DownloadIcon from 'components/SVG/DownloadIcon';
import { useMount } from 'hooks';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { acceptedTicketUploadFormFileFormat } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import { FileUploadTypesEnum, IPostTicketValues } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { COLORS } from 'theme';
import { GenericType, IOption, Routes } from 'types';
import { handleFileDownload } from 'utils/helpers';
import {
	composeValidators,
	lengthValidator,
	requiredFieldValidator,
	textValidator,
} from 'utils/validators';

import { Styled } from './PostTicket.styled';

type PostJobProps = {
	ticketTypes: IOption[];
	getTicketsTypesRequested: () => void;
	createATSTicketRequested: (values: IPostTicketValues, callback: () => void) => void;
	atsLoading: GenericType;
};

const { Option } = Select;

const PostTicket: FC<PostJobProps> = ({
	ticketTypes,
	getTicketsTypesRequested,
	createATSTicketRequested,
	atsLoading,
}) => {
	const [fileList, setFileList] = useState<UploadFile[]>([]);

	const navigate = useNavigate();

	const onSubmit = (values: IPostTicketValues) => {
		createATSTicketRequested({ ...values }, () => navigate(`${Routes.ATS}${Routes.SupportCenter}`));
	};

	useMount(() => {
		!ticketTypes?.length && getTicketsTypesRequested();
	});

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				onChange([...value, responseData]);
			},
			{
				fileList,
				accept: acceptedTicketUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					onChange([...value.filter((item) => item !== file?.response?.data)]);
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Ticket,
				maxCount: undefined,
			},
			setFileList,
		);

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const submitButtonLoading = atsLoading?.createAtsTicketLoad;

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={{}}
				autoComplete='off'
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<FormBlock>
							<FormBlockLine>
								<Field name='ticketType' validate={requiredFieldValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='ticketType'
											label='Ticket Type'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Select
												{...input}
												value={input.value || null}
												placeholder='Choose ticket type'
											>
												{ticketTypes?.map((option: IOption) => (
													<Option key={option.id} value={option.id}>
														{option.name}
													</Option>
												))}
											</Select>
										</FieldWrapper>
									)}
								</Field>
								<Field
									name='subject'
									validate={composeValidators(lengthValidator(1, 255), requiredFieldValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='subject'
											label='Subject'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input
												{...input}
												placeholder='Job Reference or another problem'
												autoComplete='off'
											/>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine columns={1}>
								<Field
									name='description'
									validate={composeValidators(requiredFieldValidator, textValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='description'
											label='Description'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<TextArea
												{...input}
												placeholder='Enter ticket description'
												autoSize={{ minRows: 8, maxRows: 8 }}
											/>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
							<FormBlockLine columns={1}>
								<Field name='attachments'>
									{({ input, meta }) => (
										<FieldWrapper
											isFixed
											name='attachments'
											label='Attachments'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Styled.Dragger
												{...draggerProps(input)}
												listType='text'
												onPreview={handleDownload}
												showUploadList={{
													showDownloadIcon: true,
													downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
												}}
											>
												<p className='ant-upload-drag-icon'>
													<InboxOutlined />
												</p>
												<p className='ant-upload-text'>Upload a files or drag and drop it</p>
												<p className='ant-upload-hint'>
													{' '}
													PDF, DOC, DOCX, XLS, XLSX, CSV, JPEG, PNG up to 15MB{' '}
												</p>
											</Styled.Dragger>
										</FieldWrapper>
									)}
								</Field>
							</FormBlockLine>
						</FormBlock>
						<Styled.ButtonBox>
							<ThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								loading={false}
								disabled={!!submitButtonLoading}
							>
								{'Send to Bluetown'}
							</ThemedButton>
						</Styled.ButtonBox>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		ticketTypes: commonDucks.commonSelectors.getTicketTypes(state),
		atsLoading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getTicketsTypesRequested: commonDucks.commonActions.getTicketsTypesRequested,
		createATSTicketRequested: atsDucks.atsActions.createATSTicketRequested,
	},
)(PostTicket);
