import { type FC, useEffect, useState } from 'react';

import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import { DEFAULT_LOGO_CONNECT } from 'modules/Common/constants';
import {
	IContractValues,
	IInvoicesDetailedValues,
	InvoicesTypesValuesEnum,
	ReceiptDataType,
} from 'modules/Common/types';
import { getContractTableData } from 'modules/Common/utils/contracts';
import { getSortedUnitForMonths } from 'modules/Common/utils/invoices';
import { CountriesType, CurrencyType, GenericType } from 'types';
import { getCurrencySymbolByName } from 'utils/helpers';

import { ITableFilteredData } from '../ContractView/ContractView.types';

import EarthSvg from './EarthSvg';
import EmailSvg from './EmailSvg';
import { styles } from './FinancePdf.styles';
import PhoneSvg from './PhoneSvg';
import { smallTableDataTemplate, tableDataTemplate } from './tableDataTemplate';

interface ReceiptPdfProps {
	contract: IContractValues & IInvoicesDetailedValues & ReceiptDataType;
	creditsFields: GenericType[];
	countries: CountriesType;
}

dayjs.extend(utc);

const ReceiptPDF: FC<ReceiptPdfProps> = ({ contract, creditsFields, countries }) => {
	const [data, setContractData] = useState<ITableFilteredData[] | []>([]);

	useEffect(() => {
		const contactsTable =
			(creditsFields && contract && getContractTableData(creditsFields, contract)) || [];
		const interviewSmsTable =
			(contract?.unitForMonths && getSortedUnitForMonths(contract, false)) || [];
		(contactsTable.length || interviewSmsTable.length) &&
			setContractData([...contactsTable, ...interviewSmsTable]);
	}, [creditsFields, contract]);

	const isPONumberShown =
		contract?.invoiceType?.value !== InvoicesTypesValuesEnum.CreditsPurchase &&
		!!contract?.poNumber;

	const bluetownDetails = contract?.bluetownDetails || contract?.blueTownDetails;
	const currency = getCurrencySymbolByName(contract?.currency) || CurrencyType.PoundSterling;

	return (
		<Document>
			<Page size='A4' style={styles.page}>
				<View>
					<View style={styles.section}>
						<View style={styles.headerWrapper}>
							<View style={styles.headerLogo}>
								<Image src={DEFAULT_LOGO_CONNECT} style={{ width: '129px', height: '30px' }} />
							</View>
							<View style={styles.headerTitle}>
								<Text>RECEIPT</Text>
							</View>
						</View>
					</View>
					<View style={styles.section}>
						<View style={styles.contractDetails}>
							<View style={styles.contractDetailsColumn}>
								<View style={styles.contractDetailsItem}>
									<Text>Made by</Text>
								</View>
								<View style={{ ...styles.contractDetailsItem, ...styles.contractDetailsItemHigh }}>
									<Text>{contract?.clientName || contract?.name}</Text>
								</View>
								<View style={styles.contractDetailsItem}>
									<Text>{contract?.clientAddress || contract?.contractAddress}</Text>
								</View>
								{isPONumberShown && (
									<View style={{ ...styles.poNumber, ...styles.contractDetailsItem }}>
										<Text>{'PO Number: '}</Text>
										<Text>{contract?.poNumber}</Text>
									</View>
								)}
							</View>
							<View style={styles.contractDetailsColumn}>
								<View style={styles.contractDetailsItem}></View>

								<View>
									<View style={styles.contractDetailsItem}>
										<Text>Reference</Text>
									</View>
									<View
										style={{ ...styles.contractDetailsItem, ...styles.contractDetailsItemHigh }}
									>
										<Text>
											{contract?.invoiceType?.value === InvoicesTypesValuesEnum.ContractAccept
												? 'Contract: '
												: 'Invoice: '}
											{contract?.btoReference}
										</Text>
									</View>
								</View>
								<View>
									<View style={styles.contractDetailsItem}>
										<Text>Date</Text>
									</View>
									<View
										style={{ ...styles.contractDetailsItem, ...styles.contractDetailsItemHigh }}
									>
										<Text>
											{countries?.isUK
												? dayjs(contract?.dateOfPayment).format('DD/MM/YYYY h:m A')
												: dayjs(contract?.dateOfPayment).format('MM/DD/YYYY h:m A')}
										</Text>
									</View>
								</View>
							</View>
						</View>
					</View>
					<View style={styles.section}>
						<View style={styles.contractCalculation}>
							<View style={styles.contractCalculationTable}>
								<View style={styles.contractCalculationTableHeader}>
									{smallTableDataTemplate.map((dt, idx) => (
										<View
											key={dt.id}
											style={{
												...styles.contractCalculationTableHeaderItem,
												width: dt.width,
												textAlign: idx ? 'center' : 'left',
											}}
										>
											<Text>{dt.label}</Text>
										</View>
									))}
								</View>
								{contract?.totalPrice &&
									data?.map((contractData, idx) => (
										<View key={idx} style={styles.contractCalculationTableRow}>
											<View
												style={{
													...styles.contractCalculationTableCell,
													width: tableDataTemplate[0].width,
													textAlign: 'left',
												}}
											>
												{<Text>{contractData?.name}</Text>}
											</View>
											<View
												style={{
													...styles.contractCalculationTableCell,
													width: tableDataTemplate[3].width,
												}}
											>
												<Text>{`${currency}${(contractData?.unitPrice
													? Number(contractData?.unitPrice)
													: 0
												).toFixed(2)}`}</Text>
											</View>
											<View
												style={{
													...styles.contractCalculationTableCell,
													width: tableDataTemplate[3].width,
													fontWeight: 'bold',
												}}
											>
												<Text>
													{`${currency}${(contractData?.totalPrice
														? Number(contractData?.totalPrice)
														: 0
													).toFixed(2)}`}
												</Text>
											</View>
										</View>
									))}
								<View></View>
								{contract?.vatPrice && contract?.totalPriceExVat && (
									<View
										style={{ ...styles.contractCalculationTableRow, backgroundColor: '#F8F8F8' }}
									>
										<View style={styles.contractCalculationTotalCell}>
											<Text>Total Ex VAT:</Text>
										</View>
										<View
											style={{
												...styles.contractCalculationTotalCell,
												width: tableDataTemplate[3].width,
											}}
										>
											<Text>{`${currency}${Number(contract?.totalPriceExVat).toFixed(2)}`}</Text>
										</View>
									</View>
								)}
								{contract?.vatPrice && (
									<View
										style={{ ...styles.contractCalculationTableRow, backgroundColor: '#F8F8F8' }}
									>
										<View style={styles.contractCalculationTotalCell}>
											<Text>VAT (20%):</Text>
										</View>
										<View
											style={{
												...styles.contractCalculationTotalCell,
												width: tableDataTemplate[3].width,
											}}
										>
											<Text>{`${currency}${Number(contract?.vatPrice).toFixed(2)}`}</Text>
										</View>
									</View>
								)}
								<View style={styles.contractTotalTableWrapper}>
									<View style={styles.contractTotalTableInner}>
										<Text>Total:</Text>
										<Text>{`${currency}${(Number(contract?.totalPrice) ?? 0).toFixed(2)}`}</Text>
									</View>
								</View>
							</View>
						</View>
					</View>
				</View>

				<View>
					{bluetownDetails && (
						<View style={styles.infoSection}>
							<View style={styles.infoItem}>
								<Text>{bluetownDetails}</Text>
							</View>
							<View style={styles.infoItem}></View>
						</View>
					)}

					<View style={styles.footer}>
						<View style={styles.footerItem}>
							<View style={styles.footerItemImg}>
								<PhoneSvg />
							</View>
							<Text>0207 183 6293</Text>
						</View>
						<View style={styles.footerItem}>
							<View style={styles.footerItemImg}>
								<EmailSvg />
							</View>
							<Text>support@bluetownonline.co.uk</Text>
						</View>
						<View style={styles.footerItem}>
							<View style={styles.footerItemImg}>
								<EarthSvg />
							</View>
							<Text>www.bluetownonline.com</Text>
						</View>
					</View>
				</View>
			</Page>
		</Document>
	);
};

export default ReceiptPDF;
