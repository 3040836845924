import styled from 'styled-components/macro';

import { COLORS, device } from 'theme';

import { MENU_NOT_EXTENDED, MENU_EXTENDED } from './Navigation.constants';

interface INavigationProps {
	bgColor?: string;
	isExtended?: boolean;
}

export namespace Styled {
	export const Navigation = styled.aside<INavigationProps>`
		position: relative;
		display: flex;
		width: 100%;
		max-width: ${({ isExtended }) => (isExtended ? MENU_EXTENDED : MENU_NOT_EXTENDED)};
		padding: ${({ isExtended }) => (isExtended ? '0 40px' : '0 23px')};
		background: ${({ bgColor }) => bgColor};
		transition: max-width 0.2s ease;

		@media ${device.desktop} {
			max-width: ${MENU_NOT_EXTENDED};
			padding: 0 23px;
		}
	`;

	export const NavigationContainer = styled.section`
		position: sticky;
		top: 0;
		display: flex;
		align-items: start;
		justify-content: space-between;
		flex-direction: column;
		height: 100vh;
		max-width: ${({ theme }) => theme.size.large};
		margin: 0 auto;
		padding: 26px 0;
	`;

	export const Box = styled.div`
		&:first-of-type {
			margin-bottom: 80px;
		}
	`;

	export const MobileLogo = styled.div<INavigationProps>`
		width: 26px;
		height: 24px;
		margin-bottom: 80px;
		display: ${({ isExtended }) => (isExtended ? 'none' : 'block')};

		@media ${device.desktop} {
			display: block;
		}
	`;

	export const DesktopLogo = styled.div<INavigationProps>`
		display: ${({ isExtended }) => (isExtended ? 'block' : 'none')};
		width: 124px;
		height: 29px;
		margin-bottom: 80px;

		@media ${device.desktop} {
			display: none;
		}
	`;

	export const LogoutButton = styled.a<INavigationProps>`
		display: flex;
		align-items: center;
		margin-top: 20px;
		color: ${COLORS.white};
		cursor: pointer;

		span {
			display: ${({ isExtended }) => (isExtended ? 'block' : 'none')};
			margin-left: 20px;
			transition: color ease 0.2s;
		}

		&:hover,
		&:focus,
		&.active,
		&:hover span,
		&:focus span,
		&:active span,
		&:hover svg path,
		&:focus svg path {
			color: ${({ theme }) => theme.colors.lightBlue1};
			fill: ${COLORS.lightBlue1};
		}

		svg path {
			transition: fill ease 0.2s;
		}

		@media ${device.desktop} {
			span {
				display: none;
			}
		}
	`;
}
