import { type FC } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import Cookies from 'js-cookie';

import { useMount } from 'hooks';
import { IUser } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import {
	AtsRouteRoleMap,
	BORouteRoleMap,
	CookiesType,
	ReactParent,
	Routes,
	UserRolesType,
} from 'types';
import { checkAllowedRoute } from 'utils/helpers';

type AuthLayoutProps = ReactParent & {
	user?: IUser;
	roles: UserRolesType;
	getUserByIdRequested: (userId: string) => void;
};

const AuthLayout: FC<AuthLayoutProps> = ({ getUserByIdRequested, user, roles, children }) => {
	const userId = Cookies.get(CookiesType.UserId);
	const params = useParams();
	const { pathname } = useLocation();

	const isBOAllowedRoutes =
		pathname.includes(Routes.BackOffice) &&
		checkAllowedRoute(pathname, params, BORouteRoleMap, roles);
	const isAtsAllowedRoutes =
		pathname.includes(Routes.ATS) && checkAllowedRoute(pathname, params, AtsRouteRoleMap, roles);

	useMount(() => {
		if (!user?.id && userId) {
			getUserByIdRequested(userId);
		}
	});

	if (
		(user?.userId && roles?.isSuperUser && !isBOAllowedRoutes) ||
		(user?.userId && roles?.isManagerUser && !isBOAllowedRoutes) ||
		(user?.userId && roles?.isSalesUser && !isBOAllowedRoutes)
	) {
		return (
			<Navigate to={Routes.Forbidden} state={`${Routes.BackOffice}${Routes.Dashboard}`} replace />
		);
	}

	if (user?.userId && roles?.isServiceUser && !isBOAllowedRoutes) {
		return (
			<Navigate
				to={Routes.Forbidden}
				state={`${Routes.BackOffice}${Routes.SupportTickets}`}
				replace
			/>
		);
	}

	if (user?.userId && (roles?.isAtsStandardUser || roles?.isAtsSuperUser) && !isAtsAllowedRoutes) {
		return <Navigate to={Routes.Forbidden} state={`${Routes.ATS}${Routes.Dashboard}`} replace />;
	}

	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);

	if (user?.userId && roles?.isAtsFinanceUser && !isAtsAllowedRoutes && isImpersonate) {
		return <Navigate to={`${Routes.ATS}${Routes.Credits}`} replace />;
	}

	if (user?.userId && roles?.isAtsFinanceUser && !isAtsAllowedRoutes) {
		return <Navigate to={Routes.Forbidden} state={`${Routes.ATS}${Routes.Credits}`} replace />;
	}

	return <>{user?.userId && children}</>;
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
	}),
	{
		getUserByIdRequested: unregisteredDucks.unregisteredActions.getUserById,
	},
)(AuthLayout);
