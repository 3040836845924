import React, { type FC } from 'react';

import Button from 'components/Button';
import { IButton } from 'components/Button/Button.types';

import { useAtsLayoutContext } from '../../containers/ATSLayoutContext/ATSLayoutContext';

const ThemedButton: FC<IButton> = ({ children, ...props }) => {
	const { subscriptionPlans, clientBrandingData } = useAtsLayoutContext();

	const buttonProps = {
		...(subscriptionPlans?.isPremiumPlan &&
			clientBrandingData?.highlightColour && {
			buttonColor: clientBrandingData.highlightColour,
		}),
		...props,
	};

	return <Button {...buttonProps}>{children}</Button>;
};

export default ThemedButton;
