import React, { type FC } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';

import { ButtonTypes } from 'components/Button/Button.types';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import { FinancePdf } from 'modules/Common/containers/FinancePdf';
import { commonDucks } from 'modules/Common/ducks';
import { IContractValues } from 'modules/Common/types';
import { Routes, GenericType } from 'types';

import ATSAcceptContractView from '../../containers/ATSAcceptContractView';

import { Styled } from './ATSContractViewPage.styled';

interface ATSContractViewPageProps {
	creditsFields: GenericType[];
	contract: IContractValues;
}

const ATSContractViewPage: FC<ATSContractViewPageProps> = ({ contract, creditsFields }) => {
	const { contractId } = useParams();
	const { state } = useLocation();

	const contractTitle = state?.contractName;

	return (
		<>
			<Styled.Head>
				<PageNavTitle
					title={contractTitle ?? 'Contract details'}
					navigationLink={`${Routes.ATS}${Routes.Finance}`}
				/>
				{contract && creditsFields && (
					<Styled.PdfButtonWrapper>
						<PDFDownloadLink
							document={<FinancePdf contract={contract} creditsFields={creditsFields} />}
							fileName={`${contract?.clientCode ?? contract?.btoReference}-contract.pdf`}
						>
							{({ loading }) => {
								const buttonText = loading ? 'Loading document...' : 'Download PDF';

								return <ThemedButton buttonType={ButtonTypes.secondary}>{buttonText}</ThemedButton>;
							}}
						</PDFDownloadLink>
					</Styled.PdfButtonWrapper>
				)}
			</Styled.Head>
			<Styled.Content>
				<ATSAcceptContractView contractId={contractId} />
			</Styled.Content>
		</>
	);
};

export default connect(
	(state) => ({
		contract: commonDucks.commonSelectors.getContractByIdDetails(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
	}),
	{},
)(ATSContractViewPage);
