import { StyleSheet, Font } from '@react-pdf/renderer';

import {COLORS} from 'theme';

Font.register({
	family: 'Inter',
	fonts: [
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf',
			fontWeight: 400,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuI6fMZhrib2Bg-4.ttf',
			fontWeight: 500,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuGKYMZhrib2Bg-4.ttf',
			fontWeight: 600,
		},
		{
			src: 'https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuFuYMZhrib2Bg-4.ttf',
			fontWeight: 'bold',
		},
	],
});

export const styles = StyleSheet.create({
	page: {
		flexDirection: 'column',
		backgroundColor: 'white',
		height: '100%',
		justifyContent: 'space-between',
		fontFamily: 'Inter',
		color: COLORS.black,
	},
	section: {
		margin: 24,
	},
	headerWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	headerLogo: {},
	headerTitle: {
		fontSize: '40px',
		fontWeight: 'bold',
		letterSpacing: '-0.44px',
		color: COLORS.darkGray3,
	},
	contractDetails: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	contractDetailsColumn: {
		color: '#1E1E20',
		fontSize: '12px',
	},
	contractDetailsItem: {
		paddingBottom: '4px',
	},
	contractDetailsItemHigh: {
		color: '#314EFF',
	},
	contractCalculation: {},
	contractCalculationTable: {
		display: 'flex',
		flexDirection: 'column',
	},
	poNumber: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	contractCalculationTableHeader: {
		backgroundColor: '#F8F8F8',
		paddingVertical: '13px',
		paddingHorizontal: '16px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		width: '100%',
		border: '1px solid #EBEBED',
	},
	contractCalculationTableHeaderItem: {
		fontSize: '10px',
		color: '#5C5C60',
		textAlign: 'center',
		textTransform: 'uppercase',
	},
	contractCalculationTableRow: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		paddingHorizontal: '16px',
		paddingVertical: '15px',
		border: '1px solid #EBEBED',
	},
	contractCalculationTableCell: {
		textAlign: 'center',
		fontSize: '10px',
		color: '#1E1E20',
	},
	contractCalculationTotalCell: {
		textAlign: 'center',
		fontSize: '12px',
		fontWeight: 'demibold',
		color: '#1E1E20',
	},
	contractTotalTableWrapper: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-end',
	},
	contractTotalTableInner: {
		width: '217px',
		paddingVertical: '10px',
		paddingHorizontal: '32px',
		color: '#FFF',
		textAlign: 'center',
		fontSize: '12px',
		backgroundColor: '#314EFF',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
	},
	infoSection: {
		textAlign: 'center',
		marginHorizontal: '8px',
	},
	infoItem: {
		marginBottom: '24px',
		paddingHorizontal: '24px',
		textAlign: 'center',
		fontSize: '10px',
		color: '#1E1E20',
	},
	footer: {
		paddingHorizontal: '40px',
		paddingVertical: '12px',
		backgroundColor: '#314EFF',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
	},
	footerItem: {
		color: '#FFF',
		fontSize: '10px',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'flex-start',
	},
	footerItemImg: {
		marginRight: '4px',
	},
	invoiceSection: {
		display: 'flex',
		marginTop: '24px',
		justifyContent: 'space-between',
		flexDirection: 'row',
		marginLeft: '24px',
		marginRight: '24px',
	},
	invoiceBlock: {
		width: '265px',
	},
	invoiceBlockHeader: {
		borderTopLeftRadius: '6px',
		borderTopRightRadius: '6px',
		color: '#fff',
		fontSize: '12px',
		fontWeight: 'bold',
		textAlign: 'center',
		paddingTop: '10px',
		height: '32px',
		backgroundColor: '#314EFF',
	},
	invoiceBlockContent: {
		padding: '8px',
	},
	invoiceBlockContentItem: {
		paddingBottom: '8px',
		fontSize: '10px',
	},
});
