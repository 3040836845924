import React, { type FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Button, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { isEqual } from 'lodash';

import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import DotIcon from 'components/SVG/DotIcon';
import EmailIcon from 'components/SVG/EmailIcon';
import JobIcon from 'components/SVG/JobIcon';
import MapMarkerIcon from 'components/SVG/MapMarkerIcon';
import UsdIcon from 'components/SVG/UsdIcon';
import { useMount } from 'hooks';
import CandidateAssessmentForm from 'modules/Common/components/JobViewComponents/CandidateAssessmentForm';
import JobRoleInfo from 'modules/Common/components/JobViewComponents/JobRoleInfo';
import { HINTS } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	ApplicationMessagesType,
	ClientBrandingDataType,
	IAssessmentQuestions,
	JobClassesEnum,
	JobDataType,
} from 'modules/Common/types';
import { GenericType, IOption, Routes, SubscriptionPlansType, DataFormatEnum } from 'types';
import { getDateFormat } from 'utils/helpers';

import { atsDucks } from '../../ducks';

import ApplicationMethodView from './ApplicationMethodView';
import { Styled } from './JobView.styled';

type ATSJobsListPageProps = {
	jobId: string;
	jobData: JobDataType;
	getJobById: (jobId: string) => void;
	jobTypes: IOption[];
	educationTypes: IOption[];
	employmentTypes: IOption[];
	getJobAssessment: (jobId: string, isFreeEndpoint?: boolean) => void;
	getJobTypesRequested: (jobId: string) => void;
	updateRejectionMessagePerJob: (jobId: number, value: string) => void;
	getEmploymentTypesRequested: () => void;
	getEducationTypesRequested: () => void;
	getJobIndustriesRequested: () => void;
	jobIndustries: IOption[];
	jobAssessmentData: IAssessmentQuestions[];
	clientAtsBrandingData: ClientBrandingDataType;
	defaultMessages: ApplicationMessagesType;
	subscriptionPlans: SubscriptionPlansType;
	loading: GenericType;
};

const JobView: FC<ATSJobsListPageProps> = ({
	jobId,
	jobData,
	getJobById,
	jobTypes,
	educationTypes,
	employmentTypes,
	getJobAssessment,
	getJobTypesRequested,
	getJobIndustriesRequested,
	getEducationTypesRequested,
	jobIndustries,
	jobAssessmentData,
	clientAtsBrandingData,
	updateRejectionMessagePerJob,
	subscriptionPlans,
	loading,
}) => {
	const navigate = useNavigate();

	const [messageRejection, setMessageRejection] = useState<string>(jobData?.messageRejection || '');
	const [messageRejectionError, setMessageRejectionError] = useState<string>('');
	const { isPremiumPlan } = subscriptionPlans || {};

	const handleCopyEmail = async (value?: string) => {
		if (value) {
			await navigator.clipboard.writeText(value);
			message.success('Email copied');
		}
	};

	const handleChangeMessageRejection = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
		event.preventDefault();

		const { value } = event.target;

		if (!value) {
			setMessageRejectionError('The field is mandatory!');
		} else {
			setMessageRejection(value);
		}
	};

	const handleSaveMessageRejection = () => {
		if (!messageRejectionError) {
			updateRejectionMessagePerJob(+jobId, messageRejection);
			setMessageRejectionError('');
		}
	};

	const handleCancelMessageRejection = () => {
		setMessageRejection(jobData?.messageRejection);
		setMessageRejectionError('');
	};

	const handleEditJob = () => {
		navigate(`${Routes.ATS}${Routes.PostJobEdit}/${jobId}`);
	};

	useEffect(() => {
		jobId && getJobById(jobId);

		jobId && getJobAssessment(jobId, true);
	}, [jobId, isPremiumPlan]);

	useEffect(() => {
		jobData?.messageRejection && setMessageRejection(jobData?.messageRejection);
	}, [jobData?.messageRejection]);

	useMount(() => {
		!jobTypes?.length && getJobTypesRequested(jobId);
		!jobIndustries?.length && getJobIndustriesRequested();
		!educationTypes?.length && getEducationTypesRequested();
	});

	if (loading?.getJobByIdLoad || loading?.getJobAssessmentLoad || loading?.getJobTypesLoad) {
		return <Spinner fixed />;
	}

	const jobType = jobData?.jobType?.name
		? jobData?.jobType?.name
		: jobTypes && jobTypes?.find((item) => item?.id === +jobData?.jobType)?.name;

	return (
		<Styled.Root>
			{jobData?.jobClass !== JobClassesEnum.PaidJob && (
				<Styled.EditButton type='default' htmlType='button' onClick={handleEditJob} size='large'>
					Edit Job
				</Styled.EditButton>
			)}
			{jobData && (
				<Styled.MainInfo>
					<Styled.BoxWrapper>
						<Styled.InfoHead>
							<h2 color={clientAtsBrandingData?.titleColor}>{jobData?.title}</h2>
						</Styled.InfoHead>
						<Styled.InfoDetails>
							{jobData?.location && (
								<Styled.InfoDetailsItem>
									<MapMarkerIcon width='20' height='20' />
									<Styled.Label>{jobData?.location}</Styled.Label>
								</Styled.InfoDetailsItem>
							)}
							{jobData?.salary && (
								<Styled.InfoDetailsItem>
									<span>
										<DotIcon />
									</span>
									<UsdIcon width='20' height='20' />
									<Styled.Label>{jobData?.salary}</Styled.Label>
								</Styled.InfoDetailsItem>
							)}
							{jobType && (
								<Styled.InfoDetailsItem>
									<span>
										<DotIcon />
									</span>
									<JobIcon width='20' height='20' />
									<Styled.Label>{jobType}</Styled.Label>
								</Styled.InfoDetailsItem>
							)}

							{jobData?.startDate && jobData?.endDate && (
								<Styled.Data>
									{`Published: ${getDateFormat(
										jobData?.startDate,
										DataFormatEnum.Full,
									)} - ${getDateFormat(jobData?.endDate, DataFormatEnum.Full)}`}
								</Styled.Data>
							)}
						</Styled.InfoDetails>
					</Styled.BoxWrapper>
					{!!jobData?.generatedEmail && (
						<Styled.BoxWrapper>
							<h2>Redirection Email:</h2>
							<Styled.ContentItem>
								<Styled.ContentItemEmail>
									<EmailIcon width='18' height='18' />
									<a href={`mailto:${jobData?.generatedEmail}`}>{jobData?.generatedEmail}</a>
									<Button onClick={() => handleCopyEmail(jobData?.generatedEmail)} type='primary'>
										Copy email
									</Button>
								</Styled.ContentItemEmail>
								<p>Description: {`${HINTS.JobEmailDescription}`}</p>
							</Styled.ContentItem>
						</Styled.BoxWrapper>
					)}
					<Styled.BoxWrapper>
						<Styled.FieldWrapper>
							<h2>Default rejection message</h2>

							<FieldWrapper name='messageRejection' errorMessage={messageRejectionError}>
								<TextArea
									value={messageRejection}
									onChange={(e) => handleChangeMessageRejection(e)}
									placeholder='Enter message here'
									autoSize={{ minRows: 10 }}
								/>
							</FieldWrapper>
							<Styled.ButtonBox>
								<Button
									onClick={handleCancelMessageRejection}
									type='default'
									htmlType='button'
									disabled={
										!!messageRejectionError || isEqual(jobData?.messageRejection, messageRejection)
									}
								>
									Cancel
								</Button>
								<Button
									onClick={handleSaveMessageRejection}
									type='primary'
									htmlType='submit'
									disabled={
										!!messageRejectionError || isEqual(jobData?.messageRejection, messageRejection)
									}
								>
									Save
								</Button>
							</Styled.ButtonBox>
						</Styled.FieldWrapper>
					</Styled.BoxWrapper>
					{jobData?.reference && (
						<Styled.BoxWrapper>
							<h2>Reference ID:</h2>
							<Styled.ContentItem>
								<p>{jobData.reference}</p>
							</Styled.ContentItem>
						</Styled.BoxWrapper>
					)}
					<Styled.BoxWrapper>
						<JobRoleInfo
							jobData={jobData}
							jobIndustries={jobIndustries}
							educationTypes={educationTypes}
							employmentTypes={employmentTypes}
						/>
					</Styled.BoxWrapper>
					{jobData?.applicationMethod && (
						<Styled.BoxWrapper>
							<ApplicationMethodView
								applicationMethod={jobData.applicationMethod}
								applicationInputValue={jobData.applicationEmail || jobData.applicationFormUrl}
								applicationAttachment={jobData?.applicationFormAttachment?.s3FileReference}
								jobType={jobData?.jobClass}
							/>
						</Styled.BoxWrapper>
					)}
					{!!jobAssessmentData?.length && (
						<Styled.BoxWrapper>
							<CandidateAssessmentForm data={jobAssessmentData} />
						</Styled.BoxWrapper>
					)}
				</Styled.MainInfo>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		jobData: commonDucks.commonSelectors.getJobData(state),
		jobAssessmentData: commonDucks.commonSelectors.getJobApply(state)?.assessment,
		jobTypes: commonDucks.commonSelectors.getJobTypes(state),
		educationTypes: commonDucks.commonSelectors.getEducationTypes(state),
		employmentTypes: commonDucks.commonSelectors.getEmploymentTypes(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		defaultMessages: atsDucks.atsSelectors.getSettingsDefaultMessages(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		jobIndustries: commonDucks.commonSelectors.getJobIndustries(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
	}),
	{
		getJobById: commonDucks.commonActions.getJobByIdRequested,
		getJobAssessment: commonDucks.commonActions.getJobAssessmentRequested,
		getJobTypesRequested: commonDucks.commonActions.getJobTypesRequested,
		getEducationTypesRequested: commonDucks.commonActions.getEducationTypesRequested,
		getEmploymentTypesRequested: commonDucks.commonActions.getEmploymentTypesRequested,
		updateRejectionMessagePerJob: atsDucks.atsActions.updateRejectionMessagePerJobRequested,
		getJobIndustriesRequested: commonDucks.commonActions.getJobIndustriesRequested,
	},
)(JobView);
