import { PostJobTypeIdsEnum, PostJobTypeValuesEnum } from 'modules/Common/types';

export const JobClasses = [
	{
		id: PostJobTypeIdsEnum.FreeJob,
		value: PostJobTypeIdsEnum.FreeJob,
		name: PostJobTypeValuesEnum.FreeJob,
	},
	{
		id: PostJobTypeIdsEnum.PaidJob,
		value: PostJobTypeIdsEnum.PaidJob,
		name: PostJobTypeValuesEnum.PaidJob,
	},
	{
		id: PostJobTypeIdsEnum.PendingBoost,
		value: PostJobTypeIdsEnum.PendingBoost,
		name: PostJobTypeValuesEnum.PendingBoost,
	},
];

export const JobUpgradesData = [
	{
		id: 1,
		name: 'Hand Filtered CVs',
		value: 'handFilteredCVsUpgrade',
	},
	{
		id: 2,
		name: 'The Guardian',
		value: 'guardianJobsUpgrade',
	},
	{
		id: 3,
		name: 'Indeed',
		value: 'indeedUpgrade',
	},
];

export const UpgradesFields = ['handFilteredCVsUpgrade', 'guardianJobsUpgrade', 'indeedUpgrade'];

export const getApplicationFormUrlThankYouMessage = (
	title = '[Job Title]',
	companyName = '[Company Name]',
	url = '[URL]',
) =>
	`Thank you for applying for the ${title ?? '[Job Title]'} position at ${
		companyName ?? '[Company Name]'
	}. We appreciate your interest in joining our team. In order to move forward with your application, we kindly request you to fill out the attached form ${
		url ?? '[URL]'
	} \n\nShould you have any questions, please feel free to reach out to us`;

export const getApplicationFormAttachmentThankYouMessage = (
	title = '[Job Title]',
	companyName = '[Company Name]',
) =>
	`Thank you for applying for the ${title ?? '[Job Title]'} position at ${
		companyName ?? '[Company Name]'
	}. We appreciate your interest in joining our team. In order to move forward with your application, we kindly request you to fill out the attached form, providing us with some additional information about your qualifications and experience. Please complete the form and send it back to us at [Email Address] \n\nShould you have any questions, please feel free to reach out to us`;

export const AllowedJobFields = [
	'id',
	'title',
	'jobType',
	'location',
	'salary',
	'experienceLevel',
	'educationType',
	'employmentType',
	'jobIndustry',
	'jobDescription',
	'applicationMethod',
	'cvAndCoverLetter',
	'cvAndCoverLetterEmail',
	'applicationEmail',
	'applicationForm',
	'applicationFormUrl',
	'jobUpgrades',
	'assessmentQuestions',
];
