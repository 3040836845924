import React, { FC } from 'react';
import { Field, Form } from 'react-final-form';

import { Input, Spin } from 'antd';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { SocialNetworksType } from 'modules/Common/types/socialNetworks';
import { trimFalsyFields } from 'utils/helpers';

import FormBlockGrid from '../../components/FormBlockGrid';

import { Styled } from './SocialNetworkContainer.styled';

type SocialNetworkContainerProps = {
	defaultValues?: SocialNetworksType;
	handleSave: (values: SocialNetworksType) => void;
	loading?: boolean;
};

const SocialNetworkContainer: FC<SocialNetworkContainerProps> = ({
	defaultValues,
	handleSave,
	loading,
}) => {
	const onSubmit = (values: SocialNetworksType) => {
		const preparedValues = trimFalsyFields(values);

		handleSave(preparedValues);
	};

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, dirty }) => (
					<form onSubmit={handleSubmit} autoComplete='off'>
						<Box noSpaces style={{ flex: '1 1 auto' }}>
							<h2>Social Networks</h2>
							<FormBlockGrid columns={2}>
								<Styled.FieldWrapper>
									<Styled.FieldLabel>LinkedIn URL</Styled.FieldLabel>
									<Field name='linkedIn' initialValue={defaultValues?.linkedIn}>
										{({ input }) => (
											<Input
												{...input}
												placeholder='E.g., https://www.linkedin.com/company/google/'
											/>
										)}
									</Field>
								</Styled.FieldWrapper>
								<Styled.FieldWrapper>
									<Styled.FieldLabel>Instagram URL</Styled.FieldLabel>
									<Field name='instagram' initialValue={defaultValues?.instagram}>
										{({ input }) => (
											<Input {...input} placeholder='E.g., https://www.instagram.com/apple/' />
										)}
									</Field>
								</Styled.FieldWrapper>
								<Styled.FieldWrapper>
									<Styled.FieldLabel>Facebook URL</Styled.FieldLabel>
									<Field name='facebook' initialValue={defaultValues?.facebook}>
										{({ input }) => (
											<Input {...input} placeholder='E.g., https://www.facebook.com/amazonweb/' />
										)}
									</Field>
								</Styled.FieldWrapper>
								<Styled.FieldWrapper>
									<Styled.FieldLabel>YouTube URL</Styled.FieldLabel>
									<Field name='youtube' initialValue={defaultValues?.youtube}>
										{({ input }) => (
											<Input {...input} placeholder='E.g., https://www.youtube.com/c/microsoft/' />
										)}
									</Field>
								</Styled.FieldWrapper>
								<Styled.FieldWrapper>
									<Styled.FieldLabel>Twitter</Styled.FieldLabel>
									<Field name='twitter' initialValue={defaultValues?.twitter}>
										{({ input }) => (
											<Input {...input} placeholder='E.g., https://twitter.com/IBM' />
										)}
									</Field>
								</Styled.FieldWrapper>
							</FormBlockGrid>
						</Box>

						<Styled.ButtonWrapper>
							<ThemedButton
								buttonType={ButtonTypes.primary}
								type='submit'
								disabled={!dirty || loading}
							>
								Save Changes
								{loading ? <Spin style={{ marginLeft: '12px' }} /> : ''}
							</ThemedButton>
						</Styled.ButtonWrapper>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default SocialNetworkContainer;
