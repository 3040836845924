import React, { type FC } from 'react';
import { Field, Form } from 'react-final-form';

import { Spin } from 'antd';
import { omit } from 'lodash';

import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldPassword from 'components/FieldPassword';
import FieldWrapper from 'components/FieldWrapper';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { IChangePasswordValues } from 'modules/Common/types';
import {
	composeValidators,
	passwordValidator,
	repeatPasswordValidator,
	requiredValidator,
} from 'utils/validators';

import { Styled } from './ChangePasswordContainer.styled';

type ChangePasswordContainerProps = {
	changeUserPassword: (values: IChangePasswordValues) => void;
	loading: boolean;
};

export const ChangePasswordContainer: FC<ChangePasswordContainerProps> = ({
	changeUserPassword,
	loading,
}) => {
	const onSubmit = (values: IChangePasswordValues) => {
		changeUserPassword(omit(values, ['confirmPassword']));
	};

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit, dirty, form }) => (
					<form onSubmit={handleSubmit}>
						<Box noSpaces fullWidth style={{ minHeight: '100%' }}>
							<Styled.Title>Change password</Styled.Title>

							<Styled.Fields>
								<Field name='oldPassword' validate={requiredValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='oldPassword'
											label='Old password'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
											isFixed
										>
											<FieldPassword {...input} placeholder='' />
										</FieldWrapper>
									)}
								</Field>
								<Field
									name='newPassword'
									validate={composeValidators(requiredValidator, passwordValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='newPassword'
											label='New password'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
											isFixed
										>
											<FieldPassword {...input} placeholder='' />
										</FieldWrapper>
									)}
								</Field>

								<Field
									name='confirmPassword'
									validate={composeValidators(requiredValidator, (value: string) =>
										repeatPasswordValidator(form.getState().values.newPassword, value),
									)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='confirmPassword'
											label='Confirm new password'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
											required
											isFixed
										>
											<FieldPassword {...input} placeholder='' />
										</FieldWrapper>
									)}
								</Field>
							</Styled.Fields>
						</Box>

						<Styled.ButtonWrapper>
							<ThemedButton
								buttonType={ButtonTypes.primary}
								type='submit'
								disabled={!dirty || loading}
							>
								Save
								{loading ? <Spin style={{ marginLeft: '12px' }} /> : ''}
							</ThemedButton>
						</Styled.ButtonWrapper>
					</form>
				)}
			/>
		</Styled.Root>
	);
};
