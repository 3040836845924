import React, { FC } from 'react';

import Container from 'components/Container';
import { Direction } from 'components/Container/Container.types';
import { ReactParent } from 'types';

import { Styled } from './Footer.styled';

type FooterProps = ReactParent & {
	bgColor?: string;
	styles?: object;
};

export const Footer: FC<FooterProps> = ({ bgColor = 'none', styles, children }) => {
	return (
		<Styled.Footer bgColor={bgColor}>
			<Container direction={Direction.Row} style={styles}>
				{children}
			</Container>
		</Styled.Footer>
	);
};
