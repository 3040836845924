const formatKey = (key: string): string =>
	key
		.toLowerCase()
		.replace(/_/g, ' ')
		.replace(/^\w|\s\w/g, (m) => m.toUpperCase())
		.replace('Cv ', 'CV ');

export const convertToPercentage = (data: { [key: string]: number }) => {
	const total = Object.values(data).reduce((acc, curr) => acc + curr, 0);
	const entries = Object.entries(data);
	const resultArray = [];
	let remainingPercentage = 100;

	if (total === 0) {
		entries.forEach(([key]) => {
			resultArray.push({ name: formatKey(key), value: 0 });
		});

		return resultArray;
	}

	entries.forEach(([key, value], index) => {
		if (index === entries.length - 1) {
			resultArray.push({ name: formatKey(key), value: remainingPercentage });
		} else {
			const percent = Math.round((value / total) * 100);
			remainingPercentage -= percent;
			resultArray.push({ name: formatKey(key), value: percent });
		}
	});

	return resultArray;
};
