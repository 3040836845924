import React, { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { Tabs } from 'antd';

import { ATS_DEFAULT_COLORS } from 'common/colors';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import {
	DEFAULT_ATS_JOBS_PAGE_SIZE,
	DEFAULT_CURRENT_PAGE,
	DEFAULT_PAGE_SIZE,
} from 'modules/Common/constants';
import JobsListStatistic from 'modules/Common/containers/JobsListStatistic';
import { ClientBrandingDataType, JobsDataType, TabsTitleEnum } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, GenericType, SubscriptionPlansType } from 'types';
import { sleep } from 'utils/helpers';

import { atsDucks } from '../../ducks';
import { GravityJobsListType } from '../GravityJobCandidates/GravityJobCandidates.types';
import GravityJobsTable from '../GravityJobsTable';

import { Styled } from './ATSJobsList.styled';

type ATSJobsListPageProps = {
	jobsData: JobsDataType;
	gravityJobsList: GravityJobsListType;
	getAllJobs: (params: { active: boolean; size?: number; page?: number }) => void;
	getGravityJobsRequested: ({ page, size }: { page: number; size: number }) => void;
	archiveAndUnarchiveJob: (jobId: number, active: boolean) => void;
	clientAtsBrandingData: ClientBrandingDataType;
	countries: CountriesType;
	loading: GenericType;
	subscriptionPlans: SubscriptionPlansType;
};

const ATSJobsList: FC<ATSJobsListPageProps> = ({
	jobsData,
	gravityJobsList,
	getAllJobs,
	getGravityJobsRequested,
	archiveAndUnarchiveJob,
	clientAtsBrandingData,
	countries,
	subscriptionPlans,
	loading,
}) => {
	const { state } = useLocation();
	const [tab, setTab] = useState<TabsTitleEnum | string>(
		state?.activeTab || TabsTitleEnum.ActiveTab,
	);
	const isActiveTab = tab === TabsTitleEnum.ActiveTab;
	const [tableParams, setTableParams] = useState({});

	const handleChangeActiveJob = useCallback(
		async (id: number, active: boolean) => {
			await archiveAndUnarchiveJob(id, active);
			await sleep(1000);
			await getAllJobs({ active: !active });
		},
		[tab, archiveAndUnarchiveJob],
	);

	const handleChangeTabs = (id: TabsTitleEnum | string) => {
		setTab(id);
	};

	const handlePaginationChange = useCallback((page: number, size: number) => {
		getAllJobs({ active: isActiveTab, page, size });
	}, []);

	useEffect(() => {
		getAllJobs({
			active: tab === TabsTitleEnum.ActiveTab,
			page: jobsData?.pageIndex,
			size: jobsData?.pageSize,
			...tableParams,
		});
	}, [tab, tableParams]);

	const handleSearch = (value: string) => {
		setTableParams({ search: value });
	};

	useMount(() => {
		getAllJobs({
			active: tab === TabsTitleEnum.ActiveTab,
			page: jobsData?.pageIndex,
			size: DEFAULT_ATS_JOBS_PAGE_SIZE,
		});
		!gravityJobsList?.data?.length &&
			getGravityJobsRequested({ page: DEFAULT_CURRENT_PAGE, size: DEFAULT_PAGE_SIZE });
	});

	const tabsItems = [
		{
			label: 'Active',
			key: TabsTitleEnum.ActiveTab,
			active: true,
			children: (
				<>
					<JobsListStatistic
						jobsData={jobsData}
						onChangeActiveJob={handleChangeActiveJob}
						textColour={
							subscriptionPlans.isPremiumPlan
								? clientAtsBrandingData?.textColour
								: ATS_DEFAULT_COLORS.textColour
						}
						onChangePage={handlePaginationChange}
						countries={countries}
						highlightColour={
							subscriptionPlans.isPremiumPlan
								? clientAtsBrandingData?.highlightColour
								: ATS_DEFAULT_COLORS.highlightColour
						}
						isPremium={subscriptionPlans.isPremiumPlan}
						isActive
					/>
				</>
			),
		},
		{
			label: 'Archive',
			key: TabsTitleEnum.ArchiveTab,
			children: (
				<>
					<JobsListStatistic
						jobsData={jobsData}
						onChangePage={handlePaginationChange}
						onChangeActiveJob={handleChangeActiveJob}
						countries={countries}
						isActive={false}
					/>
				</>
			),
		},
	];

	if (gravityJobsList?.data?.length) {
		tabsItems.push({
			label: 'Gravity History',
			key: TabsTitleEnum.GravityHistoryTab,
			children: (
				<>
					<GravityJobsTable />
				</>
			),
		});
	}

	return (
		<Styled.Root>
			<Styled.Search>
				<SearchFilterSortWrapper
					search={{
						onSearch: handleSearch,
						isDebounce: false,
						placeholder: 'Search a job',
						width: '200px',
					}}
				/>
			</Styled.Search>
			{loading?.getAllAtsJobsLoad ? (
				<Spinner fixed />
			) : (
				<Tabs
					defaultActiveKey={tab}
					onChange={handleChangeTabs}
					activeKey={tab}
					items={tabsItems}
					tabBarStyle={{ textTransform: 'uppercase', fontFamily: 'Inter' }}
				/>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		jobsData: atsDucks.atsSelectors.getJobsData(state),
		gravityJobsList: atsDucks.atsSelectors.getAllGravityJobsListData(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAllJobs: atsDucks.atsActions.getAllAtsJobsRequested,
		archiveAndUnarchiveJob: atsDucks.atsActions.archiveAndUnarchiveJobRequested,
		getGravityJobsRequested: atsDucks.atsActions.getGravityJobsRequested,
	},
)(ATSJobsList);
