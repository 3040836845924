import React, { type FC, useCallback, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DatePicker, Collapse } from 'antd';
import { TablePaginationConfig, SorterResult } from 'antd/lib/table/interface';

import Table from 'components/Table';
import { useMount } from 'hooks';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import ReceiptModal from 'modules/Common/components/ReceiptModal';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE, InvoiceFields } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	IInvoicesData,
	IInvoicesState,
	InvoicesStatusTypesFormEnum,
	ReceiptDataType,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, IOption, UserTypesValueEnum, Routes, CountriesType } from 'types';
import { propsFilter, getDateString } from 'utils/helpers';

import { InvoiceStatusModal } from '../InvoiceStatusModal/InvoiceStatusModal';

import { columns } from './Invoices.entities';
import { Styled } from './Invoices.styled';

const { RangePicker } = DatePicker;

type InvoicesProps = {
	clientContextId?: string;
	loading?: GenericType;
	clientId?: string;
	invoices: IInvoicesData;
	contractReceipt: ReceiptDataType;
	creditsFields: GenericType[];
	invoiceStatuses: IInvoicesState[];
	invoicePaymentMethods: IOption[];
	countries: CountriesType;
	getContractReceipt: (
		id: number,
		isInvoice?: boolean,
		platform?: UserTypesValueEnum,
		callback?: () => void,
	) => void;
	getCreditsRequested: () => void;
	getBackofficeInvoices: (params: { page: number; size: number; client?: string }) => void;
	deleteInvoiceByIdRequested: (id: number, callback: () => void) => void;
	getInvoiceStatusRequested: () => void;
	getInvoicePaymentMethodsRequested: () => void;
	updateInvoiceStatusRequested: (
		invoiceId: number,
		values: { status: InvoicesStatusTypesFormEnum; datePaid?: string; paymentMethod?: number },
		callback?: () => void,
	) => void;
};

const Invoices: FC<InvoicesProps> = ({
	clientContextId = '',
	loading,
	invoices,
	getBackofficeInvoices,
	contractReceipt,
	creditsFields,
	invoiceStatuses,
	invoicePaymentMethods,
	countries,
	getContractReceipt,
	getCreditsRequested,
	deleteInvoiceByIdRequested,
	getInvoiceStatusRequested,
	getInvoicePaymentMethodsRequested,
	updateInvoiceStatusRequested,
}) => {
	const navigate = useNavigate();
	const [openModal, setOpenModal] = useState(false);
	const [tableParams, setTableParams] = useState({});
	const [selectedInvoiceId, setSelectedInvoiceId] = useState<null | number>(null);
	const [invoiceSorterTooltipTitle, setInvoiceSorterTooltipTitle] = useState<string>(
		'Click to sort Oldest to Newest',
	);

	const handleViewItem = useCallback(
		async (id: number) => {
			id && getContractReceipt(id, true, UserTypesValueEnum.BACKOFFICE, () => setOpenModal(true));
		},
		[contractReceipt],
	);

	const handleDeleteItem = useCallback(
		async (id: number) => {
			const { pageIndex, pageSize } = invoices;
			deleteInvoiceByIdRequested(id, () =>
				getBackofficeInvoices({ page: pageIndex, size: pageSize, client: clientContextId }),
			);
		},
		[contractReceipt],
	);

	const handleEditItem = useCallback(async (id: number) => {
		const path = clientContextId
			? `${Routes.BOClientContext}/${clientContextId}${Routes.InvoiceEdit}/${id}`
			: `${Routes.BackOffice}${Routes.InvoiceEdit}/${id}`;
		navigate(path);
	}, []);

	const handleChangeStatus = useCallback(
		async (invoiceId: number, statusId: number) => {
			if (InvoicesStatusTypesFormEnum.PaidManually === statusId) {
				handleStatusModalOpen(invoiceId);
			} else {
				updateInvoiceStatusRequested(invoiceId, { status: statusId }, () =>
					setTableParams({ ...tableParams, page: 1 }),
				);
			}
		},
		[tableParams],
	);

	const refreshInvoicesResults = useCallback(async () => {
		const { pageIndex, pageSize } = invoices;
		getBackofficeInvoices({ page: pageIndex, size: pageSize, client: clientContextId });
	}, [invoices]);

	const handleStatusModalOpen = useCallback((invoiceId: number) => {
		setSelectedInvoiceId(invoiceId);
	}, []);

	const columnsData = columns(
		invoiceStatuses,
		clientContextId,
		invoiceSorterTooltipTitle,
		handleViewItem,
		handleDeleteItem,
		handleEditItem,
		handleChangeStatus,
	);
	const filteredInvoicesData = invoices?.data?.length && propsFilter(invoices?.data, InvoiceFields);

	const handleCancel = () => setOpenModal(false);

	const handleStatusModalCancel = () => setSelectedInvoiceId(null);

	useMount(() => {
		getBackofficeInvoices({
			page: DEFAULT_CURRENT_PAGE,
			size: DEFAULT_PAGE_SIZE,
			client: clientContextId,
		});
		getCreditsRequested();
		invoiceStatuses?.length || getInvoiceStatusRequested();
		invoicePaymentMethods?.length || getInvoicePaymentMethodsRequested();
	});

	useEffect(() => {
		getBackofficeInvoices({
			page: DEFAULT_CURRENT_PAGE,
			size: DEFAULT_PAGE_SIZE,
			client: clientContextId,
		});
	}, [clientContextId]);

	const updateInvoiceSorterTooltipTitle = useCallback(
		(sortColumn: string | undefined, sortOrder: string | undefined) => {
			if (sortColumn !== 'btoReference') return;
			if (sortOrder === 'ascend')
				return setInvoiceSorterTooltipTitle('Click to sort Newest to Oldest');
			if (sortOrder === 'descend') return setInvoiceSorterTooltipTitle('Click to cancel sorting');

			return setInvoiceSorterTooltipTitle('Click to sort Oldest to Newest');
		},
		[],
	);

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		const fieldsMap = {
			btoReference: 'id',
			startDate: 'dateDue',
			status: 'status',
			clientName: 'client',
		};

		getBackofficeInvoices({
			page: tableParams?.page || 0,
			size: tableParams?.size || DEFAULT_PAGE_SIZE,
			...(clientContextId && { client: clientContextId }),
			...(tableParams?.search && { search: tableParams?.search }),
			...(tableParams?.statusFilter && {
				statusFilter: tableParams?.statusFilter,
			}),
			...(tableParams?.dateDue &&
				tableParams?.dateDue[0] &&
				tableParams?.dateDue[1] && {
				dateDueStart: getDateString(tableParams?.dateDue[0]),
				dateDueEnd: getDateString(tableParams?.dateDue[1]),
			}),
			...(tableParams?.dateCreation &&
				tableParams?.dateCreation[0] &&
				tableParams?.dateCreation[1] && {
				dateCreationStart: getDateString(tableParams?.dateCreation[0]),
				dateCreationEnd: getDateString(tableParams?.dateCreation[1]),
			}),
			...(tableParams?.sorter?.order &&
				tableParams?.sorter?.field && {
				sort: `${fieldsMap[tableParams?.sorter?.field]},${
					ordersMap[tableParams?.sorter?.order || 'ascend']
				}`,
			}),
		});

		updateInvoiceSorterTooltipTitle(tableParams?.sorter?.field, tableParams?.sorter?.order);
	}, [tableParams, clientContextId]);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			page: 1,
			sorter,
		});
	};

	const handleSearch = (value: string) => {
		setTableParams({ ...tableParams, page: 1, search: value });
	};

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			setTableParams({
				...tableParams,
				page,
				size,
			});
		},
		[tableParams],
	);

	const handleTablePageSizeChange = useCallback(
		(page: number, size: number) => {
			size !== invoices?.pageSize && setTableParams({ ...tableParams, page: 1, size });
		},
		[invoices?.pageSize],
	);

	const handleFilter = (value) => {
		setTableParams({ ...tableParams, page: 1, statusFilter: value });
	};

	const onDueDateFilter = (value) => {
		setTableParams({ ...tableParams, page: 1, dateDue: value });
	};

	const onCreationDateFilter = (value) => {
		setTableParams({ ...tableParams, page: 1, dateCreation: value });
	};

	const onChangeAdvancedSearch = () => {
		setTableParams({ ...tableParams, dateDue: null, dateCreation: null });
	};

	const advancedSearchItems = [
		{
			key: '1',
			label: 'Advanced Search',
			children: (
				<Styled.AdvancedFilterWrapper>
					<Styled.Filter>
						<Styled.FilterLabel>Due Date</Styled.FilterLabel>
						<RangePicker
							onChange={onDueDateFilter}
							allowClear
							style={{ width: '100%' }}
							placeholder={['Start Date', 'End Date']}
						/>
					</Styled.Filter>
					<Styled.Filter>
						<Styled.FilterLabel>Creation Date</Styled.FilterLabel>
						<RangePicker
							onChange={onCreationDateFilter}
							allowClear
							style={{ width: '100%' }}
							placeholder={['Start Date', 'End Date']}
						/>
					</Styled.Filter>
				</Styled.AdvancedFilterWrapper>
			),
		},
	];

	return (
		<Styled.Root>
			<Styled.BoxWrap isBoxComponent={!clientContextId}>
				<SearchFilterSortWrapper
					search={{
						onSearch: handleSearch,
						isDebounce: false,
						placeholder: clientContextId ? 'Search invoice' : 'Search a client or invoice',
					}}
					filter={{
						mode: 'single',
						options: [
							{
								label: 'Paid',
								value: 'Paid',
							},
							{
								label: 'Overdue',
								value: 'Overdue',
							},
							{
								label: 'Outstanding',
								value: 'Outstanding',
							},
							{
								label: 'Paid Manually',
								value: 'PaidManually',
							},
						],
						onFilter: handleFilter,
						label: 'Status',
						placeholder: 'Filter',
					}}
				/>
				<Collapse onChange={onChangeAdvancedSearch} ghost items={advancedSearchItems} />
				<br />
				<Table
					data={filteredInvoicesData}
					columns={columnsData}
					loading={loading?.getBackofficeInvoicesLoad}
					pageSize={invoices.pageSize}
					current={invoices.pageIndex}
					total={invoices.totalCount}
					onTableChange={handleTableChange}
					onChange={handleTablePaginationChange}
					onPageSizeChange={handleTablePageSizeChange}
				/>
			</Styled.BoxWrap>
			<ReceiptModal
				open={openModal}
				onCancel={handleCancel}
				content={contractReceipt}
				creditsFields={creditsFields}
				countries={countries}
				rootClassName='receipt-modal'
			/>
			<InvoiceStatusModal
				invoiceId={selectedInvoiceId}
				onCancel={handleStatusModalCancel}
				invoicePaymentMethods={invoicePaymentMethods}
				updateInvoiceStatusRequested={updateInvoiceStatusRequested}
				updateInvoiceCallback={refreshInvoicesResults}
				loading={!!loading?.updateInvoiceStatusLoad}
				rootClassName='invoice-status-modal'
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		invoices: backOfficeDucks.backOfficeSelectors.getInvoicesState(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		contractReceipt: commonDucks.commonSelectors.getContractReceipt(state),
		invoiceStatuses: backOfficeDucks.backOfficeSelectors.getInvoiceStatusesForBO(state),
		invoicePaymentMethods: backOfficeDucks.backOfficeSelectors.getInvoicePaymentMethodsForBO(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
	}),
	{
		getBackofficeInvoices: backOfficeDucks.backOfficeActions.getBackofficeInvoicesRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		getContractReceipt: commonDucks.commonActions.getContractReceiptRequested,
		deleteInvoiceByIdRequested: backOfficeDucks.backOfficeActions.deleteInvoiceByIdRequested,
		getInvoiceStatusRequested: backOfficeDucks.backOfficeActions.getInvoiceStatusesRequested,
		getInvoicePaymentMethodsRequested:
			backOfficeDucks.backOfficeActions.getInvoicePaymentMethodsRequested,
		updateInvoiceStatusRequested: backOfficeDucks.backOfficeActions.updateInvoiceStatusRequested,
	},
)(Invoices);
