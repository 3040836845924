import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { IUser } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { Routes, UserRolesType } from 'types';

import { SuperUserMenuList, SupportMenuList } from './Header.constants';
import { S } from './Header.styled';
import MainMenu from './MainMenu';
import UserMenu from './UserMenu';

type HeaderProps = {
	user: IUser;
	roles: UserRolesType;
	logout: (p: () => void) => void;
};

const Header: React.FC<HeaderProps> = ({ user, roles, logout }) => {
	const navigate = useNavigate();
	const menuItems = useMemo(() => (roles?.isServiceUser ? SupportMenuList : SuperUserMenuList), []);
	const currentUser = user?.firstName || user?.lastName ? `${user.firstName} ${user.lastName}` : '';

	const UserMenuList = useMemo(
		() => [
			{
				id: 0,
				name: 'Settings',
				handleClick: () => {
					navigate(`${Routes.BackOffice}${Routes.Settings}`);
				},
			},
			{
				id: 1,
				name: 'Logout',
				handleClick: () => {
					logout(() => navigate(Routes.Login));
				},
			},
		],
		[logout, navigate],
	);

	return (
		<S.Header>
			<S.Container>
				<MainMenu items={menuItems} />
				<UserMenu items={UserMenuList} user={currentUser} />
			</S.Container>
		</S.Header>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state)?.id,
	}),
	{
		logout: unregisteredDucks.unregisteredActions.logOut,
	},
)(Header);
