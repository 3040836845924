import React, { useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { Button } from 'antd';

import { MenuList } from 'modules/Common/types';
import { Routes } from 'types';

import { ClntContext } from '../../../containers/ClientContextContainer/ClientContextContainer';
import { JobsListContainer } from '../../../containers/JobsListContainer/JobsListContainer';
import { JobsListContainerProps } from '../../../containers/JobsListContainer/JobsListContainer.types';

import { Styled } from './ClientContextJobsPage.styled';

export const ClientContextJobsPage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { clientId } = useParams();

	const clientStore = useContext(ClntContext)[clientId];

	const state: Partial<JobsListContainerProps> = {
		loading: useSelector(clientStore?.backOfficeSelectors?.backOfficeLoading),
		jobsData: useSelector(clientStore?.backOfficeSelectors?.getAllBackOfficeJobsData),
	};

	const actions: Partial<JobsListContainerProps> = {
		getAllBackOfficeJobs: (params) =>
			dispatch(
				clientStore?.backOfficeActions?.getAllBackOfficeJobsRequested(
					{ ...params, client: clientId },
					true,
				),
			),
	};

	return (
		<>
			<Styled.Head>
				<Styled.Title>{MenuList.Vacancies}</Styled.Title>
				<Styled.ButtonsBox>
					<Button
						type='primary'
						htmlType='button'
						onClick={() => navigate(`${Routes.BOClientContext}/${clientId}${Routes.CreateJob}`)}
					>
						Create New
					</Button>
				</Styled.ButtonsBox>
			</Styled.Head>
			<JobsListContainer
				clientId={clientId}
				isClientContext
				pageLink={`${Routes.BOClientContext}/${clientId}`}
				{...state}
				{...actions}
			/>
		</>
	);
};

export default ClientContextJobsPage;
