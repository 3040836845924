import { connect } from 'react-redux';

import { backOfficeDucks } from '../../ducks';

import { JobsListContainer } from './JobsListContainer';

export default connect(
	(state) => ({
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		jobsData: backOfficeDucks.backOfficeSelectors.getAllBackOfficeJobsData(state),
	}),
	{
		getAllBackOfficeJobs: backOfficeDucks.backOfficeActions.getAllBackOfficeJobsRequested,
	},
)(JobsListContainer);
