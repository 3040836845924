import { combineReducers } from 'redux';

import { takeLatest } from 'redux-saga/effects';

import createLoading from 'modules/Common/ducks/loading';

import { createActions } from './actions';
import { createActionsTypes } from './constants';
import { BackOfficeReducer } from './reducer';
import { createSagas } from './sagas';
import { createSelectors } from './selectors';

export function getBackofficeDucks(props) {
	const { prefix, root } = props;
	const PREFIX = prefix;

	const TYPES = createActionsTypes(PREFIX);
	const ACTIONS = createActions(TYPES);
	const SELECTORS = createSelectors(root);

	const SAGAS = createSagas(TYPES, PREFIX, SELECTORS, ACTIONS, props);
	const backOfficeReducer = BackOfficeReducer(TYPES);

	const { reducer: loadingReducer } = createLoading({
		GET_CLIENTS: TYPES.GET_CLIENTS,
		GET_BO_CLIENTS: TYPES.GET_BO_CLIENTS,
		GET_CONTEXT_CLIENT: TYPES.GET_CONTEXT_CLIENT,
		GET_BO_CONTRACTS_BY_CLIENT_ID: TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID,
		GET_CLIENT_CONTEXT_CLIENTS_LIST: TYPES.GET_CLIENT_CONTEXT_CLIENTS_LIST,
		GET_TICKETS: TYPES.GET_TICKETS,
		GET_CREDITS_DEFAULT_PRICES: TYPES.GET_CREDITS_DEFAULT_PRICES,
		GET_CLIENT_SUBS_INFO: TYPES.GET_CLIENT_SUBS_INFO,
		UPDATE_CREDITS_DEFAULT_PRICES: TYPES.UPDATE_CREDITS_DEFAULT_PRICES,
		GET_SUBS_DEFAULT_PRICES: TYPES.GET_SUBS_DEFAULT_PRICES,
		UPDATE_SUBS_DEFAULT_PRICES: TYPES.UPDATE_SUBS_DEFAULT_PRICES,
		GET_TICKET_DETAILS: TYPES.GET_TICKET_DETAILS,
		CREATE_AND_UPDATE_CLIENT: TYPES.CREATE_AND_UPDATE_CLIENT,
		GET_CURRENT_CLIENT: TYPES.GET_CURRENT_CLIENT,
		CREATE_CONTRACT: TYPES.CREATE_CONTRACT,
		CREATE_INVOICE: TYPES.CREATE_INVOICE,
		UPDATE_INVOICE: TYPES.UPDATE_INVOICE,
		UPDATE_CONTRACT: TYPES.UPDATE_CONTRACT,
		GET_CLIENTS_BY_REGION: TYPES.GET_CLIENTS_BY_REGION,
		GET_CLIENT_REGIONS: TYPES.GET_CLIENT_REGIONS,
		GET_CLIENTS_SUBS: TYPES.GET_CLIENTS_SUBS,
		GET_BACKOFFICE_INVOICES: TYPES.GET_BACKOFFICE_INVOICES,
		DELETE_INVOICE_BY_ID: TYPES.DELETE_INVOICE_BY_ID,
		GET_INVOICE_BY_ID: TYPES.GET_INVOICE_BY_ID,
		GET_ALL_BACKOFFICE_JOBS: TYPES.GET_ALL_BACKOFFICE_JOBS,
		GET_BACKOFFICE_CANDIDATES: TYPES.GET_BACKOFFICE_CANDIDATES,
		GET_BACKOFFICE_JOB_BY_ID: TYPES.GET_BACKOFFICE_JOB_BY_ID,
		GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID: TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID,
		CREATE_BACKOFFICE_TICKET: TYPES.CREATE_BACKOFFICE_TICKET,
		UPDATE_BACKOFFICE_TICKET: TYPES.UPDATE_BACKOFFICE_TICKET,
		UPDATE_BACKOFFICE_TICKET_STATUS: TYPES.UPDATE_BACKOFFICE_TICKET_STATUS,
		GET_BACKOFFICE_CONTRACT_BY_ID: TYPES.GET_BACKOFFICE_CONTRACT_BY_ID,
		GET_INVOICE_STATUSES: TYPES.GET_INVOICE_STATUSES,
		GET_INVOICE_PAYMENT_METHODS: TYPES.GET_INVOICE_PAYMENT_METHODS,
		UPDATE_INVOICE_STATUS: TYPES.UPDATE_INVOICE_STATUS,
		CHANGE_USER_PASSWORD: TYPES.CHANGE_USER_PASSWORD,
		GET_BO_USER_BY_ID: TYPES.GET_BO_USER_BY_ID,
		CREATE_BO_USER: TYPES.CREATE_BO_USER,
		UPDATE_BO_USER: TYPES.UPDATE_BO_USER,
		GET_BO_USERS: TYPES.GET_BO_USERS,
		GET_BO_USER_ROLES: TYPES.GET_BO_USER_ROLES,
		GET_BO_USER_MANAGERS: TYPES.GET_BO_USER_MANAGERS,
		ACTIVATE_BO_USER: TYPES.ACTIVATE_BO_USER,
		GET_CLIENT_CONTEXT_INTERVIEW_LIST: TYPES.GET_CLIENT_CONTEXT_INTERVIEW_LIST,
		GET_CANDIDATE_STATISTICS_BO: TYPES.GET_CANDIDATE_STATISTICS_BO,
		GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS: TYPES.GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS,
		GET_JOBS_MONTH_STATISTIC_BO: TYPES.GET_JOBS_MONTH_STATISTIC_BO,
		GET_CLIENTS_SHORT: TYPES.GET_CLIENTS_SHORT,
		GET_CLIENT_BY_ID_SHORT: TYPES.GET_CLIENT_BY_ID_SHORT,
	});

	function* backOfficeSaga() {
		yield takeLatest(TYPES.GET_CLIENTS.REQUESTED, SAGAS.getClients);
		yield takeLatest(TYPES.GET_BO_CLIENTS.REQUESTED, SAGAS.getBackofficeClientsSaga);
		yield takeLatest(TYPES.GET_CONTEXT_CLIENT.REQUESTED, SAGAS.getContextClientByIdSaga);
		yield takeLatest(
			TYPES.GET_CLIENT_CONTEXT_CLIENTS_LIST.REQUESTED,
			SAGAS.getClientContextClientsListSaga,
		);
		yield takeLatest(TYPES.GET_CLIENTS_SUBS.REQUESTED, SAGAS.getClientsSubs);
		yield takeLatest(TYPES.GET_INVOICE_BY_ID.REQUESTED, SAGAS.getCurrentInvoiceSaga);
		yield takeLatest(TYPES.GET_BACKOFFICE_INVOICES.REQUESTED, SAGAS.getBackofficeInvoicesSaga);
		yield takeLatest(
			TYPES.UPDATE_CREDITS_DEFAULT_PRICES.REQUESTED,
			SAGAS.updateCreditsDefaultPricesSaga,
		);
		yield takeLatest(TYPES.GET_CREDITS_DEFAULT_PRICES.REQUESTED, SAGAS.getCreditsDefaultPricesSaga);
		yield takeLatest(TYPES.GET_SUBS_DEFAULT_PRICES.REQUESTED, SAGAS.getSubsDefaultPricesSaga);
		yield takeLatest(TYPES.UPDATE_SUBS_DEFAULT_PRICES.REQUESTED, SAGAS.updateSubsDefaultPricesSaga);
		yield takeLatest(TYPES.GET_TICKETS.REQUESTED, SAGAS.getBackofficeTicketsSaga);
		yield takeLatest(TYPES.GET_TICKET_DETAILS.REQUESTED, SAGAS.getBackofficeTicketDetailsSaga);
		yield takeLatest(TYPES.GET_CLIENT_SUBS_INFO.REQUESTED, SAGAS.getClientSubsInfoSaga);
		yield takeLatest(TYPES.GET_CLIENT_REGIONS.REQUESTED, SAGAS.getClientsRegions);
		yield takeLatest(
			TYPES.GET_BO_CONTRACTS_BY_CLIENT_ID.REQUESTED,
			SAGAS.getBoContractsByClientIdSaga,
		);
		yield takeLatest(TYPES.GET_CLIENTS_BY_REGION.REQUESTED, SAGAS.getClientsByRegionSaga);
		yield takeLatest(
			TYPES.GET_CURRENT_CLIENT.REQUESTED,
			SAGAS.getCurrentClientAndSaveChangesFormSaga,
		);
		yield takeLatest(
			TYPES.CREATE_AND_UPDATE_CLIENT.REQUESTED,
			SAGAS.createAndUpdateClientChangesFormSaga,
		);
		yield takeLatest(TYPES.CREATE_CONTRACT.REQUESTED, SAGAS.createContractSaga);
		yield takeLatest(TYPES.CREATE_INVOICE.REQUESTED, SAGAS.createInvoiceSaga);
		yield takeLatest(TYPES.UPDATE_INVOICE.REQUESTED, SAGAS.updateInvoiceSaga);
		yield takeLatest(TYPES.UPDATE_CONTRACT.REQUESTED, SAGAS.updateContractSaga);
		yield takeLatest(TYPES.GET_ALL_BACKOFFICE_JOBS.REQUESTED, SAGAS.getAllBackOfficeJobsSaga);
		yield takeLatest(TYPES.GET_BACKOFFICE_CANDIDATES.REQUESTED, SAGAS.getBackOfficeCandidatesSaga);
		yield takeLatest(TYPES.GET_BACKOFFICE_JOB_BY_ID.REQUESTED, SAGAS.getBackOfficeJobByIdSaga);
		yield takeLatest(
			TYPES.GET_BO_APPLICATION_MESSAGES_BY_CLIENT_ID.REQUESTED,
			SAGAS.getBackOfficeApplicationMessageByClientIdSaga,
		);
		yield takeLatest(TYPES.CREATE_BACKOFFICE_TICKET.REQUESTED, SAGAS.createBackOfficeTicketSaga);
		yield takeLatest(TYPES.UPDATE_BACKOFFICE_TICKET.REQUESTED, SAGAS.updateBackOfficeTicketSaga);
		yield takeLatest(
			TYPES.UPDATE_BACKOFFICE_TICKET_STATUS.REQUESTED,
			SAGAS.updateBackOfficeTicketStatusSaga,
		);
		yield takeLatest(TYPES.DELETE_INVOICE_BY_ID.REQUESTED, SAGAS.deleteInvoiceByIdSaga);
		yield takeLatest(TYPES.GET_INVOICE_STATUSES.REQUESTED, SAGAS.getInvoiceStatusesSaga);
		yield takeLatest(
			TYPES.GET_INVOICE_PAYMENT_METHODS.REQUESTED,
			SAGAS.getInvoicePaymentMethodsSaga,
		);
		yield takeLatest(
			TYPES.GET_BACKOFFICE_CONTRACT_BY_ID.REQUESTED,
			SAGAS.getBackOfficeContractByIdSaga,
		);
		yield takeLatest(TYPES.UPDATE_INVOICE_STATUS.REQUESTED, SAGAS.updateInvoiceStatusSaga);
		yield takeLatest(TYPES.CHANGE_USER_PASSWORD.REQUESTED, SAGAS.changeUserPasswordSaga);
		yield takeLatest(TYPES.CREATE_BO_USER.REQUESTED, SAGAS.createBackOfficeUserSaga);
		yield takeLatest(TYPES.UPDATE_BO_USER.REQUESTED, SAGAS.updateBackOfficeUserByIdSaga);
		yield takeLatest(TYPES.GET_BO_USERS.REQUESTED, SAGAS.getBackOfficeUsersSaga);
		yield takeLatest(TYPES.GET_BO_USER_BY_ID.REQUESTED, SAGAS.getBackOfficeUserByIdSaga);
		yield takeLatest(TYPES.GET_BO_USER_ROLES.REQUESTED, SAGAS.getBackOfficeUserRolesSaga);
		yield takeLatest(TYPES.GET_BO_USER_MANAGERS.REQUESTED, SAGAS.getBackOfficeUserManagersSaga);
		yield takeLatest(TYPES.ACTIVATE_BO_USER.REQUESTED, SAGAS.deActivateBackOfficeUserSaga);
		yield takeLatest(
			TYPES.GET_CLIENT_CONTEXT_INTERVIEW_LIST.REQUESTED,
			SAGAS.getClientContextInterviewsListSaga,
		);
		yield takeLatest(
			TYPES.GET_CANDIDATE_STATISTICS_BO.REQUESTED,
			SAGAS.getCandidateStatisticsBOSaga,
		);
		yield takeLatest(
			TYPES.GET_CLIENT_CONTEXT_DASHBOARD_UNREAD_EMAILS.REQUESTED,
			SAGAS.getClientContextDashboardUnreadEmailsSaga,
		);
		yield takeLatest(
			TYPES.GET_JOBS_MONTH_STATISTIC_BO.REQUESTED,
			SAGAS.getBOApplicationMonthStatisticSaga,
		);
		yield takeLatest(
			TYPES.GET_CLIENTS_SHORT.REQUESTED,
			SAGAS.getClientsShortSaga,
		);
		yield takeLatest(
			TYPES.GET_CLIENT_BY_ID_SHORT.REQUESTED,
			SAGAS.getClientByIdShortSaga,
		);
	}

	const reducer = combineReducers({ data: backOfficeReducer, loadingReducer });

	return {
		reducer,
		backOfficeSaga,
		backOfficeActions: { ...ACTIONS },
		backOfficeSelectors: SELECTORS,
		backOfficeActionTypes: TYPES,
		backOfficesSagas: SAGAS,
	};
}

const backOfficeDucks = getBackofficeDucks({ prefix: 'backoffice', root: 'backofficeReducer' });

export default backOfficeDucks;
