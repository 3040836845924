import dayjs from 'dayjs';

import { IInvoicesDetailedValues, InvoicesTypesValuesEnum } from 'modules/Common/types';
import { GenericType } from 'types';

export const getSortedUnitForMonths = (
	invoice: IInvoicesDetailedValues,
	renderWithMarkup = true,
): GenericType[] => {
	const sortedUnitForMonths = invoice.unitForMonths?.sort((a, b) =>
		dayjs(a.yearMonth).isAfter(dayjs(b.yearMonth)) ? 1 : -1,
	);
	const orderTypeIsSms = invoice.invoiceType.value === InvoicesTypesValuesEnum.SmsPurchase;

	return sortedUnitForMonths?.map((unit, index) => {
		const month = dayjs(unit.yearMonth, 'YYYY-MM').format('MMMM-YYYY');
		const renderedMonth = renderWithMarkup ? `<b>${month}</b>` : month;
		const name = orderTypeIsSms ? `SMS: ${renderedMonth}` : `Video Interviews: ${renderedMonth}`;
		const unitPrice = orderTypeIsSms ? invoice.smsPrice : invoice.interviewPrice;
		const totalPrice =
			unit.count *
			(orderTypeIsSms ? (invoice.smsPrice as number) : (invoice.interviewPrice as number));

		return {
			key: index + invoice.invoiceType.value,
			name,
			amount: unit.count,
			unitPrice: unitPrice as number,
			totalPrice,
		};
	});
};
