import React, { memo, useMemo } from 'react';

import TextArea from 'antd/es/input/TextArea';

import { ButtonTypes } from 'components/Button/Button.types';
import ArrowUpArrowDown from 'components/SVG/ArrowUpArrowDown';
import { ICandidateNotes, IUser } from 'modules/Common/types';
import { assignColorsToIds, getFirstLettersUppercase } from 'modules/Common/utils/commonHelpers';

import ThemedButton from '../ThemedButton';

import { Styled } from './Notes.styled';

interface NotesProps {
	notes: ICandidateNotes[];
	user: IUser;
	noteLoading: boolean;
	handleAddNoteRequest: (noteText: string, cb: () => void) => void;
}

const Notes: React.FC<NotesProps> = ({ notes, user, noteLoading, handleAddNoteRequest }) => {
	const [noteText, setNoteText] = React.useState('');
	const [defaultSort, setDefaultSort] = React.useState(true);

	const sortedNotes = useMemo(
		() => (defaultSort ? notes : [...notes].reverse()),
		[notes, defaultSort],
	);

	const handleAddNote = () => {
		if (noteText.trim().length > 0) {
			handleAddNoteRequest(noteText, () => setNoteText(''));
		}
	};

	const handleSort = () => {
		setDefaultSort(!defaultSort);
	};

	const senderColors = useMemo(
		() => assignColorsToIds(notes, ['sender', 'id'], user.userId),
		[notes, user.userId],
	);

	return (
		<Styled.Root>
			<Styled.NotesHeader>
				<h3>Notes</h3>
				<Styled.Button
					onClick={handleSort}
					icon={<ArrowUpArrowDown width='16' height='16' />}
					size='large'
				>
					{defaultSort ? 'First Recent' : 'First Oldest'}
				</Styled.Button>
			</Styled.NotesHeader>

			{sortedNotes?.map((note) => (
				<Styled.NoteItem key={note.id}>
					<Styled.NoteInfo>
						<Styled.UserIcon bgColor={senderColors[note.sender.id]}>
							{getFirstLettersUppercase(note.sender.fullName)}
						</Styled.UserIcon>
						<Styled.NoteName>{note.sender.fullName}</Styled.NoteName>
						<Styled.NoteDate>{note.created}</Styled.NoteDate>
					</Styled.NoteInfo>
					<Styled.NoteText>{note.text}</Styled.NoteText>
				</Styled.NoteItem>
			))}
			<Styled.NoteForm>
				<Styled.UserIcon bgColor={senderColors[user.userId]}>
					{getFirstLettersUppercase(user.fullName || 'user')}
				</Styled.UserIcon>
				<TextArea
					placeholder={'Enter your note'}
					value={noteText}
					autoSize={{ minRows: 4 }}
					onChange={(e) => setNoteText(e.target.value)}
					readOnly={noteLoading}
				/>
			</Styled.NoteForm>
			<Styled.ButtonWrapper>
				<ThemedButton
					buttonType={ButtonTypes.primary}
					type='button'
					onClick={handleAddNote}
					disabled={noteLoading}
				>
					Add Note
				</ThemedButton>
			</Styled.ButtonWrapper>
		</Styled.Root>
	);
};

export default memo(Notes);
