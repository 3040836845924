import { createAsyncConstantsMap, createSyncConstantsMap } from 'utils/reduxHelpers';

export const createActionsTypes = (PREFIX) => {
	const asyncActions = createAsyncConstantsMap(PREFIX, [
		'ASSIGN_CANDIDATE_TO_VACANCY',
		'GET_UNAPPLIED_CANDIDATE_JOBS',
		'ARCHIVE_AND_UNARCHIVE_JOB',
		'DELETE_ASSESSMENT_FORM',
		'GET_ATS_BRANDING_BY_ID',
		'GET_ATS_INVOICES',
		'FORWARD_INVOICE',
		'GET_CLIENT_SUBSCRIPTION',
		'GET_CLIENT_SOCIAL_NETWORKS',
		'UPDATE_CLIENT_SOCIAL_NETWORKS',
		'GET_CLIENT_EMAIL_SIGNATURE',
		'UPDATE_CLIENT_EMAIL_SIGNATURE',
		'GET_CLIENT_AUTO_POP',
		'UPDATE_CLIENT_AUTO_POP',
		'GET_CLIENT_2FA',
		'UPDATE_CLIENT_2FA',
		'GET_CLIENT_SMS_NOTIFICATIONS',
		'UPDATE_CLIENT_SMS_NOTIFICATIONS',
		'GET_USER_EMAIL_NOTIFICATIONS',
		'UPDATE_USER_EMAIL_NOTIFICATIONS',
		'CHANGE_CLIENT_USER_PASSWORD',
		'GET_ATS_USER_PERSONAL_DATA',
		'UPDATE_ATS_USER_PERSONAL_DATA',
		'GET_SUBSCRIPTION_TYPES',
		'GET_ATS_TICKETS',
		'GET_ATS_TICKET_DETAILS',
		'GET_ATS_USERS',
		'GET_ORDER_BY_ID',
		'GET_ATS_USER_ROLES',
		'GET_ATS_USER_BY_ID',
		'CREATE_ATS_USER',
		'DELETE_ATS_USER',
		'UPDATE_ATS_USER',
		'GET_JOB_UPGRAGED',
		'GET_JOBS_MONTH_STATISTIC',
		'GET_CLIENT_CREDITS',
		'GET_ATS_CAREEER_BRANDING_BY_ID',
		'GET_ATS_INVOICE_BY_ID',
		'GET_CLIENT_APPLICATION_MESSAGES_BY_ID',
		'GET_CLIENT_APPLICATION_NOTIFICATION',
		'GET_AND_SAVE_POST_JOB_CHANGES_FORM',
		'GET_ALL_ATS_JOBS',
		'GET_ATS_ACTIVE_JOBS',
		'GET_CANDIDATE_APPLICATION_BY_JOB_ID',
		'GET_CANDIDATE_ASSESSMENT_APPLICATION',
		'UPDATE_REJECTION_MESSAGES',
		'UPDATE_ATS_BRANDING',
		'UPDATE_ATS_CAREER_BRANDING',
		'UPDATE_CLIENT_APPLICATION_MESSAGES',
		'GET_ATS_INTERVIEW_TYPE',
		'GET_ATS_USER_INTERVIEW',
		'GET_ATS_INTERVIEW_BY_ID',
		'CANCEL_ATS_INTERVIEW_BY_ID',
		'SAVE_ATS_INTERVIEW',
		'UPDATE_ATS_INTERVIEW',
		'ORDER_CREDITS',
		'GET_ATS_INTERVIEW_JOBS_LIST',
		'GET_ATS_EDIT_INTERVIEW_JOBS_LIST',
		'GET_ATS_INTERVIEW_CALENDAR_LIST',
		'RESEND_ATS_INTERVIEW_LINK',
		'CREATE_ORDER_INVOICE',
		'CREATE_ATS_TICKET',
		'GET_CANDIDATE_NOTES_BY_ID',
		'ADD_NOTE_TO_CANDIDATE',
		'GET_CANDIDATES_DATABASE',
		'GET_CANDIDATE_DATABASE_BY_ID',
		'GET_CANDIDATE_SMS_INFORMATION',
		'UPDATE_CANDIDATE_PHONE_NUMBER',
		'SEND_SMS_TO_CANDIDATE',
		'GET_ATS_VIDEO_INTERVIEW_COUNT',
		'ORDER_SMS',
		'ORDER_INTERVIEWS',
		'SHOW_HIDE_CANDIDATE_DATABASE',
		'ASSIGN_CANDIDATE_TO_VACANCY_DATABASE',
		'GET_CANDIDATE_STATISTICS',
		'GET_APPLICATIONS_STATISTICS_FOR_LAST_MONTH',
		'GET_CANDIDATE_APPLICATIONS_PER_STATE_STATISTICS',
		'GET_ACCEPTED_OFFERS_STATISTICS',
		'GET_DASHBOARD_UNREAD_EMAILS',
		'GET_APPLICATION_SOURCES_STATISTICS',
		'GET_TIME_TO_HIRE_MONTH',
		'GET_TIME_TO_HIRE_YEAR',
		'GET_APPLICATION_YEAR_STATISTICS',
		'GET_APPLICATION_MONTH_STATISTICS',
		'GET_ATS_ALL_JOBS_LIST',
		'GET_ATS_ALL_CANDIDATE_APPLICATION_BY_JOB_ID',
		'CREATE_ATS_EMAIL_THREAD',
		'GET_ATS_EMAIL_TEMPLATES',
		'GET_ATS_EMAIL_INBOX',
		'GET_ATS_EMAIL_THREAD_BY_ID',
		'POST_ATS_TO_EXISTING_EMAIL_THREAD',
		'GET_GRAVITY_JOBS',
		'GET_LIST_OF_GRAVITY_CANDIDATES_BY_JOB_ID',
		'ASSIGN_GRAVITY_CANDIDATE_TO_VACANCY',
	]);

	const syncActions = createSyncConstantsMap(PREFIX, [
		'RESET_POST_JOB_CHANGES_FORM',
		'SAVE_ATS_BRANDING',
		'RESET_ATS_TICKET_DETAILS',
		'SAVE_CAREER_BRANDING',
		'SETTINGS_SAVE_COMPANY_DETAILS',
		'UPDATE_CANDIDATE_RANK',
		'RESET_CANDIDATES_SMS_INFORMATION',
		'RESET_INTERVIEW_COUNT',
		'RESET_ATS_INTERVIEW_CALENDAR_LIST',
		'RESET_ATS_CURRENT_EMAIL_THREAD',
		'RESET_ATS_CURRENT_ORDER',
		'UPDATE_ATS_EMAIL_UNREAD_THREAD_COUNT',
		'RESET_ACTIVE_JOBS_LIST',
		'RESET_CANDIDATES_DATABASE',
		'RESET_INTERVIEW_JOBS_LIST',
	]);

	return {
		...asyncActions,
		...syncActions,
	};
};
