import React, { useState, useRef, FC } from 'react';
import ReactCrop, { centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { useDebounce } from 'react-use';

import Button, { ButtonTypes } from 'components/Button';
import { FileUploadTypesEnum } from 'modules/Common/types';

import { canvasPreview } from './canvasPreview';

import 'react-image-crop/dist/ReactCrop.css';

function centerAspectCrop(mediaWidth: number, mediaHeight: number, aspect: number) {
	return centerCrop(
		makeAspectCrop(
			{
				unit: '%',
				width: 90,
			},
			aspect,
			mediaWidth,
			mediaHeight,
		),
		mediaWidth,
		mediaHeight,
	);
}

function calculateMaxDimensions(imageWidth: number, imageHeight: number, cropAspectRatio: number) {
	const imageAspectRatio = imageWidth / imageHeight;

	let maxImageWidth, maxImageHeight;

	if (imageAspectRatio > cropAspectRatio) {
		maxImageWidth = imageHeight * cropAspectRatio;
		maxImageHeight = imageHeight;
	} else {
		maxImageWidth = imageWidth;
		maxImageHeight = imageWidth / cropAspectRatio;
	}

	return {
		maxWidth: maxImageWidth,
		maxHeight: maxImageHeight,
	};
}

type CropperProps = {
	setBgUrl: () => void;
	customRequest: () => void;
};

const Cropper: FC<CropperProps> = ({ customRequest, setBgUrl }) => {
	const [imgSrc, setImgSrc] = useState('');
	const previewCanvasRef = useRef<HTMLCanvasElement>(null);
	const imgRef = useRef<HTMLImageElement>(null);
	const [crop, setCrop] = useState<Crop>();
	const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
	const [aspect] = useState<number | undefined>(1440 / 342);

	function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files.length > 0) {
			setCrop(undefined); // Makes crop preview update between images.
			const reader = new FileReader();
			reader.addEventListener('load', () => setImgSrc(reader.result?.toString() || ''));
			reader.readAsDataURL(e.target.files[0]);
		}
	}

	function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
		if (aspect) {
			const { width, height } = e.currentTarget;
			setCrop(centerAspectCrop(width, height, aspect));
		}
	}

	async function onDownloadCropClick() {
		const image = imgRef.current;
		if (!image || !completedCrop) {
			throw new Error('Crop canvas does not exist');
		}
		const previewCanvas = previewCanvasRef.current;

		const imageDimensions = calculateMaxDimensions(image.naturalWidth, image.naturalHeight, aspect);

		const offscreen = new OffscreenCanvas(imageDimensions.maxWidth, imageDimensions.maxHeight);

		const base64Data = image.src;
		const type = base64Data.split(';')[0].split('/')[1];
		const ctx = offscreen.getContext('2d');
		if (!ctx) {
			throw new Error('No 2d context');
		}

		ctx.drawImage(
			previewCanvas,
			0,
			0,
			previewCanvas.width,
			previewCanvas.height,
			0,
			0,
			offscreen.width,
			offscreen.height,
		);
		const blob = await offscreen.convertToBlob({
			type: `image/${type}`,
		});

		await customRequest({
			file: blob,
			fileType: FileUploadTypesEnum.Logo,
			onSuccess: (all) => {
				setBgUrl(all.data);
				setImgSrc('');
			},
			onError: (all) => {
				setImgSrc('');
			},
			generateFilename: true,
		});
	}

	useDebounce(
		async () => {
			if (
				completedCrop?.width &&
				completedCrop?.height &&
				imgRef.current &&
				previewCanvasRef.current
			) {
				canvasPreview(imgRef.current, previewCanvasRef.current, completedCrop, 1);
			}
		},
		100,
		[completedCrop],
	);

	return (
		<>
			<div className='Crop-Controls'>
				<input type='file' accept='image/*' onChange={onSelectFile} />
			</div>
			{!!imgSrc && (
				<ReactCrop
					crop={crop}
					onChange={(_, percentCrop) => setCrop(percentCrop)}
					onComplete={(c) => setCompletedCrop(c)}
					aspect={aspect}
				>
					<img ref={imgRef} alt='Crop me' src={imgSrc} onLoad={onImageLoad} />
				</ReactCrop>
			)}
			{!!completedCrop && (
				<>
					<canvas
						ref={previewCanvasRef}
						style={{
							border: '1px solid black',
							objectFit: 'contain',
							width: completedCrop.width,
							height: completedCrop.height,
						}}
					/>
					<Button buttonType={ButtonTypes.primary} onClick={onDownloadCropClick}>
						Upload Image
					</Button>
				</>
			)}
		</>
	);
};

export default Cropper;
