import type { FC } from 'react';
import { useLocation } from 'react-router-dom';

import Container from 'components/Container';
import Footer from 'modules/Common/components/Footer';
import Header from 'modules/Common/components/Header';
import { COLORS } from 'theme';
import { ReactParent, Routes } from 'types';

import { S } from './BackofficeLayout.styled';

export const BackofficeLayout: FC<ReactParent> = ({ children }) => {
	const { pathname } = useLocation();

	const fullWidthRoute = pathname?.includes(`${Routes.BOClientContext}`);

	return (
		<S.Root>
			<>
				<Header />
				<S.Main>
					<Container noSpaces fullWidth={fullWidthRoute}>
						{children}
					</Container>
					<Footer styles={{ justifyContent: 'flex-end', gap: '24px' }} bgColor={COLORS.lightGray2}>
						<a href='/documents/bluetownonline-terms.pdf' target='_blank' rel='noreferrer'>
							Terms and Conditions
						</a>
						<span>© CONNECT 2024</span>
					</Footer>
				</S.Main>
			</>
		</S.Root>
	);
};
