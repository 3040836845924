import React, { type FC, useCallback, useEffect, useState } from 'react';

import { Tabs } from 'antd';

import Table from 'components/Table';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { TabsTitleEnum } from 'modules/Common/types';
import { propsFilter } from 'utils/helpers';

import { JobsListFields } from './JobsListContainer.constants';
import { Styled } from './JobsListContainer.styled';
import { JobsListContainerProps } from './JobsListContainer.types';
import { columns } from './JobsListTable.entities';

export const JobsListContainer: FC<JobsListContainerProps> = ({
	loading,
	jobsData,
	getAllBackOfficeJobs,
	isClientContext = false,
	pageLink,
	clientId,
}) => {
	const [tab, setTab] = useState<TabsTitleEnum | string>(TabsTitleEnum.ActiveTab);
	const { pageIndex, pageSize, totalCount } = jobsData;
	const [tableParams, setTableParams] = useState({});

	const activeData = propsFilter(jobsData?.data, JobsListFields);
	const isActiveTab = tab === TabsTitleEnum.ActiveTab;

	const handleChangeTabs = (id: string) => {
		setTab(id);
	};

	const handleTableChange = useCallback(
		(page: number, size: number) => {
			getAllBackOfficeJobs(
				{
					active: isActiveTab,
					page,
					size: size ?? pageSize,
					...(tableParams.search && { search: tableParams.search }),
				},
				isClientContext,
			);
		},
		[clientId, tableParams],
	);

	useEffect(() => {
		getAllBackOfficeJobs(
			{
				active: isActiveTab,
				page: 1,
				size: pageSize,
				...(tableParams.search && { search: tableParams.search }),
			},
			isClientContext,
		);
	}, [tab, clientId, tableParams]);

	const columnsData =
		pageLink && isClientContext ? columns(pageLink)?.slice(1) : pageLink && columns(pageLink);

	const handleSearch = (value: string) => {
		setTableParams({ search: value });
	};

	return (
		<Styled.JLCRoot>
			<Styled.JLCSearch>
				<SearchFilterSortWrapper
					search={{
						onSearch: handleSearch,
						isDebounce: false,
						placeholder: 'Search a job',
						width: '276px',
					}}
				/>
			</Styled.JLCSearch>
			<Tabs
				defaultActiveKey={TabsTitleEnum.ActiveTab}
				tabBarStyle={{ textTransform: 'uppercase' }}
				onChange={handleChangeTabs}
				items={[
					{
						label: 'Active',
						key: TabsTitleEnum.ActiveTab,
						children: (
							<Table
								data={activeData}
								columns={columnsData}
								loading={loading?.getAllBackofficeJobsLoad}
								pageSize={pageSize}
								current={pageIndex}
								total={totalCount}
								onChange={handleTableChange}
							/>
						),
					},
					{
						label: 'Archive',
						key: TabsTitleEnum.ArchiveTab,
						active: true,
						children: activeData && (
							<Table
								data={activeData}
								columns={columnsData}
								loading={loading?.getAllBackofficeJobsLoad}
								pageSize={3}
								current={pageIndex}
								total={totalCount}
								onChange={handleTableChange}
							/>
						),
					},
				]}
			/>
		</Styled.JLCRoot>
	);
};
