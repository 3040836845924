import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import _ from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import CalendarIcon from 'components/SVG/CalendarIcon';
import ListViewIcon from 'components/SVG/ListViewIcon';
import { useUnmount } from 'hooks';
import CandidateListSwitch from 'modules/ATS/components/CandidateListSwitcher';
import CountBadge from 'modules/ATS/components/CountBadge';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import InterviewCandidateList from 'modules/ATS/containers/InterviewCandidatesList';
import { atsDucks } from 'modules/ATS/ducks';
import { Routes } from 'types';

import InterviewCandidateCalendar from '../../containers/InterviewCandidataCalendar';

import { Styled } from './ATSInterviewsPage.styled';

const SWITCHER_ITEMS = [
	{
		id: '0',
		value: '0',
		icon: <CalendarIcon />,
	},
	{
		id: '1',
		value: '1',
		icon: <ListViewIcon />,
	},
];

interface ICountData {
	available: number;
	total: number;
}

const ATSInterviewsPage = ({
	interviewVideoCount,
	resetInterviewCount,
	resetInterviewJobsList,
}: {
	interviewVideoCount: Record<string, ICountData>;
	resetInterviewCount: () => void;
	resetInterviewJobsList: () => void;
}) => {
	const navigate = useNavigate();
	const [activeItem, onSwitch] = useState(SWITCHER_ITEMS[0].id);
	const isCalendarView = activeItem === SWITCHER_ITEMS[0].id;

	const renderCountBadges = (data: Record<string, ICountData>) => {
		const yearMonthsArr = Object.keys(data);

		return yearMonthsArr.map((yearMonth) => (
			<CountBadge
				key={yearMonth}
				count={data[yearMonth]}
				yearMonth={yearMonth}
				counterName='Video Interviews'
			/>
		));
	};

	useUnmount(() => {
		resetInterviewCount();
		resetInterviewJobsList();
	});

	return (
		<>
			<Styled.Head>
				<Styled.Title>Interviews</Styled.Title>
				<Styled.Buttons>
					{isCalendarView && !_.isEmpty(interviewVideoCount)
						? renderCountBadges(interviewVideoCount)
						: null}
					<CandidateListSwitch activeItem={activeItem} items={SWITCHER_ITEMS} onSwitch={onSwitch} />

					<ThemedButton
						buttonType={ButtonTypes.primary}
						onClick={() => navigate(`${Routes.ATS}${Routes.ArrangeInterviewCreate}`)}
					>
						Arrange Interview
					</ThemedButton>
				</Styled.Buttons>
			</Styled.Head>
			<Styled.Content>
				{isCalendarView && <InterviewCandidateCalendar />}
				{!isCalendarView && <InterviewCandidateList />}
			</Styled.Content>
		</>
	);
};

export default connect(
	(state) => ({
		interviewVideoCount: atsDucks.atsSelectors.getInterviewVideoCount(state),
	}),
	{
		resetInterviewCount: atsDucks.atsActions.resetInterviewCount,
		resetInterviewJobsList: atsDucks.atsActions.resetInterviewJobsList,
	},
)(ATSInterviewsPage);
