import React from 'react';

import { Popconfirm } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import DeleteIcon from 'components/SVG/DeleteIcon';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import { UserAccessColumnsType } from 'modules/Common/types';
import { COLORS, TStyled } from 'theme';
import { SettingsRoutes } from 'types';

import { Styled } from './ATSUserAccess.styled';

export const userAccessColumns = (deleteUser: (id: number) => void): ColumnsDataType => [
	{
		title: 'NAME',
		dataIndex: 'fullName',
		key: 'fullName',
		render: (_: string, { fullName }: Partial<UserAccessColumnsType>) => <span>{fullName}</span>,
	},
	{
		title: 'email address',
		dataIndex: 'email',
		key: 'email',
		render: (_: string, { email }: Partial<UserAccessColumnsType>) => {
			return <span>{email}</span>;
		},
	},
	{
		title: 'type',
		dataIndex: 'role',
		key: 'role',
		render: (_: string, { role }: Partial<UserAccessColumnsType>) => {
			return <span>{role}</span>;
		},
	},
	{
		title: 'ACTIONS',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_, { id }: Partial<UserAccessColumnsType>) => (
			<Styled.ButtonsWrapper>
				<TStyled.ButtonLink to={`${SettingsRoutes.UserAccessEdit}/${id}`}>
					<Styled.Button buttonType={ButtonTypes.default} icon={<EditIcon fill={COLORS.black} />} />
				</TStyled.ButtonLink>
				<Styled.DeleteButtonWrapper>
					<Popconfirm
						placement='topRight'
						title='Are you sure?'
						onConfirm={() => id && deleteUser(+id)}
						okText='Yes'
						cancelText='Cancel'
					>
						<>
							<Styled.Button
								buttonType={ButtonTypes.default}
								icon={<DeleteIcon width='16' height='16' fill={COLORS.black} />}
							/>
						</>
					</Popconfirm>
				</Styled.DeleteButtonWrapper>
			</Styled.ButtonsWrapper>
		),
	},
];
