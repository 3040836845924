import { MenuList } from 'modules/Common/types';
import { Routes } from 'types';

export const SuperUserMenuList = [
	{
		id: 101,
		name: MenuList.ClientContext,
		path: Routes.ClientContext,
	},
	{
		id: 1,
		name: MenuList.Dashboard,
		path: Routes.Dashboard,
	},
	{
		id: 2,
		name: MenuList.Clients,
		path: Routes.Clients,
	},
	{
		id: 3,
		name: MenuList.SupportTickets,
		path: Routes.SupportTickets,
	},
	{
		id: 4,
		name: MenuList.Vacancies,
		path: Routes.Jobs,
	},
	{
		id: 5,
		name: MenuList.Questionnaires,
		path: Routes.Questionnaires,
	},
	{
		id: 6,
		name: MenuList.Contracts,
		path: Routes.Contracts,
	},
	{
		id: 7,
		name: MenuList.Invoices,
		path: Routes.Invoices,
	},
];

export const SupportMenuList = [
	{
		id: 1,
		name: MenuList.SupportTickets,
		path: Routes.SupportTickets,
	},
];
