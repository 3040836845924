import React, { FC, useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';

import { Select, Input, DatePicker, type GetProps } from 'antd';
import dayjs from 'dayjs';

import FieldWrapper from 'components/FieldWrapper';
import FormSwitch from 'components/FormSwitch';
import { useMount } from 'hooks';
import * as Styled from 'modules/BackOffice/containers/ClientChangesFormBranding/ClientChangesFormBranding.styled';
import FormBlock from 'modules/Common/components/FormBlock';
import FormBlockLine from 'modules/Common/components/FormBlockLine';
import { ClientChangeFormDataType } from 'modules/Common/types';
import { OptionsType } from 'types';
import { positiveIntegersValidator } from 'utils/validators';

import { getSubsMessage, getCurrencySymbol } from './Subscriptions.helpers';

const { RangePicker } = DatePicker;

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>;

type SubscriptionsProps = {
	saveClientChangesFormProcess: (values: Partial<ClientChangeFormDataType>) => void;
	subscriptionOptions: OptionsType[];
	clientChangesFormData: ClientChangeFormDataType;
	isEdit: boolean;
	isSuperUser: boolean;
};

const subsPeriods = [
	{
		id: 3,
		name: 'Yearly',
		label: 'Yearly',
		value: 2,
	},
	{
		id: 4,
		name: 'Monthly',
		label: 'Monthly',
		value: 1,
	},
];

const Subscriptions: FC<SubscriptionsProps> = ({
	isEdit,
	clientChangesFormData,
	isSuperUser,
	saveClientChangesFormProcess,
}) => {
	const { values } = useFormState();
	const dateFormat = 'YYYY/MM/DD';
	const subsDetails = clientChangesFormData.subsInfo?.subsDetails;
	const currencySymbol = useMemo(() => getCurrencySymbol(values.region), [values]);

	const durationInitialValue = useMemo(() => {
		const subStartDate = clientChangesFormData?.subsInfo?.trialStartDate;
		const subEndDate = clientChangesFormData?.subsInfo?.trialEndDate;

		if (subStartDate && subEndDate) {
			return [dayjs(subStartDate, dateFormat), dayjs(subEndDate, dateFormat)];
		}

		return null;
	}, [
		clientChangesFormData?.subsInfo?.trialStartDate,
		clientChangesFormData?.subsInfo?.trialEndDate,
	]);

	// eslint-disable-next-line
	const disabledDate: RangePickerProps['disabledDate'] = (current: any) =>
		current && current < dayjs().subtract(1, 'day').endOf('day');

	useMount(() => {
		saveClientChangesFormProcess({
			initialTrialEnabled: subsDetails?.trial,
		});
	});

	return (
		<FormBlock title='Subscriptions'>
			<div>{subsDetails && getSubsMessage(subsDetails)}</div>
			<Styled.Space height={32} />
			<FormBlockLine columns={3}>
				<Styled.FieldWrapper>
					<Field
						validate={positiveIntegersValidator}
						name='annualPrice'
						initialValue={clientChangesFormData?.subsInfo?.annualPrice}
					>
						{({ input, meta }) => (
							<FieldWrapper
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								name='annualPrice'
								label={currencySymbol ? `Annual Price (${currencySymbol})` : 'Annual Price'}
							>
								<Input {...input} placeholder={`e.g. ${currencySymbol} 39`} />
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
				<Styled.FieldWrapper>
					<Field
						validate={positiveIntegersValidator}
						name='monthlyPrice'
						initialValue={clientChangesFormData?.subsInfo?.monthlyPrice}
					>
						{({ input, meta }) => (
							<FieldWrapper
								errorMessage={meta.submitFailed && meta.touched && meta.error}
								name='monthlyPrice'
								label={currencySymbol ? `Monthly Price (${currencySymbol})` : 'Monthly Price'}
							>
								<Input {...input} placeholder={`e.g. ${currencySymbol} 559`} />
							</FieldWrapper>
						)}
					</Field>
				</Styled.FieldWrapper>
			</FormBlockLine>
			<Styled.Space height={37} />
			{(!isEdit || !subsDetails?.premium || subsDetails?.premiumStatus === 'CANCELLED') && (
				<FormBlockLine columns={3}>
					<Styled.FieldWrapper>
						<Field name='planType'>
							{({ input }) => (
								<FieldWrapper hint='We will send an invoive with choosed period.'>
									<FormSwitch {...input} title='Send subscription invoice' />
								</FieldWrapper>
							)}
						</Field>
					</Styled.FieldWrapper>
					{values.planType && (
						<Styled.FieldWrapper>
							<Field name='subscriptionPlanDuration' initialValue={1}>
								{({ input }) => (
									<FieldWrapper name='subscriptionPlanDuration' label='Plan Period'>
										<Select
											{...input}
											value={input.value || null}
											placeholder='Select period'
											options={subsPeriods}
										/>
									</FieldWrapper>
								)}
							</Field>
						</Styled.FieldWrapper>
					)}
				</FormBlockLine>
			)}
			{(subsDetails || !isEdit) && (
				<FormBlockLine columns={3}>
					<Styled.FieldWrapper>
						<Field
							name='freeTrial'
							initialValue={clientChangesFormData?.subsInfo?.freeTrial || false}
						>
							{({ input }) => (
								<FieldWrapper
									name='freeTrial'
									hint='This feature can be selected just once for Trial Period'
								>
									<FormSwitch
										disabled={
											(subsDetails?.premium ||
												(subsDetails?.trial && !isSuperUser) ||
												(!subsDetails?.trialAvailable && !isSuperUser)) &&
											isEdit
										}
										{...input}
										defaultChecked
										title='Free Trial'
									/>
								</FieldWrapper>
							)}
						</Field>
					</Styled.FieldWrapper>
					<Styled.FieldWrapper>
						<Field name='trialDuration' initialValue={durationInitialValue}>
							{({ input }) => {
								return (
									<FieldWrapper name='trialDuration' label='Trial Period'>
										{/* @ts-ignore */}
										<RangePicker
											{...input}
											disabledDate={disabledDate}
											disabled={!values.freeTrial || subsDetails?.trial}
										/>
									</FieldWrapper>
								);
							}}
						</Field>
					</Styled.FieldWrapper>
				</FormBlockLine>
			)}
			<FormBlockLine columns={3}>
				<div hidden>
					<Styled.FieldLabel>Pro Features List</Styled.FieldLabel>
					<Styled.Space height={13} />
					<Field
						name='questionnairesFunctionality'
						initialValue={clientChangesFormData?.questionnairesFunctionality}
					>
						{({ input }) => (
							<FormSwitch defaultChecked {...input} title='Questionnaires Functionality' />
						)}
					</Field>
					<Styled.Space height={18} />
					<Field name='hrModule' initialValue={clientChangesFormData?.hrModule}>
						{({ input }) => <FormSwitch defaultChecked {...input} title='HR Module' />}
					</Field>
					<Styled.Space height={18} />
					<Field
						name='candidatesDatabaseEnabled'
						initialValue={clientChangesFormData?.candidatesDatabaseEnabled}
					>
						{({ input }) => <FormSwitch defaultChecked {...input} title='Candidate Database' />}
					</Field>
				</div>
			</FormBlockLine>
		</FormBlock>
	);
};

export default Subscriptions;
