import React, { FC, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import { Input, Radio, Button } from 'antd';
import _ from 'lodash';

import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import { RadioOptions } from 'modules/Common/constants';
import OrderCredits from 'modules/Common/containers/ContractView/OrderCredits';
import { commonDucks } from 'modules/Common/ducks';
import {
	IInvoicesDetailedValues,
	InvoicesStatusValuesEnum,
	InvoicesTypesValuesEnum,
	PaymentMethodEnum,
} from 'modules/Common/types';
import { getContractTableData } from 'modules/Common/utils/contracts';
import { CurrencyType, GenericType, Routes } from 'types';
import { getCurrencySymbolByName, getDateFormat } from 'utils/helpers';
import {
	composeValidators,
	requiredFieldValidator,
	lettersValidator,
	lengthValidator,
} from 'utils/validators';

import { backOfficeDucks } from '../../ducks';

import { Styled } from './ViewInvoiceForm.styled';

type ViewInvoiceFormProps = {
	clientContextId: string;
	getClientsListRequested: () => void;
	getInvoiceByIdRequested: (invoiceId: string) => void;
	loading: GenericType;
	currentInvoice: IInvoicesDetailedValues;
	getCreditsRequested: () => void;
	creditsFields: GenericType[];
	editInvoiceRequested: (values: GenericType) => void;
};

const ViewInvoiceForm: FC<ViewInvoiceFormProps> = ({
	clientContextId,
	loading,
	creditsFields,
	currentInvoice,
	getCreditsRequested,
	getInvoiceByIdRequested,
	getClientsListRequested,
}) => {
	const { invoiceId } = useParams();
	const { pathname } = useLocation();

	const isViewMode = pathname?.includes(Routes.InvoicesView);
	const currency = getCurrencySymbolByName(currentInvoice?.currency) || CurrencyType.PoundSterling;

	useEffect(() => {
		getClientsListRequested();
		getCreditsRequested();

		if (invoiceId) {
			getInvoiceByIdRequested(invoiceId);
		}
	}, [getClientsListRequested]);

	const onSubmit = (values: never) => values;

	if (loading?.getCurrentClientLoad || loading?.getInvoiceByIdLoad) {
		return <Spinner fixed />;
	}

	const isSubscriptionInvoice = [
		InvoicesTypesValuesEnum.PremiumSubscritionManual,
		InvoicesTypesValuesEnum.PremiumSubscrition,
	].includes(currentInvoice?.invoiceType?.value);

	const filteredData = isSubscriptionInvoice
		? [
			{
				key: 0,
				amount: 1,
				name: `${currentInvoice?.subscriptionPlanType?.value ?? ''} subscription`,
				unitPrice: currentInvoice?.subscriptionPlanType?.price || currentInvoice?.premiumPrice,
				totalPrice: currentInvoice?.subscriptionPlanType?.price || currentInvoice?.premiumPrice,
			},
		  ]
		: getContractTableData(creditsFields, currentInvoice);

	const isDataDueShown =
		(currentInvoice?.invoiceType?.value === InvoicesTypesValuesEnum.ContractAccept &&
			currentInvoice?.invoiceStatus?.value !== InvoicesStatusValuesEnum.Paid) ||
		currentInvoice?.invoiceType?.value === InvoicesTypesValuesEnum.Backoffice;

	const isPONumberShown =
		(!isViewMode || (isViewMode && currentInvoice?.poNumber)) && !isSubscriptionInvoice;

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Styled.BoxWrap isBoxComponent={!clientContextId}>
							<h2>Invoice Details</h2>
							<Styled.GridContainer>
								<Styled.FieldWrapper>
									<Field
										name='name'
										validate={requiredFieldValidator}
										initialValue={currentInvoice?.name || currentInvoice?.clientName}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name='name'
												label='Client / Company Name'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<Input disabled {...input} placeholder='Enter the name' />
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldWrapper>
								{isPONumberShown && (
									<Styled.FieldWrapper>
										<Field
											name='poNumber'
											validate={composeValidators(lengthValidator(4), lettersValidator)}
											initialValue={currentInvoice?.poNumber}
										>
											{({ input, meta }) => (
												<FieldWrapper
													name='poNumber'
													label='PO Number'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input disabled {...input} placeholder='Enter the code' />
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
								)}
								<Styled.FieldWrapper>
									<Field
										name='btoReference'
										validate={requiredFieldValidator}
										initialValue={currentInvoice?.btoReference}
									>
										{({ input, meta }) => (
											<FieldWrapper
												name='btoReference'
												label='Reference'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<Input disabled {...input} placeholder='Enter reference' />
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldWrapper>
								{isDataDueShown && (
									<Styled.FieldWrapper>
										<Field
											name='dateDue'
											validate={composeValidators(lengthValidator(4), lettersValidator)}
											initialValue={getDateFormat(currentInvoice?.dateDue)}
										>
											{({ input, meta }) => (
												<FieldWrapper
													name='dateDue'
													label='Date Due'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input disabled {...input} placeholder='Enter the date' />
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
								)}
								{currentInvoice?.invoiceStatus?.value === InvoicesStatusValuesEnum.Paid && (
									<>
										<Styled.FieldWrapper>
											<Field
												name='datePaid'
												validate={composeValidators(lengthValidator(4), lettersValidator)}
												initialValue={getDateFormat(currentInvoice?.datePaid)}
											>
												{({ input, meta }) => (
													<FieldWrapper
														name='datePaid'
														label='Date Paid'
														errorMessage={meta.submitFailed && meta.touched && meta.error}
													>
														<Input disabled {...input} placeholder='Enter the date' />
													</FieldWrapper>
												)}
											</Field>
										</Styled.FieldWrapper>
										<Styled.FieldWrapper>
											<Field
												name='paymentMethod'
												validate={requiredFieldValidator}
												initialValue={currentInvoice?.paymentMethod?.name || PaymentMethodEnum.Card}
											>
												{({ input, meta }) => (
													<FieldWrapper
														name='paymentMethod'
														label='Payment Method'
														errorMessage={meta.submitFailed && meta.touched && meta.error}
													>
														<Input disabled {...input} placeholder='Enter reference' />
													</FieldWrapper>
												)}
											</Field>
										</Styled.FieldWrapper>
									</>
								)}
								{!isViewMode && (
									<Styled.FieldWrapper>
										<Field name='chargeVat' initialValue={currentInvoice?.chargeVat}>
											{({ input, meta }) => (
												<FieldWrapper
													name='chargeVat'
													label='Charge VAT on Contracts?'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Radio.Group
														{...input}
														disabled
														value={_.isBoolean(input.value) ? input.value : false}
														options={RadioOptions}
													/>
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
								)}
							</Styled.GridContainer>
						</Styled.BoxWrap>
						<OrderCredits
							creditsFields={filteredData}
							currency={currency}
							totalPrice={
								isSubscriptionInvoice ? currentInvoice?.premiumPrice : currentInvoice?.totalPrice
							}
							totalPriceExVat={currentInvoice?.totalExVat}
							vatAmount={currentInvoice?.vatPrice}
							discountAmount={currentInvoice?.discountAmount}
						/>
						{!isViewMode && (
							<Button htmlType='submit' disabled={true}>
								Save
							</Button>
						)}
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		currentInvoice: backOfficeDucks.backOfficeSelectors.getCurrentInvoice(state),
		clients: backOfficeDucks.backOfficeSelectors.getClientsData(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		loading: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
	}),
	{
		editInvoiceRequested: backOfficeDucks.backOfficeActions.editInvoiceRequested,
		getClientsListRequested: backOfficeDucks.backOfficeActions.getClientsRequested,
		getInvoiceByIdRequested: backOfficeDucks.backOfficeActions.getInvoiceByIdRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
	},
)(ViewInvoiceForm);
