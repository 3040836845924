import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button, Modal } from 'antd';

import CloseIcon from 'components/SVG/CloseIcon';
import { Styled } from 'modules/ATS/containers/CandidatesApplication/CandidatesApplication.styled';
import QuestionnaireListTable from 'modules/ATS/containers/CandidatesApplication/QuestionnaireListTable';
import { IQuestionnairesPaginated } from 'modules/Common/types';
import { SettingsRoutes, SubscriptionPlansType } from 'types';

type SendQuestionnaireModalProps = {
	open: boolean;
	jobId?: number | string;
	candidateIds?: number[];
	subscriptionPlans: SubscriptionPlansType;
	sendQuestionnaireLink: string;
	sendQuestionnairesData: IQuestionnairesPaginated;
	handleSendQuestionnaire: (id: number) => void;
	onTableChange: (page: number, size: number) => void;
	onTablePageSizeChange: (page: number, size: number) => void;
	onClose: () => void;
	loading: boolean;
};

export const SendQuestionnaireModal: FC<SendQuestionnaireModalProps> = ({
	open,
	jobId = '',
	candidateIds = [],
	subscriptionPlans,
	sendQuestionnaireLink,
	sendQuestionnairesData,
	handleSendQuestionnaire,
	onTableChange,
	onTablePageSizeChange,
	onClose,
	loading,
}) => {
	const navigate = useNavigate();

	return (
		<Modal
			title={subscriptionPlans?.isPremiumPlan ? <h2>Send Video Questionnaire</h2> : ''}
			open={open}
			onCancel={onClose}
			closeIcon={<CloseIcon />}
			style={{ maxWidth: '820px' }}
			width={'100%'}
			footer={null}
			centered
		>
			<Styled.ModalBox>
				{subscriptionPlans?.isPremiumPlan ? (
					<>
						<Styled.CreateButton to={sendQuestionnaireLink} state={{ candidateIds, jobId }}>
							<Button type='primary'>Create new</Button>
						</Styled.CreateButton>
						<QuestionnaireListTable
							handleSendQuestionnaire={handleSendQuestionnaire}
							listData={sendQuestionnairesData}
							handleTableChange={onTableChange}
							handleTablePageSizeChange={onTablePageSizeChange}
							loading={loading}
						/>
					</>
				) : (
					<Styled.InfoBox>
						<p>This feature require a pro membership, upgrade or try free here</p>
						<Button type='primary' onClick={() => navigate(SettingsRoutes.Subscription)}>
							Upgrade Plan
						</Button>
					</Styled.InfoBox>
				)}
			</Styled.ModalBox>
		</Modal>
	);
};
