import { lighten } from 'polished';
import styled from 'styled-components/macro';

import { COLORS } from 'theme';

import { statusActiveColors, statusArchiveColors } from './JobsListStatistic.constants';

type StatisticItemProps = {
	statusId: number;
	isActive: boolean;
	isPremium: boolean;
	highlightColour?: string;
	idx?: number;
};

type TextProps = {
	textColour?: string;
};

type JobItemsProps = {
	dashboardStyles?: boolean;
};

export namespace Styled {
	export const Root = styled.div``;

	export const JobsItem = styled.div<JobItemsProps>`
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		margin: 8px 0;
		padding: 16px;
		gap: 12px;
		background-color: ${COLORS.white};
		border-radius: 4px;
		box-shadow: ${({ dashboardStyles }) =>
		dashboardStyles ? '0px 2px 4px 0px rgba(99, 111, 122, 0.12)' : 'none'};
	`;

	export const JobsItemTitle = styled.div<TextProps>`
		font-family: 'Inter';
		display: flex;
		justify-content: space-between;
		align-items: center;
		line-height: 30px;

		h2 {
			color: ${({ textColour }) => textColour};
			margin-bottom: 0 !important;
		}
	`;

	export const JobsIcons = styled.div`
		display: flex;
		gap: 0 20px;
	`;

	export const JobsStatistic = styled.div`
		display: flex;
		border-radius: 4px;
		overflow: hidden;
		gap: 20px;
	`;

	export const JobsStatisticItem = styled.div<StatisticItemProps>`
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		flex: 1;
		height: 68px;
		padding: 12px;
		text-align: center;
		font-family: 'Inter';
		${({ isActive, statusId, isPremium, highlightColour }) => `
			background-color: ${
				(isActive
					? isPremium && highlightColour
						? lighten((6 - statusId) / 10, highlightColour)
						: statusActiveColors[statusId - 1]
					: statusArchiveColors[statusId - 1]) || 'unset'
			};
		`};

		&:before {
			content: '';
			position: absolute;
			top: 0;
			right: -20px;
			width: 0;
			height: 0;
			border-left: 16px solid transparent;
			${({ isActive, statusId, isPremium, highlightColour }) => `
				border-top: 34px solid ${
					(isActive
						? isPremium && highlightColour
							? lighten((5 - statusId) / 10, highlightColour)
							: statusActiveColors[statusId]
						: statusArchiveColors[statusId]) || 'none'
				};


				border-bottom: 34px solid ${
					(isActive
						? isPremium && highlightColour
							? lighten((5 - statusId) / 10, highlightColour)
							: statusActiveColors[statusId]
						: statusArchiveColors[statusId]) || 'none'
				};
		`}
			z-index: 3;
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			right: -16px;
			width: 0;
			height: 0;
			border-top: 34px solid transparent;
			border-left: 16px solid
				${({ isActive, statusId, isPremium, highlightColour }) =>
					(isActive
						? isPremium && highlightColour
							? lighten((6 - statusId) / 10, highlightColour)
							: statusActiveColors[statusId - 1]
						: statusArchiveColors[statusId - 1]) || 'none'};
			border-bottom: 34px solid transparent;
			z-index: 3;
		}

		span {
			display: block;
			font-weight: 400;
			font-size: 12px;
			line-height: 15px;
		}

		&:first-child {
			${({ isActive, isPremium, highlightColour }) =>
							` color: ${isActive && isPremium ? highlightColour : COLORS.darkBlue1}; `};
		}
	`;

	export const JobsFooter = styled.div`
		display: flex;
		justify-content: space-between;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		font-family: 'Inter';

		span {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 8px;
		}
	`;

	export const JobsFooterLeft = styled.div`
		display: flex;
		gap: 8px;
	`;
}
