export interface ISubsPrices {
	ukMonthlyPrice: number;
	ukAnnualPrice: number;
	usaMonthlyPrice: number;
	usaAnnualPrice: number;
}

export interface IDefaultPrice {
	id: number;
	interval: 'MONTH' | 'YEAR';
	value: 'Annually' | 'Monthly';
	name: 'MONTHLY' | 'ANNUALLY';
	price: number;
}

export interface ICurrentSubscription {
	premiumType: {
		value: 'Annually' | 'Monthly';
		price: number;
		interval: 'YEAR' | 'MONTH';
	};
	nextAnnualPrice: string;
	nextMonthlyPrice: string;
	premium: true;
	premiumEndDate: string;
	premiumStartDate: string;
	premiumStatus: 'SUCCESS' | 'CANCELLED' | 'PENDING' | 'PRE_SUCCESS' | 'N_A';
	price: string;
	trial: false;
	trialAvailable: true;
	trialEndDate: string;
	trialStartDate: string;
	trialState: string;
	chargeVat: boolean;
}

export enum SubscriptionPayPremiumStatusesEnum {
	Success = 'success',
	Fail = 'fail',
}
