import { darken } from 'polished';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

type ButtonProps = {
	highlightColour: string;
};

export namespace Styled {
	export const JobsBlockWrapper = styled.section`
		display: flex;
		padding-bottom: 80px;

		@media screen and (max-width: 800px) {
			flex-direction: column;
		}
	`;

	export const JobsContacts = styled.section`
		width: 286px;
		flex-shrink: 0;
		margin-left: auto;
		margin-right: auto;

		@media screen and (max-width: 450px) {
			width: 100%;
		}
	`;
	export const JobsContactsApply = styled.div`
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 24px 32px 40px;
		background: ${COLORS.lightGray3};
		border-radius: 12px;
		width: 286px;
	`;
	export const JobsContactsApplyIcon = styled.div`
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(235, 235, 237, 0.5);
		border-radius: 50%;
		overflow: hidden;
		height: 92px;
		width: 92px;
	`;
	export const JobsContactsApplyTitle = styled.div`
		font-family: 'Raleway';
		font-weight: 600;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		letter-spacing: 0.01em;
		color: ${COLORS.black};
		padding-top: 16px;
	`;
	export const JobsContactsApplyDesc = styled.div`
		font-family: 'Raleway';
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		text-align: center;
		color: ${COLORS.black};
		padding-top: 16px;
	`;
	export const JobsContactsApplyButton = styled(ButtonComponent)<ButtonProps>`
		margin-top: 16px;
		width: 100%;
		background: ${({ highlightColour }) => highlightColour};
		font-family: 'Inter';

		:hover {
			background: ${({ highlightColour }) => highlightColour && darken(0.1, highlightColour)};
		}
	`;
	export const JobsContactsNetworks = styled.div`
		margin-top: 24px;
	`;
	export const JobsContactsNetworksTitle = styled.div`
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		letter-spacing: 2.3px;
		text-transform: uppercase;
		color: ${COLORS.darkGray5};
		padding-bottom: 24px;
	`;
	export const JobsContactsNetworksItems = styled.div`
		justify-content: center;
		display: flex;
	`;
	export const JobsContactsNetworksItem = styled.div`
		cursor: pointer;
		height: 46px;
		width: 46px;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 50%;
		background: ${COLORS.lightGray3};
		overflow: hidden;
		margin: 0 7px;
	`;

	export const JobsMain = styled.section`
		padding-left: 80px;
		width: 100%;

		@media screen and (max-width: 450px) {
			padding: 0 16px;
		}
	`;
	export const JobsTitle = styled.div`
		font-family: 'Raleway';
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 40px;
		letter-spacing: 0.01em;
		color: ${COLORS.black};
		padding-bottom: 16px;

		@media screen and (max-width: 450px) {
			font-size: 24px;
			line-height: 28px;
			padding-bottom: 6px;
		}
	`;
	export const JobItems = styled.div`
		display: grid;
		row-gap: 16px;
		width: 100%;
	`;
}
