export const clientChangesFormAllowedFields = [
	'region',
	'id',
	'parent',
	'clientName',
	'clientCode',
	'subdomain',
	'email',
	'logo',
	'companiesHouse',
	'companyCheck',
	'creditChecked',
	'requireUpfrontPayment',
	'companyAddress',
	'annualSubscription',
	'monthlySubscription',
	'candidatesDatabaseEnabled',
	'careersPageEnabled',
	'chargeVatOnContracts',
	'defaultPaymentTerms',
	'defaultPaymentTermsUnit',
	'sendRejectionLetter',
	'sendThankyouLetter',
	'interviewReminder',
	'enable2FA',
	'sendSMSQuestionnaireReminder',
];

export const subscriptionFields = [
	'planType',
	'freeTrial',
	'annualPrice',
	'monthlyPrice',
	'trialStartDate',
	'trialEndDate',
	'subscriptionPlanDuration',
	'questionnairesFunctionality',
	'hrModule',
];

export const CareerPageBrandingAllowedFields = [
	'textColour',
	'headerText',
	'backgroundImage',
	'highlightColour',
	'highlightColourAlternate',
	'accentColour',
	'logo',
	'backgroundImage',
	'whyOurCompany',
	'videoLink',
	'country',
	'city',
	'address',
	'zipCode',
	'email',
	'phoneNumber',
	'id',
	'client',
	'latitude',
	'longitude',
];

export const AtsBrandingAllowedFields = [
	'textColour',
	'headerText',
	'headerBackground',
	'backgroundImage',
	'highlightColour',
	'highlightColourAlternate',
	'accentColour',
	'logo',
	'backgroundImage',
	'id',
	'client',
];

export const AtsColoursAllowedFields = [
	'textColour',
	'headerBackground',
	'highlightColour',
	'highlightColourAlternate',
	'accentColour',
];

export const SocialNetworksAllowedFields = [
	'instagram',
	'facebook',
	'twitter',
	'linkedIn',
	'youtube',
];

export const contractChangesFormAllowedFields = [
	'client',
	'clientOfficeAddress',
	'poNumber',
	'durationDays',
	'durationDaysTimeUnit',
	'chargeVat',
	'generateInvoiceOnAcceptance',
	'paymentTerm',
	'paymentTermTimeUnit',
	'premiumMultisiteCount',
	'premiumMultisitePrice',
	'cvFilteringCount',
	'cvFilteringPrice',
	'guardianCount',
	'guardianPrice',
	'indeedCount',
	'indeedPrice',
	'getCopy',
	'recipientEmails',
];
