import React, { memo } from 'react';
import { NavLink } from 'react-router-dom';

import { INavListItem } from 'modules/Common/types';
import { Routes } from 'types';

import { S } from './MainMenu.styled';

type MainMenuType = {
	items: INavListItem[];
};

const MainMenu: React.FC<MainMenuType> = ({ items }) => {
	return (
		<S.Root>
			<S.MainMenu>
				{items?.map((item: INavListItem) => (
					<S.MenuItem key={item.id}>
						<NavLink to={`${Routes.BackOffice}${item.path}`}>{item.name}</NavLink>
					</S.MenuItem>
				))}
			</S.MainMenu>
		</S.Root>
	);
};

export default memo(MainMenu);
