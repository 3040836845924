import React, { ReactNode } from 'react';

import { Popconfirm, Switch } from 'antd';

import { ButtonTypes } from 'components/Button/Button.types';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import { COLORS, TStyled } from 'theme';
import { DataFormatEnum } from 'types';
import { getDateAndTime, getDateFormat } from 'utils/helpers';

import { Styled } from './UserAccessContainer.styled';

type UserAccessColumnsType = {
	id: number;
	fullName: string;
	email: string;
	role: string;
	lastSignIn: string;
	isActive: boolean;
	acceptedTermsAndConditions: boolean;
	customAction: (id: number) => ReactNode;
};

export const userAccessColumns = (
	deActivateUser: (id: number) => void,
	isActive: boolean,
	editUserLink: string,
	customAction,
): ColumnsDataType => [
	{
		title: 'Name',
		dataIndex: 'fullName',
		key: 'fullName',
		render: (_: string, { fullName }: Partial<UserAccessColumnsType>) => <span>{fullName}</span>,
	},
	{
		title: 'email address',
		dataIndex: 'email',
		key: 'email',
		align: 'center',
		render: (_: string, { email }: Partial<UserAccessColumnsType>) => {
			return <span>{email}</span>;
		},
	},
	{
		title: 'Access',
		dataIndex: 'role',
		key: 'role',
		align: 'center',
		render: (_: string, { role }: Partial<UserAccessColumnsType>) => {
			return <span>{role}</span>;
		},
	},
	{
		title: 'last login',
		dataIndex: 'lastSignIn',
		key: 'lastSignIn',
		align: 'center',
		render: (_: string, { lastSignIn }: Partial<UserAccessColumnsType>) => {
			const date = getDateFormat(
				getDateAndTime(lastSignIn, { withSeconds: true }),
				DataFormatEnum.FullHoursMinutes,
			);

			return <span>{date ?? '-'}</span>;
		},
	},
	{
		title: 'Enabled',
		dataIndex: 'isActive',
		key: 'isActive',
		align: 'center',
		render: (_: string, { id }: Partial<UserAccessColumnsType>) => {
			return (
				<div>
					<Popconfirm
						placement='top'
						title='Are you sure?'
						onConfirm={() => id && deActivateUser(+id)}
						okText='Yes'
						cancelText='Cancel'
					>
						<Switch defaultChecked={isActive} />
					</Popconfirm>
				</div>
			);
		},
	},
	{
		title: 'ACTIONS',
		key: 'action',
		align: 'center',
		width: '54px',
		render: (_, { id, acceptedTermsAndConditions }: Partial<UserAccessColumnsType>) => (
			<Styled.ButtonBox>
				<TStyled.ButtonLink to={editUserLink && `${editUserLink}/${id}`}>
					<Styled.Button
						buttonType={ButtonTypes.default}
						icon={<EditIcon fill={COLORS.black} />}
						disabled={!isActive}
					/>
				</TStyled.ButtonLink>
				{customAction && acceptedTermsAndConditions && customAction(id)}
			</Styled.ButtonBox>
		),
	},
];
