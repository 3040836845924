import React, { type FC } from 'react';
import { Outlet } from 'react-router-dom';

import { ReactParent } from 'types';

import BackofficeLayout from '../../components/BackofficeLayout';

export const BackOfficeMainPage: FC<ReactParent> = ({ children }) => {
	return (
		<BackofficeLayout>
			<Outlet />
			<>{children}</>
		</BackofficeLayout>
	);
};
