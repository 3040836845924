import React, { type FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Modal, Tabs, UploadFile } from 'antd';
import arrayMutators from 'final-form-arrays';
import _, { isEmpty } from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import BlueTownSmallLogo from 'components/SVG/BlueTownSmallLogo';
import CloseIcon from 'components/SVG/CloseIcon';
import { useMount, useUnmount } from 'hooks';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import ApplicationMethod from 'modules/Common/components/ApplicationMethod';
import AssessmentQuestions from 'modules/Common/components/AssessmentQuestions';
import { AppMethodsFreeData, AppMethodsPaidData } from 'modules/Common/constants';
import { commonDucks } from 'modules/Common/ducks';
import {
	AppMethodsEnum,
	CreditType,
	CreditWithNamesType,
	IPostFreeJobValues,
	IPostJobValues,
	IPostPaidJobValues,
	JobClassesEnum,
	JobClassesIdsEnum,
	JobDataType,
	PostJobTypeIdsEnum,
} from 'modules/Common/types';
import { handleCheckApplicationFormMethod } from 'modules/Common/utils/jobHelpers';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { COLORS } from 'theme';
import {
	CountriesEnumIds,
	CountriesType,
	GenericType,
	IOption,
	Routes,
	SubscriptionPlansType,
	UserRolesType,
	UserTypesEnum,
} from 'types';

import JobBoards from './JobBoards';
import JobsListTable from './JobsListTable';
import JobUpgradesContainer from './JobUpgrades';
import MainInfo from './MainInfo';
import { dynamicFormValidation } from './PostJob.helpers';
import { Styled } from './PostJob.styled';
import RoleInfo from './RoleInfo';

type UserType = { id: string; fullName: string };

type PostJobProps = {
	jobId?: string;
	clientId: number;
	userRoles: UserRolesType;
	countries: CountriesType;
	subscriptionPlans: SubscriptionPlansType;
	jobTypes: IOption[];
	clientUsers: UserType[];
	credits: CreditType[];
	commonJobUpgrades: IOption[];
	jobsData: {
		data: JobDataType[];
		pageIndex: number;
		pageSize: number;
		totalCount: number;
	};
	isEditMode: boolean;
	isCopyMode: boolean;
	isBoostMode: boolean;
	postJobChangesForm: JobDataType;
	employmentTypes: IOption[];
	educationTypes: IOption[];
	jobIndustries: IOption[];
	saveFreeJobRequested: (
		values: Partial<IPostFreeJobValues>,
		platform: UserTypesEnum,
		cb: (jobId: string) => void,
	) => void;
	savePaidJobRequested: (
		values: Partial<IPostPaidJobValues>,
		platform: UserTypesEnum,
		cb: (ticketId: number) => void,
	) => void;
	updateFreeJobRequested: (
		values: Partial<IPostFreeJobValues>,
		platform: UserTypesEnum,
		cb: (jobId: string) => void,
	) => void;
	updatePaidJobRequested: (
		values: Partial<IPostPaidJobValues>,
		isBoost: boolean,
		platform: UserTypesEnum,
		cb: (ticketId?: number) => void,
	) => void;
	getJobTypesRequested: () => void;
	getClientUsersRequested: () => void;
	getEmploymentTypesRequested: () => void;
	getEducationTypesByRegionId: (regionId: number) => void;
	getClientCopyJobsRequested: (p: { size: number; page: number }) => void;
	getAndSavePostJobChangesFormProcess: (jobId: number) => void;
	resetPostJobChangesFormProcess: () => void;
	getCommonJobUpgradesRequested: () => void;
	deleteAssessmentFormRequested: (jobId: number) => void;
	getCreditsRequested: (clientId: number) => void;
	getJobIndustriesRequested: () => void;
	resetActiveJobsList: () => void;
	loading: GenericType;
	loadingAts: GenericType;
};

const JOB_UPGRADES_VIEW_INFO = {
	'Hand Filtered CVs': {
		displayName: 'Hand Filtered CVs',
		messageWording: 'filtering CVs ',
		creditId: 2,
	},
	'The Guardian': {
		displayName: 'The Guardian',
		messageWording: 'guardian ',
		logoUrl: '/images/jobBoards/6.png',
		logoHeight: '31px',
		creditId: 3,
	},
	Indeed: {
		displayName: 'Indeed',
		messageWording: 'indeed sponsored ',
		logoUrl: '/images/jobBoards/8.png',
		logoHeight: '39px',
		creditId: 4,
	},
};

const PostJob: FC<PostJobProps> = ({
	jobId,
	clientId,
	userRoles,
	countries,
	jobsData,
	jobTypes,
	clientUsers,
	credits,
	isEditMode,
	isCopyMode,
	isBoostMode,
	postJobChangesForm,
	employmentTypes,
	educationTypes,
	jobIndustries,
	saveFreeJobRequested,
	savePaidJobRequested,
	updateFreeJobRequested,
	updatePaidJobRequested,
	getJobTypesRequested,
	getClientUsersRequested,
	getCreditsRequested,
	commonJobUpgrades,
	getCommonJobUpgradesRequested,
	getEmploymentTypesRequested,
	getEducationTypesByRegionId,
	getClientCopyJobsRequested,
	getAndSavePostJobChangesFormProcess,
	resetPostJobChangesFormProcess,
	deleteAssessmentFormRequested,
	getJobIndustriesRequested,
	resetActiveJobsList,
	loading,
	loadingAts,
}) => {
	const [creditWithNames, setCreditsWithNames] = useState<CreditWithNamesType[] | []>([]);
	const navigate = useNavigate();
	const { isUK } = countries || {};

	const [tab, setTab] = useState<string>(
		isUK ? PostJobTypeIdsEnum.PaidJob : PostJobTypeIdsEnum.FreeJob,
	);
	const [jobUpgrades, setJobUpgrades] = useState([]);
	const [modalOpen, setModalOpen] = useState(false);
	const [uploadFiles, setUploadFiles] = useState<UploadFile[]>([]);

	const { isAtsSuperUser } = userRoles || {};

	const isFreeJob = tab === PostJobTypeIdsEnum.FreeJob;
	const isPaidJob = tab === PostJobTypeIdsEnum.PaidJob;

	const initialValues = useMemo(
		() => ({
			client: clientId,
			applicationMethod: AppMethodsEnum.cvAndCoverLetter,
			assessmentQuestions: [],
			...(!isEmpty(postJobChangesForm) && postJobChangesForm),
		}),
		[clientId, postJobChangesForm],
	);

	const onSubmit = (values: IPostJobValues) => {
		const appMethodsValues = {
			[AppMethodsEnum.cvAndCoverLetter]:
				values?.applicationMethod === AppMethodsEnum.cvAndCoverLetter,
			[AppMethodsEnum.cvAndCoverLetterEmail]:
				values?.applicationMethod === AppMethodsEnum.cvAndCoverLetterEmail,
			[AppMethodsEnum.applicationForm]:
				values?.applicationMethod === AppMethodsEnum.applicationForm,
		};

		if (isFreeJob) {
			if (isEditMode) {
				updateFreeJobRequested(
					{
						...values,
						...appMethodsValues,
						educationType: values?.educationType || null,
						employmentType: values?.employmentType || null,
					},
					UserTypesEnum.ATS,
					() => navigate(`${Routes.ATS}${Routes.Jobs}/${jobId}`),
				);
			} else {
				saveFreeJobRequested(
					{ ...values, ...appMethodsValues },
					UserTypesEnum.ATS,
					(createdJobId: string) => {
						resetActiveJobsList();

						return navigate(`${Routes.ATS}${Routes.JobView}/${createdJobId}`);
					},
				);
			}
		} else if (isPaidJob) {
			const fieldsToOmit: string[] = [];

			if (
				!values.applicationFormAttachment ||
				values.applicationMethod !== AppMethodsEnum.applicationForm
			) {
				fieldsToOmit.push('applicationFormAttachment');
			}
			if (
				(isPaidJob &&
					handleCheckApplicationFormMethod(
						values?.applicationMethod,
						!!values?.applicationFormUrl && !values.applicationFormAttachment,
					)) ||
				(isFreeJob &&
					handleCheckApplicationFormMethod(values?.applicationMethod, !!values?.applicationFormUrl))
			) {
				fieldsToOmit.push('assessmentQuestions');
			}

			const preparedValues = _.omit(
				{
					...values,
					...appMethodsValues,
					attachments: uploadFiles,
					jobUpgrades,
					jobId,
					applicationFormAttachment:
						values.applicationFormAttachment && typeof values.applicationFormAttachment === 'object'
							? values.applicationFormAttachment?.s3FileReference?.referenceUuid
							: values.applicationFormAttachment,
				},
				fieldsToOmit,
			);

			if (isEditMode || isBoostMode) {
				updatePaidJobRequested(preparedValues, isBoostMode, UserTypesEnum.ATS, (ticketId) => {
					navigate(`${Routes.ATS}${Routes.SupportCenter}/${ticketId}`);
				});
			} else {
				savePaidJobRequested(preparedValues, UserTypesEnum.ATS, (ticketId) =>
					navigate(`${Routes.ATS}${Routes.SupportCenter}/${ticketId}`),
				);
			}
		}
	};

	const handleChangeTabs = (id: string) => {
		setTab(id);
	};

	const handleCopyJob = useCallback((id: number) => {
		id && getAndSavePostJobChangesFormProcess(id);
		setModalOpen(false);
		navigate(`${Routes.ATS}${Routes.PostJobCopy}/${id}`);
	}, []);

	const handleOpenModal = useCallback(() => {
		getClientCopyJobsRequested({ page: jobsData?.pageIndex, size: jobsData?.pageSize });
		setModalOpen(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleDeleteForm = useCallback(() => {
		jobId && deleteAssessmentFormRequested(+jobId);
	}, []);

	const handleTableChange = useCallback((page: number, size: number) => {
		getClientCopyJobsRequested({ page, size: size ?? jobsData?.pageIndex });
	}, []);

	const handleUploadFiles = useCallback((values) => {
		setUploadFiles(values);
	}, []);

	useEffect(() => {
		const preparedCredits = commonJobUpgrades.map((c) => ({
			...c,
			...JOB_UPGRADES_VIEW_INFO[c.name as keyof typeof JOB_UPGRADES_VIEW_INFO],
			count:
				credits.length &&
				credits.find((cr) => cr.credit === JOB_UPGRADES_VIEW_INFO[c.name]?.creditId)?.count,
		}));

		setCreditsWithNames(preparedCredits);
	}, [credits, commonJobUpgrades]);

	useEffect(() => {
		if (postJobChangesForm?.jobClass && !isBoostMode) {
			if (postJobChangesForm?.jobClass === JobClassesEnum.PaidJob) {
				handleChangeTabs(JobClassesIdsEnum.PREMIUM);
			} else {
				handleChangeTabs(JobClassesIdsEnum.SELF_SERVICE);
			}
		}
		if (postJobChangesForm?.jobClass === JobClassesEnum.PaidJob && isEditMode) {
			navigate(Routes.Forbidden);
		}
	}, [postJobChangesForm?.jobClass, isBoostMode]);

	useEffect(() => {
		if (clientId && isUK) {
			getCreditsRequested(clientId);
		}
	}, [clientId, isUK]);

	useMount(() => {
		!commonJobUpgrades?.length && getCommonJobUpgradesRequested();
		jobId &&
			(isEditMode || isCopyMode || isBoostMode) &&
			getAndSavePostJobChangesFormProcess(+jobId);
		!jobTypes?.length && getJobTypesRequested();
		!employmentTypes?.length && getEmploymentTypesRequested();
		!educationTypes?.length &&
			getEducationTypesByRegionId(isUK ? CountriesEnumIds.UK : CountriesEnumIds.US);
		isAtsSuperUser && !clientUsers?.length && getClientUsersRequested();
		!jobIndustries?.length && getJobIndustriesRequested();
	});

	useUnmount(() => {
		resetPostJobChangesFormProcess();
	});

	if (
		loadingAts?.getJobUpgragedLoad ||
		loadingAts?.getAndSavePostJobChangesFormLoad ||
		loading?.getEducationTypesLoad ||
		loading?.getEmploymentTypesLoad ||
		loading?.getJobTypesLoad ||
		loading?.getClientUsersLoad ||
		loading?.getJobIndustriesLoad
	) {
		return <Spinner fixed />;
	}

	const submitButtonLoading =
		loading?.savePaidJobLoad ||
		loading?.saveFreeJobLoad ||
		loading?.updatePaidJobLoad ||
		loading?.updateFreeJobLoad;

	const isDisabledPaidJob =
		((postJobChangesForm?.jobClass === JobClassesEnum.FreeJob && !isBoostMode) ||
			(postJobChangesForm?.jobClass === JobClassesEnum.PendingJob && !isBoostMode)) &&
		!isCopyMode;
	const jobRestrictedUserIds = postJobChangesForm.restrictedUserIds;

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				mutators={{
					...arrayMutators,
				}}
				validate={(values) => dynamicFormValidation(values, isPaidJob)}
				render={({ handleSubmit, dirty, values, form }) => {
					const hideAssessmentForm =
						(isPaidJob &&
							handleCheckApplicationFormMethod(
								values?.applicationMethod,
								!!values?.applicationFormUrl && !values?.applicationFormAttachment,
							)) ||
						(isFreeJob && handleCheckApplicationFormMethod(values?.applicationMethod, true));

					return (
						<form onSubmit={handleSubmit}>
							<MainInfo
								jobTypes={jobTypes}
								clientUsers={clientUsers}
								jobRestrictedUserIds={jobRestrictedUserIds}
								onOpenModal={handleOpenModal}
								isShownCopyButton={!isEditMode && !isCopyMode && !isBoostMode}
								isAtsSuperUser={isAtsSuperUser}
								readOnly={isBoostMode}
							/>

							{isUK ? (
								<Tabs
									defaultActiveKey={tab}
									onChange={handleChangeTabs}
									activeKey={tab}
									tabBarStyle={{ fontFamily: 'Inter' }}
									items={[
										{
											label: (
												<Styled.TitleWithImage>
													Publish with
													<BlueTownSmallLogo fill={COLORS.blue} />
												</Styled.TitleWithImage>
											),
											key: PostJobTypeIdsEnum.PaidJob,
											children: (
												<RoleInfo
													employmentTypes={employmentTypes}
													educationTypes={educationTypes}
													jobIndustries={jobIndustries}
													isPaidJob={isPaidJob}
													handleUploadFiles={handleUploadFiles}
													readOnly={isBoostMode}
												/>
											),
											disabled: isDisabledPaidJob || !isUK,
										},
										{
											label: 'Publish yourself',
											key: PostJobTypeIdsEnum.FreeJob,
											active: true,
											children: (
												<RoleInfo
													employmentTypes={employmentTypes}
													educationTypes={educationTypes}
													jobIndustries={jobIndustries}
													handleUploadFiles={handleUploadFiles}
													isPaidJob={isPaidJob}
													readOnly={isBoostMode}
												/>
											),
											disabled:
												(postJobChangesForm?.jobClass === JobClassesEnum.PaidJob || isBoostMode) &&
												!isCopyMode,
										},
									]}
								/>
							) : (
								<RoleInfo
									employmentTypes={employmentTypes}
									educationTypes={educationTypes}
									jobIndustries={jobIndustries}
									handleUploadFiles={handleUploadFiles}
									isPaidJob={isPaidJob}
								/>
							)}
							{isPaidJob ? (
								<ApplicationMethod
									appMethodsData={AppMethodsPaidData}
									defaultValue={values?.applicationMethod}
									setValues={form.change}
									readOnly={isBoostMode}
								/>
							) : (
								<ApplicationMethod
									appMethodsData={AppMethodsFreeData}
									defaultValue={values?.applicationMethod}
									setValues={form.change}
									readOnly={isBoostMode}
								/>
							)}
							{!hideAssessmentForm && (
								<AssessmentQuestions
									form={form}
									onDeleteForm={handleDeleteForm}
									readOnly={isBoostMode}
								/>
							)}

							{isPaidJob && (
								<>
									<JobUpgradesContainer
										jobUpgrades={jobUpgrades}
										setJobUpgrades={setJobUpgrades}
										credits={creditWithNames}
									/>
									{isUK && <JobBoards />}
								</>
							)}

							<Styled.ButtonBox>
								<ThemedButton
									type='submit'
									buttonType={ButtonTypes.primary}
									disabled={isBoostMode ? false : !dirty || !!submitButtonLoading}
									loading={!!submitButtonLoading}
								>
									{(isCopyMode && isPaidJob) || (isBoostMode && isPaidJob)
										? 'Send to Bluetown'
										: 'Publish'}
								</ThemedButton>
							</Styled.ButtonBox>
						</form>
					);
				}}
			/>
			{!isCopyMode && !isEditMode && (
				<Modal
					title={<h2>Copy Job</h2>}
					open={modalOpen}
					onCancel={handleCloseModal}
					closeIcon={<CloseIcon />}
					style={{ maxWidth: '820px' }}
					width={'100%'}
					footer={null}
					centered
				>
					<p>Please choose job to copy</p>
					<JobsListTable
						handleCopyJob={handleCopyJob}
						jobsData={jobsData}
						loading={!!loading?.getClientCopyJobsLoad}
						handleTableChange={handleTableChange}
					/>
				</Modal>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		userRoles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		jobTypes: commonDucks.commonSelectors.getJobTypes(state),
		clientUsers: commonDucks.commonSelectors.getClientUsers(state),
		employmentTypes: commonDucks.commonSelectors.getEmploymentTypes(state),
		educationTypes: commonDucks.commonSelectors.getEducationTypes(state),
		credits: atsDucks.atsSelectors.getCreditsState(state),
		commonJobUpgrades: atsDucks.atsSelectors.getJobUpgradesState(state),
		jobsData: commonDucks.commonSelectors.getClientCopyJobs(state),
		postJobChangesForm: atsDucks.atsSelectors.getPostJobChangesForm(state),
		jobIndustries: commonDucks.commonSelectors.getJobIndustries(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
		loadingAts: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getCommonJobUpgradesRequested: atsDucks.atsActions.getJobUpgradesRequested,
		getCreditsRequested: atsDucks.atsActions.getCurrentClientCredits,
		getClientUsersRequested: commonDucks.commonActions.getClientUsersRequested,
		getJobTypesRequested: commonDucks.commonActions.getJobTypesRequested,
		getEmploymentTypesRequested: commonDucks.commonActions.getEmploymentTypesRequested,
		getEducationTypesByRegionId: commonDucks.commonActions.getEducationTypesByRegionIdRequested,
		saveFreeJobRequested: commonDucks.commonActions.saveFreeJobRequested,
		savePaidJobRequested: commonDucks.commonActions.savePaidJobRequested,
		updateFreeJobRequested: commonDucks.commonActions.updateFreeJobRequested,
		updatePaidJobRequested: commonDucks.commonActions.updatePaidJobRequested,
		getClientCopyJobsRequested: commonDucks.commonActions.getClientCopyJobsRequested,
		getAndSavePostJobChangesFormProcess: atsDucks.atsActions.getAndSavePostJobChangesFormProcess,
		resetPostJobChangesFormProcess: atsDucks.atsActions.resetPostJobChangesFormProcess,
		deleteAssessmentFormRequested: atsDucks.atsActions.deleteAssessmentFormRequested,
		getJobIndustriesRequested: commonDucks.commonActions.getJobIndustriesRequested,
		resetActiveJobsList: atsDucks.atsActions.resetActiveJobsList,
	},
)(PostJob);
