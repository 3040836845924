import React, { type FC, useMemo } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';

import { Spin } from 'antd';

import backOfficeService from 'api/endpoints/backOffice';
import Box from 'components/Box';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FieldHtmlEditor from 'modules/Common/components/FieldEditor/FieldHtmlEditor';
import FormBlockGrid from 'modules/Common/components/FormBlockGrid';
import { convertContentFromHtml, convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { GenericType } from 'types';
import { requiredFieldValidator } from 'utils/validators';

import { SettingsPanelMenuList } from '../ATSSettings.types';

import { Styled } from './EmailSignature.styled';

type EmailSignatureDataType = {
	emailSignatureHtml: string;
};

export type EmailSignatureProps = {
	emailSignatureData: string;
	getClientEmailSignature: () => void;
	updateClientEmailSignature: (values: {
		emailSignatureHtml: string | TrustedHTML | undefined;
	}) => void;
	loading: GenericType;
};

const baseUrl = `${process.env.REACT_APP_API_URL}/open/public/file/logo/`;

/*
 We are checking if the first tag in the string is the <img/> in that case we remove
 it is because it happens due to the bug when we use backspace in the react-draft-wysiwyg
 to remove the image and it actually doesn't remove, and breaks the editor on next load.
 The solution is not ideal, because it doesn't take in the account other cases, but the thing
 it achieves it doesn't alow to break the editor in any case.
*/
const checkAndSanitizeEmailSignatureData = (htmlString: string) => {
	const imgTagRegex = /^<img[^>]*\/>/;

	htmlString = htmlString.replace(imgTagRegex, '');

	return htmlString || '<p></p>\n';
};

const EmailSignature: FC<EmailSignatureProps> = ({
	emailSignatureData,
	getClientEmailSignature,
	updateClientEmailSignature,
	loading,
}) => {
	const onSubmit = (values: EmailSignatureDataType) => {
		const preparedValues = {
			emailSignatureHtml: checkAndSanitizeEmailSignatureData(convertContentToHtml(values?.emailSignatureHtml)?.props
				?.dangerouslySetInnerHTML.__html),
		};
		updateClientEmailSignature(preparedValues);
	};

	useMount(() => {
		getClientEmailSignature();
	});

	const initialValues = useMemo(
		() => ({
			emailSignatureHtml: emailSignatureData && convertContentFromHtml(emailSignatureData),
		}),
		[emailSignatureData],
	);

	const isUpdating = !!loading?.updateClientEmailSignatureLoad;

	const uploadImageCallBack = async (file: File) => {
		let response: { data: File };
		await backOfficeService.uploadFile({
			file,
			onSuccess: (value: { data: File }) => {
				response = value;
			},
		});

		return new Promise((resolve) => {
			resolve({ data: { link: `${baseUrl}${response?.data}` } });
		});
	};

	return (
		<Styled.Root>
			{loading.getClientEmailSignatureLoad ? (
				<Spinner fullWidth />
			) : (
				<>
					<Form
						onSubmit={onSubmit}
						initialValues={initialValues}
						render={({ handleSubmit, dirty }) => (
							<form onSubmit={handleSubmit}>
								<Box noSpaces fullWidth style={{ flex: '1 1 auto' }}>
									<h2>{SettingsPanelMenuList.EmailSignature}</h2>
									<p>Your email signature will be used for outgoing candidate emails.</p>
									<FormBlockGrid columns={1}>
										<Field name={'emailSignatureHtml'} validate={requiredFieldValidator}>
											{({ input, meta }) => (
												<FieldWrapper
													name='emailSignatureHtml'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
													required
												>
													<FieldHtmlEditor
														onChange={(value) => input.onChange(value)}
														defaultValue={input.value}
														toolbarValues={{
															image: {
																uploadCallback: uploadImageCallBack,
																previewImage: true,
																component: undefined,
																popupClassName: undefined,
																urlEnabled: false,
																uploadEnabled: true,
																alignmentEnabled: false,
																inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
																alt: { present: false, mandatory: false },
																className: 'editor-item',
																dropdownClassName: undefined,
																defaultSize: {
																	height: '80px',
																	width: 'auto',
																},
															},
														}}
														placeholder='Enter your signature'
														editorClassName='email-signature'
														minRaws={10}
													/>
												</FieldWrapper>
											)}
										</Field>
									</FormBlockGrid>
								</Box>

								<Styled.ButtonWrapper>
									<ThemedButton
										buttonType={ButtonTypes.primary}
										type='submit'
										disabled={!dirty || isUpdating}
									>
										Save
										{isUpdating ? <Spin style={{ marginLeft: '12px' }} /> : ''}
									</ThemedButton>
								</Styled.ButtonWrapper>
							</form>
						)}
					/>
				</>
			)}
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		emailSignatureData: atsDucks.atsSelectors.getClientEmailSignature(state)?.emailSignatureHtml,
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getClientEmailSignature: atsDucks.atsActions.getClientEmailSignatureRequested,
		updateClientEmailSignature: atsDucks.atsActions.updateClientEmailSignatureRequested,
	},
)(EmailSignature);
