import { del, get, post } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const assessmentApplicationOpen = async (data) =>
	post(RequestsEnum.AssessmentApplicationOpen, data);

export const saveBOAssessment = async (data) => post(RequestsEnum.AssessmentBackoffice, data);

export const saveAtsAssessment = async (data) => post(RequestsEnum.AssessmentNew, data);

export const getAssessmentByJobId = async (id) => get(`${RequestsEnum.AssessmentJob}/${id}`);

export const getCandidateAssessmentApplication = async (id) =>
	get(`${RequestsEnum.AssessmentApplicationCandidate}/${id}`);

export const deleteAssessmentFormByJobId = async (data) => del(RequestsEnum.Assessment, data);

const assessmentService = {
	assessmentApplicationOpen,
	saveBOAssessment,
	saveAtsAssessment,
	getAssessmentByJobId,
	getCandidateAssessmentApplication,
	deleteAssessmentFormByJobId,
};

export default assessmentService;
