import styled from 'styled-components/macro';

export namespace S {
	export const Header = styled.header`
		padding: 16px 55px;
		background: ${({ theme }) => theme.colors.darkBlue};
		z-index: 9999;
	`;

	export const Container = styled.section`
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 0 auto;
		padding: 0;
		max-width: ${({ theme }) => theme.size.large};
	`;
}
