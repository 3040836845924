import React, { FC, useEffect } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { message } from 'antd';
import Cookies from 'js-cookie';

import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import ATSClientCredits from 'modules/ATS/containers/ATSClientCredits';
import JobBoards from 'modules/ATS/containers/PostJob/JobBoards';
import { commonDucks } from 'modules/Common/ducks';
import { CreditsType, PriceType } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import {
	GenericType,
	Routes,
	SubscriptionPlansType,
	CookiesType,
	CountriesType,
	CurrencyType,
} from 'types';

import { atsDucks } from '../../ducks';

import { Styled } from './ATSOrderCredits.styled';
import CreditsFields from './CreditsFields';

type ATSOrdertsProps = {
	loading: GenericType;
	subscriptionPlans: SubscriptionPlansType;
	creditsFields: CreditsType[];
	countries: CountriesType;
	getCreditsRequested: () => void;
	getContractVatRequested: () => void;
	orderCreditsRequested: (v: unknown, callback: (orderId: number) => void) => void;
	clientPrices: PriceType[];
	chargeVat: number;
	getPricesByClientIdRequested: (clientId: number) => void;
	clientId: number;
	chargeVatOnContracts: boolean;
};

const ATSOrderCredits: FC<ATSOrdertsProps> = ({
	loading,
	creditsFields,
	chargeVat,
	clientPrices,
	countries,
	getPricesByClientIdRequested,
	getCreditsRequested,
	getContractVatRequested,
	clientId,
	chargeVatOnContracts,
	orderCreditsRequested,
	subscriptionPlans,
}) => {
	const navigate = useNavigate();
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);
	const currencySymbol = countries.isUK ? CurrencyType.PoundSterling : CurrencyType.USDollar;

	useEffect(() => {
		getCreditsRequested();
		getPricesByClientIdRequested(clientId);
		getContractVatRequested();
	}, []);

	if (!chargeVat || !clientPrices.length || !creditsFields.length) {
		return <Spinner fixed />;
	}

	const onSubmit = (v) => {
		if (!Object.values(v).length) {
			message.error('Please fill the form');
		}

		orderCreditsRequested(v, (orderId) => navigate(`${Routes.ATS}${Routes.Credits}/${orderId}`));
	};

	const formInitValues = creditsFields.reduce((acc, cf) => ({ ...acc, [cf.value]: 0 }), {});

	return (
		<Styled.Root>
			<ATSClientCredits />
			<Form
				onSubmit={onSubmit}
				initialValues={formInitValues}
				autoComplete='off'
				render={({ handleSubmit, dirty }) => (
					<form onSubmit={handleSubmit}>
						<Styled.CreditsForm>
							<CreditsFields
								chargeVatOnContracts={chargeVatOnContracts}
								credits={creditsFields}
								clientPrices={clientPrices}
								vat={chargeVat}
								isPremium={subscriptionPlans?.isPremiumPlan}
								currency={currencySymbol}
							/>
						</Styled.CreditsForm>
						<JobBoards />
						{!isImpersonate && (
							<Styled.CheckoutContainer>
								<Styled.CheckoutInfo>
									<Styled.CheckoutInfoLine>
										All prices are exclusive of VAT.
									</Styled.CheckoutInfoLine>
									<Styled.CheckoutInfoLine>
										Job posting credits expire 12 months after purchase.
									</Styled.CheckoutInfoLine>
								</Styled.CheckoutInfo>
								<ThemedButton
									buttonType={ButtonTypes.primary}
									disabled={!dirty}
									type='submit'
									loading={!loading}
								>
									Go to Checkout
								</ThemedButton>
							</Styled.CheckoutContainer>
						)}
					</form>
				)}
			></Form>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		clientPrices: commonDucks.commonSelectors.getPricesByClientIdState(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
		chargeVat: commonDucks.commonSelectors.getContractVat(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		chargeVatOnContracts:
			unregisteredDucks.unregisteredSelectors.getUser(state)?.client?.chargeVatOnContracts,
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
	}),
	{
		getContractVatRequested: commonDucks.commonActions.getContractVatRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		getPricesByClientIdRequested: commonDucks.commonActions.getPricesByClientIdRequested,
		orderCreditsRequested: atsDucks.atsActions.orderCreditsRequested,
	},
)(ATSOrderCredits);
