import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import dayjs from 'dayjs';

import { atsDucks } from 'modules/ATS/ducks';
import DashboardActiveJobs from 'modules/Common/components/DashboardComponents/DashboardActiveJobs';
import DashboardAllCandidates from 'modules/Common/components/DashboardComponents/DashboardAllCandidates';
import DashboardBranding from 'modules/Common/components/DashboardComponents/DashboardBranding';
import DashboardEmails from 'modules/Common/components/DashboardComponents/DashboardEmails';
import DashboardInterviews from 'modules/Common/components/DashboardComponents/DashboardInterviews';
import DashboardJobsStatistics from 'modules/Common/components/DashboardComponents/DashboardJobsStatistics';
import {
	ClientBrandingDataType,
	InterviewListType,
	JobsDataType,
	CandidateStatisticsType,
	JobsStatisticType,
	DashboardUnreadEmailsType,
} from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, GenericType, Routes, SubscriptionPlansType } from 'types';
import { defaultFilterInterviewFunc } from 'utils/helpers';

import { Styled } from './Dashboard.styled';
import SubscriptionModal from './SubscriptionModal';

type DashboardProps = {
	jobsData: JobsDataType;
	clientBrandingData: ClientBrandingDataType;
	countries: CountriesType;
	subscriptionPlans: SubscriptionPlansType;
	interviewList: InterviewListType[];
	candidateStatistics: CandidateStatisticsType[];
	jobMonthStatistics: JobsStatisticType;
	emailList: DashboardUnreadEmailsType;
	loading: GenericType;
	getAllJobs: (params: { active: boolean; size?: number; page?: number }) => void;
	archiveAndUnarchiveJob: (jobId: number, active: boolean) => void;
	getInterviewCalendarList: ({ fromDate, toDate }: { fromDate: string; toDate: string }) => void;
	resetAtsInterviewCalenderList: () => void;
	getCandidateStatistics: (params: { timeFrameInDays?: number; periodType: string }) => void;
	getJobsMonthStatistic: () => void;
	getEmails: () => void;
};

const Dashboard = ({
	jobsData,
	clientBrandingData,
	countries,
	subscriptionPlans,
	interviewList,
	candidateStatistics,
	emailList,
	loading,
	getAllJobs,
	archiveAndUnarchiveJob,
	getInterviewCalendarList,
	resetAtsInterviewCalenderList,
	getCandidateStatistics,
	getJobsMonthStatistic,
	getEmails,
	jobMonthStatistics,
}: DashboardProps) => {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const navigate = useNavigate();

	const handleSeeAllJobs = () => {
		navigate(`${Routes.ATS}${Routes.Jobs}`);
	};
	const handlePostJob = () => {
		navigate(`${Routes.ATS}${Routes.PostJob}`);
	};
	const handleSeeAllStatistic = () => {
		if (subscriptionPlans.isPremiumPlan) {
			navigate(`${Routes.ATS}${Routes.Statistic}`);
		} else {
			handleOpenModal();
		}
	};
	const handleOpenModal = () => {
		setModalOpen(true);
	};
	const handleCloseModal = () => {
		setModalOpen(false);
	};
	const handleSeeAllMessages = () => {
		navigate(`${Routes.ATS}${Routes.Messages}`);
	};
	const getCandidateStatisticsBinded = (timeFrameInDays: number): void => {
		getCandidateStatistics({
			timeFrameInDays,
			periodType: 'days',
		});
	};

	return (
		<Styled.Root>
			<Styled.Header>
				<DashboardBranding
					clientBrandingData={clientBrandingData}
					subscriptionPlans={subscriptionPlans}
					loading={!!loading.getAtsBrandingByIdLoad}
				/>
			</Styled.Header>
			<Styled.Content>
				<Styled.LeftColumn>
					<DashboardJobsStatistics
						getJobsMonthStatistic={getJobsMonthStatistic}
						subscriptionPlans={subscriptionPlans}
						loading={loading?.getJobsMonthStatisticLoad}
						jobStatistic={jobMonthStatistics}
						clientBrandingData={clientBrandingData}
						handleSeeAllStatistic={handleSeeAllStatistic}
					/>
					<DashboardActiveJobs
						jobsData={jobsData}
						clientBrandingData={clientBrandingData}
						countries={countries}
						subscriptionPlans={subscriptionPlans}
						loading={!!loading?.getAllAtsJobsLoad}
						getAllJobs={getAllJobs}
						archiveAndUnarchiveJob={archiveAndUnarchiveJob}
						handleSeeAllJobs={handleSeeAllJobs}
						handlePostJob={handlePostJob}
					/>
				</Styled.LeftColumn>
				<Styled.RightColumn>
					<DashboardAllCandidates
						candidateStatistics={candidateStatistics}
						loading={!!loading?.getCandidateStatisticsLoad}
						getCandidateStatistics={getCandidateStatisticsBinded}
					/>
					<DashboardInterviews
						startDate={dayjs()}
						filterInterviewFunc={defaultFilterInterviewFunc}
						interviewList={interviewList}
						loading={!!loading?.getAtsInterviewCalendarListLoad}
						getInterviewCalendarList={getInterviewCalendarList}
						resetInterviewCalenderList={resetAtsInterviewCalenderList}
					/>
					<DashboardEmails
						emailList={emailList}
						loading={!!loading?.getDashboardUnreadEmailsLoad}
						getEmails={getEmails}
						handleSeeAllMessages={handleSeeAllMessages}
						linkToSingleMessage={Routes.ATS + Routes.Messages}
					/>
				</Styled.RightColumn>
			</Styled.Content>
			<SubscriptionModal modalOpen={modalOpen} handleCloseModal={handleCloseModal} />
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		jobsData: atsDucks.atsSelectors.getJobsData(state),
		clientBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		subscriptionPlans: atsDucks.atsSelectors.getSubscriptionPlans(state),
		interviewList: atsDucks.atsSelectors.getInterviewCalendarList(state),
		emailList: atsDucks.atsSelectors.getDashboardUnreadEmails(state),
		candidateStatistics: atsDucks.atsSelectors.getCandidateStatistics(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
		jobMonthStatistics: atsDucks.atsSelectors.getJobMonthStatisticsState(state),
	}),
	{
		getAllJobs: atsDucks.atsActions.getAllAtsJobsRequested,
		getJobsMonthStatistic: atsDucks.atsActions.getJobsMonthStatisticRequested,
		archiveAndUnarchiveJob: atsDucks.atsActions.archiveAndUnarchiveJobRequested,
		getInterviewCalendarList: atsDucks.atsActions.getAtsInterviewCalendarListRequested,
		resetAtsInterviewCalenderList: atsDucks.atsActions.resetAtsInterviewCalenderList,
		getCandidateStatistics: atsDucks.atsActions.getCandidateStatisticsRequested,
		getEmails: atsDucks.atsActions.getDashboardUnreadEmailsRequested,
	},
)(Dashboard);
