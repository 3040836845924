import React, { FC, useEffect } from 'react';
import { Line } from 'react-chartjs-2';

import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
} from 'chart.js';
import moment from 'moment';
import { darken } from 'polished';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { ShowMoreWrapperWrapper } from 'components/ShowMoreWrapper/ShowMoreWrapper';
import Spinner from 'components/Spinner';
import CVIcon from 'components/SVG/CVIcon';
import { ClientBrandingDataType, JobsStatisticType } from 'modules/Common/types';
import { getHtmlLegendPlugin } from 'modules/Common/utils/chartHelpers';
import { STATISTICS_COLORS } from 'modules/Common/utils/StatisticsUtils';
import { COLORS } from 'theme';
import { SubscriptionPlansType } from 'types';

import { Styled } from './DashboardJobsStatistics.styled';
import { options } from './DashboardJobStatistics.options';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

type DashboardJobsStatisticsProps = {
	getJobsMonthStatistic: () => void;
	jobStatistic: JobsStatisticType;
	clientBrandingData: ClientBrandingDataType;
	loading: boolean;
	handleSeeAllStatistic?: () => void;
	subscriptionPlans?: SubscriptionPlansType;
	startDate?: string;
};

const DashboardJobsStatistics: FC<DashboardJobsStatisticsProps> = ({
	jobStatistic,
	subscriptionPlans,
	clientBrandingData,
	getJobsMonthStatistic,
	loading,
	handleSeeAllStatistic,
	startDate,
}) => {
	useEffect(() => {
		getJobsMonthStatistic();
	}, []);
	const { isPremiumPlan } = subscriptionPlans || {};

	if (loading || !jobStatistic) {
		return <Spinner />;
	}

	if (!jobStatistic?.jobs?.length) {
		return (
			<Styled.Root>
				<Styled.Header>
					<Styled.Title>Applications Per Vacancy For Last Month</Styled.Title>
					{handleSeeAllStatistic && (
						<Button
							buttonColor={isPremiumPlan ? clientBrandingData?.highlightColour : COLORS.blue}
							buttonType={ButtonTypes.secondary}
							onClick={handleSeeAllStatistic}
						>
							View all statistics
						</Button>
					)}
				</Styled.Header>
				<Styled.NoActiveJobs>
					<Styled.CVIconContainer>
						<CVIcon width='120' height='124' />
					</Styled.CVIconContainer>
					<Styled.NoJobsText>
						Once the candidates start applying for your jobs, the number of applications will be
						displayed here
					</Styled.NoJobsText>
				</Styled.NoActiveJobs>
			</Styled.Root>
		);
	}

	const labels = [...new Array(31)]
		.map((i, idx) => ({
			l: moment(startDate || Date.now())
				.startOf('day')
				.subtract(idx, 'days')
				.format('DD.MM'),
			v: moment(startDate || Date.now())
				.startOf('day')
				.subtract(idx, 'days')
				.format('YYYY-MM-DD'),
		}))
		.reverse();

	const datasets = jobStatistic.jobs.map((j, idx) => {
		const currentColor =
			STATISTICS_COLORS[idx] ?? darken(0.1, STATISTICS_COLORS[idx % STATISTICS_COLORS.length]);
		let archieved = false;

		return {
			label: j.title,
			borderColor: currentColor,
			backgroundColor: currentColor,

			data: labels.reduce((acc, l) => {
				const applies = j.applicationsData.find((ad) => ad.date === l.v);
				archieved = archieved || l.v === moment(j.archivingDate).format('YYYY-MM-DD');

				if (applies) {
					acc = [...acc, applies.count];
				} else if (!archieved) {
					acc = [...acc, acc[acc.length - 1] || 0];
				}

				return acc;
			}, []),
		};
	});

	const data = {
		labels: labels.map((label) => label.l),
		datasets,
	};
	const htmlLegendPlugin = getHtmlLegendPlugin('htmlLegend12');

	return (
		<Styled.Root>
			<Styled.Header>
				<Styled.Title>Applications Per Vacancy For Last Month</Styled.Title>
				{handleSeeAllStatistic && (
					<Button
						buttonColor={isPremiumPlan ? clientBrandingData?.highlightColour : COLORS.blue}
						buttonType={ButtonTypes.secondary}
						onClick={handleSeeAllStatistic}
					>
						View all statistics
					</Button>
				)}
			</Styled.Header>
			<Styled.ChartWrapper>
				<Line options={options} data={data} plugins={[htmlLegendPlugin]} />
			</Styled.ChartWrapper>
			<Styled.LegendWrapper>
				<ShowMoreWrapperWrapper maxHeight={100}>
					<div id='legend-container123'></div>
				</ShowMoreWrapperWrapper>
			</Styled.LegendWrapper>
		</Styled.Root>
	);
};

export default DashboardJobsStatistics;
