import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import Button, { ButtonTypes } from 'components/Button';
import Spinner from 'components/Spinner';
import { ICurrentSubscription } from 'modules/Common/types';
import { ClientBrandingDataType } from 'modules/Common/types/branding';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { COLORS } from 'theme';
import { UserRolesType, CookiesType } from 'types';

import ATSLayout from '../../components/ATSLayout';
import { atsDucks } from '../../ducks';

import { Styled } from './ATSMainPage.styled';

type ATSMainPageProps = {
	clientId: number;
	clientAtsBrandingData: ClientBrandingDataType;
	currentSubscription: ICurrentSubscription;
	roles: UserRolesType;
	getATSBrandingByClientId: (clientId: number) => void;
	getCareerBrandingByClientId: (clientId: number) => void;
	getCurrentSubscriptionInfo: () => void;
	unImpersonateUser: (callbakc: () => void) => null;
};

const ATSMainPage: FC<ATSMainPageProps> = ({
	clientId,
	unImpersonateUser,
	roles,
	currentSubscription,
	clientAtsBrandingData,
	getATSBrandingByClientId,
	getCareerBrandingByClientId,
	getCurrentSubscriptionInfo,
}) => {
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);
	const brandingDataEmpty = isEmpty(clientAtsBrandingData) || !clientAtsBrandingData.id;
	const navigate = useNavigate();

	useEffect(() => {
		if (clientId && currentSubscription) {
			getATSBrandingByClientId(clientId);

			if (roles.isAtsSuperUser) {
				getCareerBrandingByClientId(clientId);
			}
		}
	}, [clientId, currentSubscription]);

	useEffect(() => {
		getCurrentSubscriptionInfo();
	}, []);

	if (!currentSubscription || brandingDataEmpty) {
		return <Spinner fullWidth />;
	}

	const handleUnImpersonate = () =>
		unImpersonateUser(() =>
			// navigate(`${Routes.BackOffice}${Routes.ClientContext}/${client.id}/users`),
			navigate(0),
		);

	return (
		<>
			{isImpersonate && (
				<Styled.UnPersonateContainer>
					<Styled.UnPersonateContainerInner>
						<Styled.UnPersonateContainerMessage>
							You are currently in the impersonate mode
						</Styled.UnPersonateContainerMessage>
						<div>
							<Button
								buttonType={ButtonTypes.primary}
								color={COLORS.black}
								onClick={handleUnImpersonate}
							>
								Back to Back Office
							</Button>
						</div>
					</Styled.UnPersonateContainerInner>
				</Styled.UnPersonateContainer>
			)}
			<ATSLayout
				currentSubscription={currentSubscription}
				navigationProps={{
					accentColour: clientAtsBrandingData?.accentColour,
					highlightColourAlternate: clientAtsBrandingData?.highlightColourAlternate,
				}}
			>
				<Outlet />
			</ATSLayout>
		</>
	);
};

export default connect(
	(state) => ({
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		clientId: unregisteredDucks.unregisteredSelectors.getClientId(state),
		clientAtsBrandingData: atsDucks.atsSelectors.getClientAtsBrandingData(state),
		currentSubscription: atsDucks.atsSelectors.getCurrentSubscriptionState(state),
	}),
	{
		getCurrentSubscriptionInfo: atsDucks.atsActions.getClientSubscriptionRequested,
		getATSBrandingByClientId: atsDucks.atsActions.getATSBrandingByClientId,
		getCareerBrandingByClientId: atsDucks.atsActions.getATSCareerBrandingByClientId,
		unImpersonateUser: unregisteredDucks.unregisteredActions.unImpersonateUserRequested,
	},
)(ATSMainPage);
