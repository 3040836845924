import { createGlobalStyle } from 'styled-components/macro';

import { COLORS, TYPOGRAPHY } from './index';

export const GlobalStyles = createGlobalStyle`
	#root {
    height: 100%;
    min-height: 100%;
/* 
    @media (-webkit-device-pixel-ratio: 1.25) {
        zoom: 0.9;
    }
    @media (-webkit-device-pixel-ratio: 1.2) {
        zoom: 0.9;
    }
    @media (-webkit-device-pixel-ratio: 1.15) {
        zoom: 0.9;
    } */
	}
	
  html,
  body {
    padding: 0;
    margin: 0;
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
		font-size: 16px;
		color: ${COLORS.black};
		background-color: ${COLORS.lightGray3};
    height: 100%;
    min-height: 100%;
    scroll-behavior: smooth;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
  }

  *,
	*::after,
	*::before {
    box-sizing: border-box;
  }

  ul {
		list-style: none;
		margin: 0;
		padding: 0;
	}
	
	h1 {
    font-size: ${TYPOGRAPHY.xxl}px;
    line-height: 40px;
	}
	
	h2 {
    font-size: ${TYPOGRAPHY.xl}px;
    line-height: 32px;
	}
	
	h3 {
    font-size: ${TYPOGRAPHY.l}px;
    line-height: 24px;
	}

  h4 {
    font-size: ${TYPOGRAPHY.m}px;
    line-height: 24px;
  }

  h1, h2, h3, h4, h5, h6 {
    font-weight: 600;
    margin: 0;
  }
	
	a {
		text-decoration: none;
	}

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input::placeholder,
  input::-webkit-input-placeholder,
  input:-moz-placeholder,
  input::-moz-placeholder,
  input:-ms-input-placeholder {
    color: ${COLORS.darkGray1};
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
	
  .ant-tooltip {
		white-space: pre-wrap;
		max-width: none;
		color: ${COLORS.darkGray3};
  }
  
  // Animation
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  .ant-message {
	  z-index: 99999;
  }
  
  .ant-upload-wrapper .ant-upload-list.ant-upload-list-picture .ant-upload-list-item .ant-upload-list-item-thumbnail {
    img {
      object-fit: contain;
    }
  }

  .ant-btn {
    border-radius: 4px;
  }

  .ant-modal {
	  .ant-modal-content {
    	padding: 32px 56px;
	  }
  }
  .ant-picker-dropdown {
	  min-width: 150px;
	  
	  .ant-picker-panel-layout .ant-picker-panel {
      width: 100%;
	  }
	  
	  .ant-picker-footer {
		  width: 100%;
		  
      .ant-picker-ranges {
	      justify-content: space-between;
      }
	  }
  }
  
  .receipt-modal .ant-modal-wrap {
    z-index: 99999;
  }
  
  .questionnaire-notification.ant-notification-notice {
    text-align: center;

	  .ant-notification-notice-content { 
		  .ant-notification-notice-icon {
       left: 38%;
		  }

      .ant-notification-notice-description {
        margin-inline-start: 0;
        margin-left: 0;
      }
	  }
  }
  
  .ant-popover.questionnaire-popconfirm {
	  max-width: 400px;
  }

  .ant-tabs-nav-operations {
    display: none !important;
  }

  video-player-container {
    width: 100%;
    height: auto;
  }

  video-player {
    width: 100%;
    height: auto;
    aspect-ratio: 16/9;
  }
`;
