import { get, post, put, patch } from 'api/axiosInstance';
import { RequestsEnum } from 'types';

export const getSubscriptionTypes = async () => {
	return get(RequestsEnum.SubscriptionTypes);
};

export const payClientPremium = async (type) => {
	return post(`${RequestsEnum.ClientPremiumPay}?type=${type}`);
};

export const updatePaymentDetails = async () => {
	return patch(RequestsEnum.ClientPremiumPay);
};

export const discardClientPremium = async () => {
	return post(RequestsEnum.ClientPremiumDiscard);
};

export const confirmClientPremium = async (ref) => {
	return patch(`${RequestsEnum.ClientPremiumConfirm}/${ref}`);
};

export const getClientPremiumTrial = async () => {
	return patch(RequestsEnum.ClientPremiumTrial);
};

export const cancelClientPremiumTrial = async () => {
	return post(RequestsEnum.CancelClientPremiumTrial);
};

export const wipePremium = async () => {
	return get(RequestsEnum.WipePremium);
};

export const getCurrentSubscriptionInfo = async () => {
	return get(RequestsEnum.CurrentSubscriptionInfo);
};

export const getClientSubscriptionInfoDetails = async (clientId) => {
	return get(`${RequestsEnum.CurrentSubscriptionInfoBO}/${clientId}`);
};

export const getSubscriptionPlans = async () => {
	return get(RequestsEnum.SubscriptionType);
};

export const getClientSubscriptionSettings = async (id) => {
	return get(`${RequestsEnum.SubscriptionSettings}/${id}`);
};

export const applySubscriptionSettings = async data => {
	return patch(RequestsEnum.SubscriptionSettings, data);
};

export const getSubsDefaultPrices = async () => get(RequestsEnum.SubscriptionsPrices);

export const updateSubsDefaultPrices = async (values) => put(RequestsEnum.SubscriptionsPrices, values);

export const SubscriptionService = {
	getSubscriptionTypes,
	payClientPremium,
	updatePaymentDetails,
	discardClientPremium,
	getClientPremiumTrial,
	getCurrentSubscriptionInfo,
	getClientSubscriptionInfoDetails,
	confirmClientPremium,
	cancelClientPremiumTrial,
	wipePremium,
	applySubscriptionSettings,
	getSubscriptionPlans,
	getClientSubscriptionSettings,
	getSubsDefaultPrices,
	updateSubsDefaultPrices,
};

export default SubscriptionService;
