import React, { FC, useEffect, useState, useCallback } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { Input } from 'antd';

import API from 'api';
import Box from 'components/Box';
import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import OrderCredits from 'modules/Common/containers/ContractView/OrderCredits';
import { commonDucks } from 'modules/Common/ducks';
import {
	IInvoicesDetailedValues,
	InvoicesStatusValuesEnum,
	InvoicesTypesEnum,
	InvoicesTypesValuesEnum,
	PaymentMethodEnum,
} from 'modules/Common/types';
import { getContractTableData } from 'modules/Common/utils/contracts';
import { getSortedUnitForMonths } from 'modules/Common/utils/invoices';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, CurrencyType, GenericType } from 'types';
import { getCurrencySymbolByName, getDateFormat } from 'utils/helpers';

import { atsDucks } from '../../ducks';
import { getPaymentUrl } from '../ATSSettings/Subscriptions/SubscriptionsPremiumBlock/SubscriptionPremiumBlock';

import { Styled } from './ATSViewInvoice.styled';

type EditInvoiceFormProps = {
	getInvoiceByIdRequested: (invoiceId: string) => void;
	loading: GenericType;
	countries: CountriesType;
	loadingAts: GenericType;
	getCreditsRequested: () => void;
	currentInvoice: IInvoicesDetailedValues;
	creditsFields: GenericType[];
};

type InvoiceRowDataType = {
	key: string;
	name: string;
	amount: number;
	unitPrice: number;
	totalPrice: number;
};

const EditInvoiceForm: FC<EditInvoiceFormProps> = ({
	loading,
	loadingAts,
	countries,
	creditsFields,
	currentInvoice,
	getCreditsRequested,
	getInvoiceByIdRequested,
}) => {
	const { invoiceId } = useParams();
	const { isUK } = countries || {};
	const currencySymbol = getCurrencySymbolByName(currentInvoice?.currency) || CurrencyType.PoundSterling;
	const [loadPaymentUrl, setLoadtPaymentUrl] = useState(false);

	useEffect(() => {
		if (isUK) {
			getCreditsRequested();
		}

		if (invoiceId) {
			getInvoiceByIdRequested(invoiceId);
		}
	}, [invoiceId, isUK]);

	const handlePayPremium = useCallback(
		() =>
			getPaymentUrl(
				() => API.paymentsService.payForInvoice({ itemId: currentInvoice.id }),
				setLoadtPaymentUrl,
			),
		[currentInvoice],
	);

	const onSubmit = () => ({});

	if (
		!currentInvoice ||
		loading?.getCreditsLoad ||
		loading?.getContractVatLoad ||
		loadingAts?.getAtsInvoiceByIdLoad
	) {
		return <Spinner fixed />;
	}

	const isSubscriptionInvoice = [
		InvoicesTypesValuesEnum.PremiumSubscritionManual,
		InvoicesTypesValuesEnum.PremiumSubscrition,
	].includes(currentInvoice?.invoiceType?.value);

	const isSmsORInterviewInvoice = [
		InvoicesTypesValuesEnum.SmsPurchase,
		InvoicesTypesValuesEnum.InterviewPurchase,
	].includes(currentInvoice?.invoiceType?.value);
	let unitFields = [] as InvoiceRowDataType[];

	if (isSmsORInterviewInvoice) {
		unitFields = getSortedUnitForMonths(currentInvoice) || [];
	}

	const filteredData = isSubscriptionInvoice
		? [
			{
				key: 0,
				amount: 1,
				name: `${currentInvoice?.subscriptionPlanType?.value ?? ''} subscription`,
				unitPrice: currentInvoice?.premiumPrice,
				totalPrice: currentInvoice?.premiumPrice,
			},
		  ]
		: [...getContractTableData(creditsFields, currentInvoice), ...unitFields];

	const isPONumberShown =
		currentInvoice?.invoiceType?.name !== InvoicesTypesEnum.CreditsPurchase &&
		!isSubscriptionInvoice &&
		currentInvoice?.poNumber;

	const isDataDueShown =
		(currentInvoice?.invoiceType?.value === InvoicesTypesValuesEnum.ContractAccept &&
			currentInvoice?.invoiceStatus?.value !== InvoicesStatusValuesEnum.Paid) ||
		currentInvoice?.invoiceType?.value === InvoicesTypesValuesEnum.Backoffice;
	const showUpgradePlanButton =
		isSubscriptionInvoice && currentInvoice.invoiceStatus.value !== InvoicesStatusValuesEnum.Paid;

	return (
		<div>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<Box>
							<h2>Invoice Details</h2>
							<Styled.GridContainer>
								<Styled.FieldWrapper>
									<Field name='btoReference' initialValue={currentInvoice?.btoReference}>
										{({ input, meta }) => (
											<FieldWrapper
												name='btoReference'
												label='Reference'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<Input disabled {...input} placeholder='Enter reference' />
											</FieldWrapper>
										)}
									</Field>
								</Styled.FieldWrapper>
								{isDataDueShown && (
									<Styled.FieldWrapper>
										<Field name='dateDue' initialValue={getDateFormat(currentInvoice?.dateDue)}>
											{({ input, meta }) => (
												<FieldWrapper
													name='dateDue'
													label='Date Due'
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input disabled {...input} placeholder='' />
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
								)}
								{isPONumberShown && (
									<Styled.FieldWrapper>
										<Field name='poNumber' initialValue={currentInvoice?.poNumber}>
											{({ input, meta }) => (
												<FieldWrapper
													name='poNumber'
													label='PO Number'
													hint={
														currentInvoice?.poNumber
															? ''
															: 'Enter a purchase order number if required'
													}
													errorMessage={meta.submitFailed && meta.touched && meta.error}
												>
													<Input disabled {...input} placeholder='' />
												</FieldWrapper>
											)}
										</Field>
									</Styled.FieldWrapper>
								)}

								{currentInvoice?.invoiceStatus?.value === InvoicesStatusValuesEnum.Paid && (
									<>
										<Styled.FieldWrapper>
											<Field
												name='paymentMethod'
												initialValue={currentInvoice?.paymentMethod?.name || PaymentMethodEnum.Card}
											>
												{({ input, meta }) => (
													<FieldWrapper
														name='paymentMethod'
														label='Payment Method'
														errorMessage={meta.submitFailed && meta.touched && meta.error}
													>
														<Input disabled {...input} placeholder='' />
													</FieldWrapper>
												)}
											</Field>
										</Styled.FieldWrapper>
										<Styled.FieldWrapper>
											<Field name='datePaid' initialValue={getDateFormat(currentInvoice?.datePaid)}>
												{({ input, meta }) => (
													<FieldWrapper
														name='datePaid'
														label='Date Paid'
														errorMessage={meta.submitFailed && meta.touched && meta.error}
													>
														<Input disabled {...input} />
													</FieldWrapper>
												)}
											</Field>
										</Styled.FieldWrapper>
									</>
								)}
							</Styled.GridContainer>
						</Box>
						{!!filteredData?.length && (
							<OrderCredits
								creditsFields={filteredData}
								currency={currencySymbol}
								totalPrice={
									isSubscriptionInvoice ? currentInvoice?.premiumPrice : currentInvoice?.totalPrice
								}
								totalPriceExVat={currentInvoice?.totalExVat}
								vatAmount={currentInvoice?.vatPrice}
								discountAmount={currentInvoice?.discountAmount}
							/>
						)}
					</form>
				)}
			/>
			{showUpgradePlanButton && (
				<Button
					isFullWidth
					disabled={loadPaymentUrl}
					buttonType={ButtonTypes.primary}
					onClick={handlePayPremium}
				>
					Upgrade Plan
				</Button>
			)}
		</div>
	);
};

export default connect(
	(state) => ({
		loadingAts: atsDucks.atsSelectors.getAtsLoading(state),
		loading: commonDucks.commonSelectors.commonLoading(state),
		currentInvoice: atsDucks.atsSelectors.getCurrentInvoice(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
	}),
	{
		getInvoiceByIdRequested: atsDucks.atsActions.getAtsInvoiceByIdRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
	},
)(EditInvoiceForm);
