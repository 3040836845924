import { Key } from 'react';

import { IInvoicesState, IInvoicesTypes } from './invoices';

export enum ContractStatusEnum {
	ACCEPTED = 'Accepted',
	OUTSTANDING = 'Outstanding',
	FULFILLED = 'Fulfilled',
	PENDING_APPROVAL = 'Pending for approval',
}

export enum ContractStatusValuesEnum {
	Accepted = 'ACCEPTED',
	Outstanding = 'OUTSTANDING',
	Fulfilled = 'FULFILLED',
	PendingForApproval = 'PENDING_APPROVAL',
}

export interface IContractState {
	id: number;
	name: ContractStatusEnum;
	value: ContractStatusValuesEnum;
}

export interface IContractValues {
	key?: Key;
	id?: number;
	contractId?: number;
	client: string;
	clientName: string;
	active: boolean;
	accepted: boolean;
	acceptedViaEmail: boolean;
	btoReference?: string;
	clientCode?: string;
	duration: string;
	generateInvoiceOnAcceptance: string;
	clientOfficeAddress: string;
	clientOffice?: string;
	poNumber: string;
	durationDays: string;
	durationDaysTimeUnit: string;
	paymentTerm: string;
	paymentTermTimeUnit: string;
	totalPrice: number;
	bluetownDetails: string;
	blueTownDetails: string;
	agreementReference: string;
	recipientEmails: string | null;
	startDate: string;
	endDate: string;
	contractAddress?: string;
	dateOfPayment?: string;
	invoice?: {
		id: number;
		btoReference: string;
		invoiceStatus: IInvoicesState;
		invoiceType: IInvoicesTypes;
		invoiceId?: number;
	};
	invoices:
		| {
				id?: number;
				invoiceId?: number;
				btoReference: string;
				invoiceStatus: IInvoicesState;
				invoiceType: IInvoicesTypes;
		  }[]
		| null;
	chargeVat: boolean;
	getCopy: boolean;
	signed: boolean;
	contractState: IContractState;
	creatorFirstName: string | null;
	creatorLastName: string | null;
	creator: number;
	clientAddress: string;
	premiumMultisiteCount: number;
	premiumMultisitePrice: number;
	cvFilteringCount: number;
	cvFilteringPrice: number;
	guardianCount: number;
	guardianPrice: number;
	indeedCount: number;
	indeedPrice: number;
	totalExVat: number;
	vatPrice: number;
	discountAmount?: number;
	sentContractEmails: {
		id: number;
		recipientEmails: string | null;
		getCopy: boolean;
		lastSentTime: string | null;
	};
	currency: string;
}
