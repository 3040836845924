import React, { FC } from 'react';

import { Upload } from 'antd';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import EditIcon from 'components/SVG/EditIcon';

import Cropper from './Cropper';

type BrandingImgUploaderProps = {
	isCropped: boolean;
	setBgUrl: () => void;
	customRequest: () => void;
};

export const BrandingImgUploader: FC<BrandingImgUploaderProps> = ({
	isCropped,
	setBgUrl,
	...props
}) => {
	return (
		<div>
			{isCropped ? (
				<Cropper setBgUrl={setBgUrl} customRequest={props.customRequest} />
			) : (
				<Upload {...props}>
					<Button buttonType={ButtonTypes.primary} type='button' icon={<EditIcon />} />
				</Upload>
			)}
		</div>
	);
};
