import { ContentState, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

export const convertContentToHtml = (value: string | undefined) => {
	try {
		const convertedToJson = typeof value === 'string' ? JSON.parse(value) : value;

		const htmlText = value && draftToHtml(convertedToJson);

		const createMarkup = (html: string | undefined): { __html: string } => {
			return { __html: html || '' };
		};

		return <div dangerouslySetInnerHTML={createMarkup(htmlText)} />;
	} catch (e) {
		console.error('convertContentToHtml error: ', e);
	}
};

export const convertContentFromHtml = (htmlString) => {
	try {
		const contentBlock = htmlToDraft(htmlString);

		if (!contentBlock || !contentBlock.contentBlocks) {
			console.error('Failed to convert HTML to Draft.js content');

			return null;
		}

		const entityMap = contentBlock.entityMap || {};

		const contentState = ContentState?.createFromBlockArray(contentBlock.contentBlocks, entityMap);

		return contentState && EditorState.createWithContent(contentState);
	} catch (error) {
		console.error('Error converting HTML to Draft.js content:', error);

		return null;
	}
};
