import { useLayoutEffect, type FC } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import Cookies from 'js-cookie';

import { setAxiosInterceptor } from 'api/axiosInstance';
import { CookiesType, ReactParent, Routes, RouteTypesEnum, UserTypesEnum } from 'types';
import { LocalStorageType } from 'types/storage';
import { getFromLocalStorage } from 'utils/helpers';

import AuthLayout from '../../containers/AuthLayout';

type ProtectedRouterProps = ReactParent & {
	type: RouteTypesEnum;
};

export const ProtectedRoute: FC<ProtectedRouterProps> = ({
	type = RouteTypesEnum.UNREGISTERED,
	children,
}) => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const isRegisteredType = type === RouteTypesEnum.REGISTERED;

	const { isTempPassword, userType } = getFromLocalStorage(LocalStorageType.User) ?? {};
	const jwtToken = Cookies.get(CookiesType.JWT);

	const isUnauthorized = !jwtToken && isRegisteredType;
	const isNotFinishedRegistration = jwtToken && isRegisteredType && isTempPassword;
	const isAuthorized = !isTempPassword && jwtToken && isRegisteredType;

	const isBackofficeUser = +userType === UserTypesEnum.BACKOFFICE;
	const isBackofficeRoute = pathname.includes(Routes.BackOffice);
	const isAtsUser = +userType === UserTypesEnum.ATS;
	const isAtsRoute = pathname.includes(Routes.ATS);
	const hasNoRoute = pathname === '/';

	useLayoutEffect(() => {
		const { removeInterceptors } = setAxiosInterceptor(navigate, type);

		return () => {
			removeInterceptors();
		};
	}, [navigate, type]);

	if (isUnauthorized) {
		Cookies.remove(CookiesType.UserId);
		localStorage.clear();

		return <Navigate to={Routes.Login} replace />;
	}
	if (
		(isNotFinishedRegistration && isBackofficeRoute) ||
		(isNotFinishedRegistration && isAtsRoute) ||
		(isNotFinishedRegistration && hasNoRoute)
	) {
		return <Navigate to={`${Routes.FinishRegistration}`} />;
	}

	if (isAuthorized && isBackofficeUser && !isBackofficeRoute) {
		return <Navigate to={`${Routes.BackOffice}${Routes.Dashboard}`} />;
	}

	if (isAuthorized && isAtsUser && !isAtsRoute) {
		return <Navigate to={`${Routes.ATS}${Routes.Dashboard}`} />;
	}

	return isRegisteredType ? <AuthLayout>{children}</AuthLayout> : <>{children}</>;
};
