import { FieldInputType } from 'modules/Common/types';

export interface RadioFieldOption {
	label: string;
	value: string;
	fields?: FieldInputType[];
}

export type JobApplicationFormType = {
	applicationMethod: AppMethodsEnum;
	applicationMethodAttachment?: string;
};

export interface IPostJobValues {
	title: string;
	jobType: number;
	location: string;
	salary: string;
	restricted: boolean;
	restrictedUserIds: string[];
	experienceLevel: number;
	educationType: number | null;
	employmentType: number | null;
	jobIndustry: number;
	jobDescription: string;
	applicationMethod: string;
	cvAndCoverLetter: boolean;
	applicationFormAttachment: string;
	applicationFormUrl: string;
	jobStartDate?: string | null;
	attachments?: string[];
}

export interface IJobValues extends IPostJobValues {
	clientId: string;
	jobAttachmentFile: string;
	ApplicationFormAttachment: string;
}

export interface IPostFreeJobValues extends IPostJobValues {
	assessmentQuestions?: [];
	jobUpgrades?: number[];
}

export interface IPostPaidJobValues extends IPostJobValues {
	attachments?: string[];
	jobUpgrades?: number[];
}

export interface IPostJobApplicationMethodFields {
	applicationFormAttachment?: string;
	applicationFormUrl?: string;
	applicationMethod?: AppMethodsEnum;
}

export enum AppMethodsEnum {
	cvAndCoverLetter = 'cvAndCoverLetter',
	cvAndCoverLetterEmail = 'cvAndCoverLetterEmail',
	applicationForm = 'applicationForm',
}

export enum ApplicationFormFieldsEnum {
	ApplicationFormUrl = 'applicationFormUrl',
	applicationEmail = 'applicationEmail',
	ApplicationFormAttachment = 'applicationFormAttachment',
	ApplicationFormUploadForm = 'applicationFormUploadForm',
}

export const enum PostJobTypeIdsEnum {
	PaidJob = '1',
	FreeJob = '2',
	PendingBoost = '3',
}

export const enum PostJobTypeValuesEnum {
	PaidJob = 'Paid job',
	FreeJob = 'Free job',
	PendingBoost = 'Pending boost',
}

export const enum PostJobTypeValuesSimpleEnum {
	PaidJob = 'Paid',
	FreeJob = 'Free',
}
