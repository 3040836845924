import React, { type FC, FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CheckboxValueType } from 'antd/es/checkbox/Group';
import dayjs from 'dayjs';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-calculate';
import { pick } from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import Spinner from 'components/Spinner';
import { useMount } from 'hooks';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import { InterviewTypesFormEnum, IUser } from 'modules/Common/types';
import { convertContentToHtml } from 'modules/Common/utils/editorHelper';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { GenericType, IOption, Routes } from 'types';
import { getDate, getDateAndTime } from 'utils/helpers';

import { AllowedInterviewFormFields } from '../ArrangeInterview.constants';
import { Styled } from '../ArrangeInterview.styled';
import {
	ArrangeInterviewFormParamsType,
	ClientApplicationMessageType,
	IArrangeCandidate,
	IOptionArrange,
	IMonthInterviewCountData,
} from '../ArrangeInterview.types';
import Details from '../Details';
import Guests from '../Guests';

type ArrangeInterviewProps = {
	user: IUser;
	activeJobs: IOption[];
	interviewTypes: IOption[];
	interviewUsers: IOptionArrange[];
	candidateApplicationData: IArrangeCandidate[];
	interviewVideoCount: {
		[key: string]: IMonthInterviewCountData;
	};
	getAtsActiveJobs: () => void;
	getAtsInterviewType: () => void;
	getAtsUserInterview: () => void;
	getCandidateApplicationById: (jobId: string | number, cb?: () => void) => void;
	clientApplicationNotification: ClientApplicationMessageType;
	getClientApplicationNotification: (id: number) => void;
	saveAtsInterview: (values: Partial<ArrangeInterviewFormParamsType>, cb: () => void) => void;
	getVideoInterviewCount: (dates: string[]) => void;
	resetInterviewCount: () => void;
	handleOpenModal: (monthName: string) => void;
	candidateId?: string;
	jobId?: string;
	loading: GenericType;
};

const ArrangeInterviewCreate: FC<ArrangeInterviewProps> = ({
	user,
	activeJobs,
	interviewTypes,
	interviewUsers,
	candidateApplicationData,
	interviewVideoCount,
	getAtsActiveJobs,
	getAtsInterviewType,
	getAtsUserInterview,
	getCandidateApplicationById,
	clientApplicationNotification,
	getClientApplicationNotification,
	saveAtsInterview,
	getVideoInterviewCount,
	resetInterviewCount,
	handleOpenModal,
	candidateId,
	jobId,
	loading,
}) => {
	const isPredefinedInterview = jobId && candidateId;
	const [preparedToRenderForm, setPreparedToRenderForm] = useState(!isPredefinedInterview);
	const navigate = useNavigate();
	const [currentCandidate, setCurrentCandidate] = useState<IArrangeCandidate | null>(null);
	const [guestsOptions, setGuestsOptions] = useState<Partial<IOptionArrange>[] | []>([]);
	const [selectedOptions, setSelectedOptions] = useState<CheckboxValueType[] | []>([]);

	useEffect(() => {
		if (!preparedToRenderForm) {
			jobId && getCandidateApplicationById(jobId, () => setPreparedToRenderForm(true));
		}
	}, [preparedToRenderForm, jobId]);

	const handleChangeJob = useCallback((id: string | number) => {
		getCandidateApplicationById(id);
		setCurrentCandidate(null);
	}, []);

	const handleSetCurrentCandidate = useCallback(
		(value: IArrangeCandidate | null) => {
			setCurrentCandidate(value);
		},
		[guestsOptions],
	);

	const handleAddGuests = useCallback(
		(value: Partial<IOptionArrange>) => {
			setGuestsOptions((prevState) => [...prevState, value]);
		},
		[guestsOptions, selectedOptions],
	);

	const handleChangeGuests = useCallback(
		(values: CheckboxValueType[]) => {
			setSelectedOptions(values);
		},
		[selectedOptions],
	);

	const handleCancelForm = () => {
		navigate(-1);
	};

	const handleDeleteGuest = useCallback(
		(event: FormEvent, value: Partial<IOptionArrange>) => {
			event.preventDefault();

			setGuestsOptions((prevState) => {
				return prevState.filter((option) => option.email !== value?.email);
			});
		},
		[guestsOptions, selectedOptions],
	);

	const onSubmit = (values: Partial<ArrangeInterviewFormParamsType>) => {
		const users = guestsOptions?.length
			? guestsOptions
				.filter((i) => selectedOptions?.includes(i.id as never))
				.map((option: Partial<IOptionArrange>) => ({ id: option.id, email: option.email }))
			: [];
		const guests = guestsOptions?.slice().filter((i) => !i.id);

		const startDate = getDateAndTime(values?.startDate);

		const yearMonth = dayjs(values?.startDate).format('YYYY-MM');

		const isVideoOnlineType = values?.interviewType === InterviewTypesFormEnum.VideoOnline;

		if (isVideoOnlineType && interviewVideoCount[yearMonth]?.available === 0) {
			return handleOpenModal(yearMonth);
		}
		const preparedValues = pick(
			{
				...values,
				guests: [...users, ...guests],
				startDate,
				recordInterview: isVideoOnlineType ? values?.recordInterview : false,
				sendCandidateSmsReminder: isVideoOnlineType ? values?.sendCandidateSmsReminder : false,
				information: convertContentToHtml(values?.information)?.props?.dangerouslySetInnerHTML
					.__html,
			},
			AllowedInterviewFormFields,
		) as ArrangeInterviewFormParamsType;

		saveAtsInterview(preparedValues, () => navigate(`${Routes.ATS}${Routes.Interviews}`));
	};

	useMount(() => {
		!interviewTypes?.length && getAtsInterviewType();
		!interviewUsers?.length && getAtsUserInterview();
		!activeJobs?.length && getAtsActiveJobs();
	});

	useEffect(() => {
		if (interviewUsers?.length) {
			const filteredOption = interviewUsers.filter((i) => i.id !== user?.id);
			setGuestsOptions(filteredOption);
		}
		user?.clientId && getClientApplicationNotification(user?.clientId);
	}, [interviewUsers, user?.clientId]);

	useEffect(() => {
		if (isPredefinedInterview && candidateApplicationData?.length) {
			const candidate = candidateApplicationData?.find((i) => i.id === +candidateId);
			candidate && setCurrentCandidate(candidate);
		}
	}, [candidateId, candidateApplicationData]);

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'role',
					updates: {
						candidateApplicationId: (
							value,
							curr?: Partial<ArrangeInterviewFormParamsType>,
							prev?: Partial<ArrangeInterviewFormParamsType>,
						) => {
							if (prev && !Object.keys(prev).length) {
								return curr?.candidateApplicationId;
							}

							return null;
						},
					},
				},
				{
					field: 'startDate',
					updates: (value, _, allValues?: Partial<ArrangeInterviewFormParamsType>) => {
						const isVideoOnlineType =
							allValues?.interviewType === InterviewTypesFormEnum.VideoOnline;
						const interviewDate = getDate(value);
						if (interviewDate && isVideoOnlineType) {
							const { year, month } = interviewDate;
							year && month && getVideoInterviewCount([`${year}-${month}`]);
						} else {
							resetInterviewCount();
						}

						return {};
					},
				},
				{
					field: 'interviewType',
					updates: (value, _, allValues?: Partial<ArrangeInterviewFormParamsType>) => {
						const isVideoOnlineType = value === InterviewTypesFormEnum.VideoOnline;
						const interviewDate = allValues?.startDate && getDate(allValues.startDate);
						if (isVideoOnlineType && interviewDate) {
							const { year, month } = interviewDate;
							year && month && getVideoInterviewCount([`${year}-${month}`]);
						} else {
							resetInterviewCount();
						}

						return {};
					},
				},
			),
		[],
	) as never;

	const initialValues: Partial<ArrangeInterviewFormParamsType> = useMemo(
		() => ({
			recordInterview: false,
			information: '',
			role: jobId,
			candidateApplicationId: candidateId,
		}),
		[jobId, candidateId],
	);

	if (
		loading?.getAtsUserInterviewLoad ||
		loading?.getAtsUsersLoad ||
		loading?.getAtsActiveJobsLoad ||
		!preparedToRenderForm ||
		loading?.getClientApplicationNotificationLoad
	) {
		return <Spinner fixed />;
	}

	const defaultValues = {
		sendCandidateSmsReminder: clientApplicationNotification?.interviewReminder || false,
	};

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				decorators={[formDecorator]}
				mutators={{
					...arrayMutators,
				}}
				render={({ handleSubmit, dirty, values, submitting }) => (
					<form onSubmit={handleSubmit}>
						<Details
							jobs={activeJobs}
							values={values}
							defaultValues={defaultValues}
							interviewTypes={interviewTypes}
							candidates={candidateApplicationData}
							curCandidate={currentCandidate}
							onChangeJob={handleChangeJob}
							onChangeCandidate={handleSetCurrentCandidate}
						/>

						<Guests
							options={guestsOptions}
							user={user}
							selectedOptions={selectedOptions}
							curCandidate={currentCandidate}
							onAddGuest={handleAddGuests}
							onChangeCheckbox={handleChangeGuests}
							onDeleteGuest={handleDeleteGuest}
						/>

						<Styled.ButtonBox>
							<ThemedButton
								type='button'
								buttonType={ButtonTypes.secondary}
								onClick={() => handleCancelForm()}
							>
								Cancel
							</ThemedButton>
							<ThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								disabled={!dirty || submitting || !!loading?.saveAtsInterviewLoad}
								loading={submitting || !!loading?.saveAtsInterviewLoad}
							>
								Send invite
							</ThemedButton>
						</Styled.ButtonBox>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		user: unregisteredDucks.unregisteredSelectors.getUser(state),
		candidateApplicationData: atsDucks.atsSelectors.getCandidateApplicationData(state),
		activeJobs: atsDucks.atsSelectors.getActiveJobsData(state),
		interviewTypes: atsDucks.atsSelectors.getInterviewTypes(state),
		interviewUsers: atsDucks.atsSelectors.getInterviewUsers(state),
		clientApplicationNotification: atsDucks.atsSelectors.getClientApplicationNotification(state),
		interviewVideoCount: atsDucks.atsSelectors.getInterviewVideoCount(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsActiveJobs: atsDucks.atsActions.getAtsActiveJobsRequested,
		getAtsInterviewType: atsDucks.atsActions.getAtsInterviewTypeRequested,
		getAtsUserInterview: atsDucks.atsActions.getAtsUserInterviewRequested,
		getCandidateApplicationById: atsDucks.atsActions.getAtsCandidatesApplicationByJobIdRequested,
		getClientApplicationNotification: atsDucks.atsActions.getClientApplicationNotification,
		saveAtsInterview: atsDucks.atsActions.saveAtsInterviewRequested,
		getVideoInterviewCount: atsDucks.atsActions.getAtsVideoInterviewCountRequested,
		resetInterviewCount: atsDucks.atsActions.resetInterviewCount,
	},
)(ArrangeInterviewCreate);
