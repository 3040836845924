export const paletteColors = {
	green: '#52C41A',
	green2: '#16A34A',
	green3: '#2dc768',
	lightGreen: '#DCFCE7',
	red: '#FF4D4F',
	lightRed: '#FFCCCB',
	orange: '#FAAD14',
	lightOrange: '#FFD8B2',
	darkOrange: '#C95B0C',
	yellow: '#F2C94C',
	yellow2: '#CA8A04',
	lightYellow: '#FEF9C3',
	mintGreen: '#3EEBD0',
	blue: '#314EFF',
	blue1: '#001DCC',
	blue2: '#3B82F6',
	blue3: '#93C5FD',
	blue4: '#2563EB',
	blue5: '#E8F6FE',
	blue6: '#1890ff',
	darkBlue: '#262834',
	lightBlue: '#99A7FF33',
	lightBlue1: '#99A7FF',
	lightBlue2: '#7A8DFF',
	lightBlue3: '#f8f9fc',
	lightBlue4: '#d4daed',
	lightBlue5: '#EFF6FF',
	lightBlue6: '#DBEAFE',
	lightBlue7: '#F5F6FF',
	darkBlue1: '#001699',
	darkBlue2: '#212B3C',
	purple: '#9B51E0',
	black: '#1E1E20',
	darkGray7: '#222222',
	darkGray6: '#475569',
	darkGray5: '#54545D',
	darkGray4: '#2A2A2C',
	darkGray3: '#39393B',
	darkGray2: '#5C5C60',
	darkGray1: '#9D9DA1',
	gray: '#C4C4C8',
	neutral5: '#D9D9D9',
	lightGray1: '#DEDDE1',
	lightGray2: '#EBEBED',
	lightGray3: '#F8F8F8',
	lightGray4: '#E4E8ED',
	lightGray5: '#F1F3F4',
	neutral2: '#FAFAFA',
	white: '#FFFFFF',
} as const;

export const typographyColors = {
	iconColor: '#9BA4C2',
	badgeColor: '#F03F3F',
	inputColor: '#4096ff',
	bluePulse: 'rgba(49,78,255,0.2)',
	blueOverlay: 'rgba(49, 78, 255, 0.1)',
	settingsIconColor: 'rgba(153, 167, 255, 0.1)',
	settingsItemColor: 'rgba(153, 167, 255, 0.2)',
	labelColor: 'rgba(153, 167, 255, 0.4)',
	elevationShadow: '0 4px 12px rgba(99, 111, 122, 0.16)',
	elevationShadow1: '0px 2px 4px rgba(99, 111, 122, 0.12)',
	elevationAntInputShadow: '0 0 0 2px rgba(5, 145, 255, 0.1)',
	borderColor: '#EBEBED',
} as const;

export default { ...paletteColors, ...typographyColors };
