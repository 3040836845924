import React, { memo } from 'react';

import { Button, Tooltip } from 'antd';

import InfoIcon from 'components/SVG/InfoIcon';

import { Styled } from '../EmailTemplates.styled';

const AutoFilledInfo = () => {
	return (
		<Styled.InfoBlock>
			<h4>Auto-filled Data</h4>
			<Tooltip
				placement='bottomLeft'
				title={
					<>
						To ensure seamless autofill in your sent emails, please enter the following data exactly
						as shown:
						<ul>
							<li>
								<b>[Candidate Name]</b>: Full name of the candidate.
							</li>
							<li>
								<b>[Company Name]</b>: Name of the company as displayed in Settings → Company
								Details.
							</li>
							<li>
								<b>[Role Name]</b>: Job title selected when creating an email in Messages → Create
								New
							</li>
							<li>
								<b>[Your Name]</b>: Your first and last name.
							</li>
							<li>
								<br />
								<b>Important Reminder</b>: Only the data enclosed in brackets {'"[ ]"'} listed above
								will be auto-filled. <br />
								Be sure to change other data before sending email.
							</li>
						</ul>
					</>
				}
				trigger={['click']}
			>
				<Button type='ghost' size='small'>
					<InfoIcon width='20px' height='20px' />
				</Button>
			</Tooltip>
		</Styled.InfoBlock>
	);
};

export default memo(AutoFilledInfo);
