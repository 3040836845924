export const enum Routes {
	Main = '/',
	BackOffice = '/back-office',
	ATS = '/ats',
	HRModule = '/hr-module',
	CareerPage = '/careers',
	CareerJobPage = '/careers/jobs',
	CareerJobAssessment = '/careers/jobs/:jobId/assessment',
	DeclineInterview = '/decline-interview',
	Login = '/login',
	FinishRegistration = '/finish-registration',
	TwoFA = '/twoFA',
	ForgotPass = '/forgot-password',
	ResetPass = '/reset-password',
	VideoQuestionnaire = '/video-questionnaire',
	ClientContext = '/client-context',
	BOClientContext = '/back-office/client-context',
	Dashboard = '/dashboard',
	Clients = '/clients',
	ClientCreate = '/clients/create-client',
	EditClient = '/clients/edit-client',
	CreateClientBranding = '/clients/create-client-branding',
	EditClientBranding = '/clients/edit-client-branding',
	SupportTickets = '/support-tickets',
	Jobs = '/vacancies',
	PostJob = '/vacancies/post-job',
	CreateJob = '/vacancies/create-job',
	EditJob = '/vacancies/edit-job',
	PostJobCopy = '/vacancies/post-job/copy',
	PostJobBoost = '/vacancies/post-job/boost',
	PostJobEdit = '/vacancies/post-job/edit',
	JobView = '/vacancies/view-job',
	GravityJob = '/vacancies/gravity-job',
	Questionnaires = '/questionnaires',
	QuestionnairesCreate = '/questionnaires/create',
	QuestionnairesEdit = '/questionnaires/edit',
	QuestionnairesCopy = '/questionnaires/copy',
	QuestionnairesSend = '/questionnaires/send',
	Contracts = '/contracts',
	ContractsCreate = '/contracts/create',
	ContractsView = '/contracts/view',
	ContractsEdit = '/contracts/edit',
	Invoices = '/invoices',
	InvoicesView = '/invoices/view',
	InvoiceEdit = '/invoices/edit',
	InvoicesCreate = '/invoices/create',
	InvoiceForward = '/invoice/forward',
	Rates = '/rates',
	Users = '/users',
	Create = '/create',
	Edit = '/edit',
	Candidates = '/candidates',
	AppliedJobsCandidate = '/candidates/applied-jobs',
	CandidatesSendSMS = '/candidates/send-sms',
	CandidatesDatabase = '/candidates/database',
	RemoveCandidatesFromDatabase = '/candidate-database',
	Management = '/management',
	Interview = '/interview',
	Interviews = '/interviews',
	ArrangeInterview = '/interviews/arrange-interview',
	ArrangeInterviewCreate = '/interviews/arrange-interview/create',
	ArrangeInterviewEdit = '/interviews/arrange-interview/edit',
	InterviewRecording = '/interviews/recording',
	Messages = '/messages',
	Credits = '/credits',
	SupportCenter = '/support-center',
	SupportCenterTicketCreate = '/support-center/create',
	Finance = '/finance',
	UserProfile = '/user-profile',
	Settings = '/settings',
	ErrorPage = '/error-page',
	NotFound = '/404',
	Unauthorised = '/unauthorised',
	Forbidden = '/forbidden',
	ServerError = '/server-error',
	CreateSupportTicket = '/support-tickets/create',
	EditSupportTicket = '/support-tickets/edit',
	BuyVideoCredits = '/buy-video-credits',
	BuySMSCredits = '/buy-sms-credits',
	Statistic = '/statistic',
	CreateMessage = '/messages/create',
	EmailReply = '/email-reply',
}

export const enum SettingsRoutes {
	CompanyDetails = '/ats/settings/company-details',
	Subscription = '/ats/settings/subscription',
	Branding = '/ats/settings/branding',
	SocialNetworks = '/ats/settings/social-networks',
	EmailNotifications = '/ats/settings/email-notifications',
	EmailTemplates = '/ats/settings/email-templates',
	SMSReminders = '/ats/settings/sms-reminders',
	EmailSignature = '/ats/settings/email-signature',
	DefaultMessages = '/ats/settings/default-messages',
	Authentication = '/ats/settings/authentication',
	AutoPop = '/ats/settings/auto-pop',
	ChangePassword = '/ats/settings/change-password',
	UserAccess = '/ats/settings/user-access',
	UserAccessCreate = '/ats/settings/user-access/create',
	UserAccessEdit = '/ats/settings/user-access/edit',
	PersonalData = '/ats/settings/personal-data',
}

export const enum BackOfficeSettingsRoutes {
	CreditPrices = '/back-office/settings/credit-prices',
	EmailTemplates = '/back-office/settings/email-templates',
	ChangePassword = '/back-office/settings/change-password',
	UserAccess = '/back-office/settings/user-access',
	UserAccessCreate = '/back-office/settings/user-access/create',
	UserAccessEdit = '/back-office/settings/user-access/edit',
}
