import React, { type FC, useCallback, useState, useMemo, useEffect } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { TablePaginationConfig, SorterResult } from 'antd/lib/table/interface';

import Table from 'components/Table';
import { useMount, useUnmount } from 'hooks';
import ReceiptModal from 'modules/Common/components/ReceiptModal';
import SearchFilterSortWrapper from 'modules/Common/components/SearchFilterSortWrapper';
import { commonDucks } from 'modules/Common/ducks';
import { IContractState, IContractValues, ReceiptDataType } from 'modules/Common/types';
import { unregisteredDucks } from 'modules/Unregistered/ducks';
import { CountriesType, GenericType, Routes, UserRolesType, UserTypesValueEnum } from 'types';
import { propsFilter, getDateString } from 'utils/helpers';

import { ContractFields } from './Contracts.constants';
import { Styled } from './Contracts.styled';
import { columns } from './ContractsTable.entities';

type ContractsProps = {
	loading?: GenericType;
	clientId?: string;
	userId: number;
	roles: UserRolesType;
	isClientContext: boolean;
	contracts: {
		pageSize: number;
		pageIndex: number;
		totalCount: number;
		data: IContractValues[];
	};
	contractStatuses: IContractState[];
	contractReceipt: ReceiptDataType;
	creditsFields: GenericType[];
	countries: CountriesType;
	getContractReceipt: (
		id: number,
		isInvoice?: boolean,
		platform?: UserTypesValueEnum,
		callback?: () => void,
	) => void;
	getCreditsRequested: () => void;
	getContracts: ({ page, size, client }: { page?: number; size?: number; client?: string }) => void;
	resetContractsState: () => void;
	getContractStatuses: () => void;
	updateContractStatus: (
		contractId: number,
		data: { contractState: number },
		params?: { page?: number; size?: number; client?: string },
		platform?: UserTypesValueEnum,
	) => void;
	approvePendingContract: (
		contractId: number,
		params: { page?: number; size?: number; client?: string },
		cb: () => void,
		platform?: UserTypesValueEnum,
	) => void;
	deleteContractByIdRequested: (id: number, cb?: () => void) => void;
};

const Contracts: FC<ContractsProps> = ({
	loading,
	roles,
	contracts,
	clientId = '',
	userId,
	contractStatuses,
	contractReceipt,
	creditsFields,
	countries,
	getContractReceipt,
	resetContractsState,
	getCreditsRequested,
	getContracts,
	getContractStatuses,
	updateContractStatus,
	approvePendingContract,
	deleteContractByIdRequested,
	isClientContext,
}) => {
	const [openModal, setOpenModal] = useState(false);
	const [buttonDisabledList, setButtonDisabledList] = useState<number[]>([]);
	const [tableParams, setTableParams] = useState({});

	const navigate = useNavigate();

	const { pageIndex, pageSize, totalCount } = contracts || {};

	const handleChangeStatus = useCallback((contractId: number, statusId: number) => {
		updateContractStatus(
			contractId,
			{ contractState: statusId },
			{ page: pageIndex as number, size: pageSize as number, client: clientId },
		);
	}, []);

	const handleViewItem = useCallback(
		async (id: number) => {
			id && getContractReceipt(id, false, UserTypesValueEnum.BACKOFFICE, () => setOpenModal(true));
		},
		[contractReceipt],
	);

	const handleEditItem = useCallback(async (contractId: number) => {
		const path = clientId
			? `${Routes.BOClientContext}/${clientId}${Routes.ContractsEdit}/${contractId}`
			: `${Routes.BackOffice}${Routes.ContractsEdit}/${contractId}`;
		contractId && navigate(path);
	}, []);

	const handlePendingContractApproval = useCallback(
		(contractId: number) => {
			setButtonDisabledList((prev) => [...prev, contractId]);
			approvePendingContract(
				contractId,
				{ page: pageIndex as number, size: pageSize as number, client: clientId },
				() => setButtonDisabledList(buttonDisabledList.filter((id) => id !== contractId)),
			);
		},
		[approvePendingContract],
	);

	const handleDeleteItem = useCallback((id: number) => {
		const refresh = () =>
			getContracts({ page: pageIndex as number, size: pageSize as number, client: clientId });
		deleteContractByIdRequested(id, refresh);
	}, []);

	const sortedFilteredData = useMemo(() => {
		const filteredData = propsFilter(contracts?.data, ContractFields);

		return filteredData;
	}, [contracts?.data, ContractFields]);

	const columnsData = columns(
		clientId,
		contractStatuses,
		buttonDisabledList,
		handleChangeStatus,
		handleViewItem,
		handleEditItem,
		handlePendingContractApproval,
		handleDeleteItem,
		roles,
		userId,
		isClientContext,
	);

	const handleCancel = () => setOpenModal(false);

	useMount(() => {
		getContractStatuses();
		getContracts({ page: pageIndex as number, size: pageSize as number, client: clientId });
		getCreditsRequested();
	});

	useUnmount(() => {
		resetContractsState();
	});

	useEffect(() => {
		const ordersMap = {
			ascend: 'asc',
			descend: 'desc',
		};

		const fieldsMap = {
			startDate: 'startDate',
			clientName: 'client',
			accepted: 'state',
		};

		getContracts({
			page: tableParams?.page || 0,
			size: tableParams?.size || pageSize,
			...(clientId && { client: clientId }),
			...(tableParams?.search && { search: tableParams?.search }),
			...(tableParams?.state && {
				state: tableParams?.state,
			}),
			...(tableParams?.dateFilter &&
				tableParams?.dateFilter[0] &&
				tableParams?.dateFilter[1] && {
				fromDate: getDateString(tableParams?.dateFilter[0]),
				toDate: getDateString(tableParams?.dateFilter[1]),
			}),
			...(tableParams?.sorter?.order &&
				tableParams?.sorter?.field && {
				sort: `${fieldsMap[tableParams?.sorter?.field]},${
					ordersMap[tableParams?.sorter?.order || 'ascend']
				}`,
			}),
		});
	}, [tableParams, clientId]);

	const handleTableChange = (
		pagination: TablePaginationConfig,
		filters: unknown,
		sorter: SorterResult<unknown> | SorterResult<unknown>[],
	) => {
		setTableParams({
			...tableParams,
			page: 1,
			sorter,
		});
	};

	const handleSearch = (value: string) => {
		setTableParams({ ...tableParams, page: 1, search: value });
	};

	const handleTablePaginationChange = useCallback(
		(page: number, size: number) => {
			setTableParams({
				...tableParams,
				page,
				size,
			});
		},
		[tableParams],
	);

	const handleFilter = (value) => {
		setTableParams({ ...tableParams, page: 1, state: value });
	};

	const onDateFilter = (value) => {
		setTableParams({ ...tableParams, page: 1, dateFilter: value });
	};

	return (
		<Styled.Root>
			<Styled.BoxWrap isBoxComponent={!clientId}>
				<SearchFilterSortWrapper
					search={{
						onSearch: handleSearch,
						isDebounce: false,
						placeholder: isClientContext ? 'Search a contract' : 'Search a contract or client',
					}}
					filter={{
						mode: 'single',
						options: [
							{
								label: 'Pending Approval',
								value: 'PENDING_APPROVAL',
							},
							{
								label: 'Outstanding',
								value: 'OUTSTANDING',
							},
							{
								label: 'Accepted',
								value: 'ACCEPTED',
							},
							{
								label: 'Fulfilled',
								value: 'FULFILLED',
							},
						],
						onFilter: handleFilter,
						label: 'Status',
						placeholder: 'Filter',
					}}
					dateFilter={{
						label: 'Date Period',
						placeholder: 'Select date period',
						onDateFilter,
					}}
				/>
				<br />
				{sortedFilteredData && (
					<Table
						data={sortedFilteredData}
						columns={columnsData}
						onTableChange={handleTableChange}
						loading={loading?.getContractStatusesLoad || loading?.getContractsLoad}
						pageSize={pageSize as number}
						current={pageIndex as number}
						total={totalCount as number}
						onChange={handleTablePaginationChange}
					/>
				)}
			</Styled.BoxWrap>
			<ReceiptModal
				open={!loading?.getContractReceiptLoad && openModal}
				content={contractReceipt}
				creditsFields={creditsFields}
				countries={countries}
				onCancel={handleCancel}
				rootClassName='receipt-modal'
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loading: commonDucks.commonSelectors.commonLoading(state),
		userId: unregisteredDucks.unregisteredSelectors.getUser(state)?.id,
		contractsState: commonDucks.commonSelectors.getContractsState(state),
		contracts: commonDucks.commonSelectors.getContracts(state),
		contractStatuses: commonDucks.commonSelectors.getContractStatusesShort(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		contractReceipt: commonDucks.commonSelectors.getContractReceipt(state),
		roles: unregisteredDucks.unregisteredSelectors.getUserRoles(state),
		countries: unregisteredDucks.unregisteredSelectors.getCountries(state),
	}),
	{
		getContracts: commonDucks.commonActions.getContractsRequested,
		resetContractsState: commonDucks.commonActions.resetContractsState,
		getContractStatuses: commonDucks.commonActions.getContractStatusesRequested,
		getCreditsRequested: commonDucks.commonActions.getCreditsRequested,
		getContractReceipt: commonDucks.commonActions.getContractReceiptRequested,
		updateContractStatus: commonDucks.commonActions.updateContractStatusRequested,
		approvePendingContract: commonDucks.commonActions.approvePendingContractRequested,
		deleteContractByIdRequested: commonDucks.commonActions.deleteContractByIdRequested,
	},
)(Contracts);
