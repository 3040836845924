import { isValidPhoneNumber } from 'react-phone-number-input';

import { isNumber } from 'lodash';

import { MESSAGES } from 'modules/Common/constants';

const emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

const smsRegExp = /^[A-Za-z0-9 @#%^&*()_+\-=[\]{}:;"'<>?!,.\\|/£€$\n\r]+$/;

export const textWithoutNewLinesTabsAndCarriageReturnRegExp = /[^\r\n\t]/g;

export const phoneNumberIsValid = (value) => !!value && isValidPhoneNumber(value);

export const trimSpaces = (value) => {
	if (value && typeof value === 'string') return value.trim();

	return value;
};

export const emailValidator = (value) =>
	trimSpaces(value)?.match(emailRegExp) ? undefined : MESSAGES.validateEmailMessage;

export const multipleEmailValidator = (value, isDisabled = false) => {
	if (isDisabled) {
		return undefined;
	}
	const emails = value?.split(',');

	const invalidEmails = emails?.filter((email) => !trimSpaces(email).match(emailRegExp));

	if (invalidEmails?.length) {
		return MESSAGES.validateEmailMessage;
	}

	return undefined;
};

export const smsValidator = (value) =>
	trimSpaces(value)?.match(smsRegExp) ? undefined : MESSAGES.validateSMSMessage;

export const phoneValidator = (value) =>
	!value || isValidPhoneNumber(value) ? undefined : MESSAGES.validatePhoneMessage;

export const passwordValidator = (value) => {
	const whitespaceChecker = (v) => !/^\s|\s$/.test(v);
	const lengthChecker = (v) => v?.length > 7;
	const uppercaseLettersChecker = (v) => /[A-Z]/.test(v);
	const lowercaseLettersChecker = (v) => /[a-z]/.test(v);
	const specCharsChecker = (v) => /[!;#$%&@'*+\-/=?^_`{|}~]/.test(v);

	const isValid =
		whitespaceChecker(value) &&
		lengthChecker(value) &&
		uppercaseLettersChecker(value) &&
		lowercaseLettersChecker(value) &&
		specCharsChecker(value);

	if (value && !isValid) {
		return MESSAGES.weakPassword;
	}

	return undefined;
};

export const repeatPasswordValidator = (password, repeatPassword) => {
	if (password !== repeatPassword) {
		return MESSAGES.passwordNotMatch;
	}

	return undefined;
};

export const textValidator = (value) => {
	const errorMessage = 'Please enter a valid value for this field';

	const lengthChecker = (v) => !(v.length >= 1);

	if (value && lengthChecker(value)) {
		return errorMessage;
	}

	return undefined;
};

export const textMaxLengthValidator = (value, maxLength = 255) => {
	const allowedLength = value?.length >= 1 && value?.length <= maxLength;

	if (value && !allowedLength) {
		return MESSAGES.textFieldValidation;
	}

	return undefined;
};

export const editorFieldValidator = (value) => {
	if (value) {
		const parseValue = JSON.parse(value);
		const isNotEmpty = !parseValue?.blocks?.some((i) => i.text !== '');

		if (isNotEmpty) {
			return MESSAGES.mandatoryField;
		}
	}

	return undefined;
};

export const numberValidator = (value) => {
	if (value && !/^[0-9/.]+$/.test(String(value))) {
		return 'Please enter a valid value for this field. Negative values are not allowed';
	}

	return undefined;
};

export const positiveIntegersValidator = (value) => {
	const numberedValue = +value;

	if (!numberedValue || !isNumber(numberedValue) || numberedValue < 1) {
		return 'Please enter a value greater than 0';
	}

	return undefined;
};

export const lettersValidator = (value) => {
	if (value && !/^[a-zA-Z]+$/.test(String(value))) {
		return 'Please enter a valid value for this field. Only letters allowed';
	}

	return undefined;
};

export const lengthValidator = (minLength, maxLength, regexPattern) => (value) => {
	const length = regexPattern ? value?.match(regexPattern)?.length || 0 : value?.length || 0;

	// Case 1: Exact length validation (only minLength is provided and maxLength is undefined)
	if (maxLength === undefined) {
		if (length !== minLength) {
			return `String have to contains ${minLength} chars`;
		}
	} else {
		// Case 2: Range validation (both minLength and maxLength are provided)
		if (length < minLength) {
			return `String must be at least ${minLength} chars`;
		}
		if (length > maxLength) {
			return `String must be no more than ${maxLength} chars`;
		}
	}

	return undefined;
};

export const requiredValidator = (value) => (value || value === 0 ? undefined : 'Required');

export const requiredFieldValidator = (value) =>
	trimSpaces(value) ? undefined : MESSAGES.mandatoryField;

export const requiredFieldArrayValidator = (value) =>
	value?.length ? undefined : MESSAGES.mandatoryField;

export const requiredValidateAssessmentAnswers = (values, autoreject) => {
	const validateCorrectData = values?.filter((v) => v.correct === true);

	if (values?.length <= 1) return 'Please add at least 2 possible answers';

	const hasCorrectAnswer = values?.some((v) => v.correct === true);

	if (autoreject && !hasCorrectAnswer) return 'Please select at least one right answer';

	if (!autoreject && !validateCorrectData?.length) return undefined;

	return validateCorrectData?.length ? undefined : 'Please select at least one right answer';
};

export const composeValidators = (...validators) => {
	return (value) => {
		return validators.reduce((error, validator) => error || validator(value), undefined);
	};
};

export const validateOptionalField = (validator) => (value) => {
	const trimmedValue = trimSpaces(value);
	if (!trimmedValue) return undefined;

	return validator(value);
};
