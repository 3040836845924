import styled from 'styled-components/macro';

import { COLORS } from 'theme';

export namespace Styled {
	export const UnPersonateContainer = styled.div`
		width: 100%;
        height: 72px;
        position: relative;
	`;

	export const UnPersonateContainerInner = styled.div`
        background-color: ${COLORS.white};
		padding: 16px;
		font-size: 16px;
		display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        height: 72px;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 10;
    `;

	export const UnPersonateContainerMessage = styled.div`
		padding: 0 16px;
		text-align: center;
	`;
}
