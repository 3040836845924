import { Link } from 'react-router-dom';

import { Select as SelectComponent, Tag as TagComponent, Button as AntButton } from 'antd';
import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

const { Option } = SelectComponent;

interface IStyledProps {
	compactView: boolean;
}

export namespace Styled {
	export const Root = styled.section`
		position: relative;
	`;

	export const Head = styled.section`
		display: flex;
		justify-content: space-between;
	`;

	export const ActionPanel = styled.div<IStyledProps>`
		margin: ${({ compactView }) => (compactView ? '-56px 0 0' : '0 0 24px')};
		float: right;
		display: flex;
		align-items: center;
	`;

	export const Title = styled.h3``;

	export const Content = styled.section`
		position: relative;

		.ant-tabs .ant-tabs-nav {
			text-transform: uppercase;

			&:before {
				display: none;
			}
		}
	`;

	export const ButtonBox = styled.div`
		position: absolute;
		right: 0;
		top: 4px;
		z-index: 1;
	`;

	export const TitleWrapper = styled.div`
		display: flex;
		align-items: center;
		text-overflow: ellipsis;
		overflow: hidden;
		gap: 8px;
	`;

	export const TitleList = styled.span`
		height: auto;
		padding: 0;
		line-height: 28px;
		font-weight: 500;
		font-size: 16px;
		color: ${COLORS.black};
		word-break: break-word;
		transition: color 0.2s ease;

		&:hover {
			color: ${COLORS.blue};
		}
	`;

	export const LabelNew = styled.span`
		display: inline-block;
		width: 39px;
		height: 20px;
		font-weight: 700;
		font-size: 10px;
		line-height: 18px;
		color: ${COLORS.lightBlue2};
		text-align: center;
		background: ${COLORS.settingsItemColor};
		border: 1px solid ${COLORS.labelColor};
		border-radius: 2px;
	`;

	export const TagBox = styled.div`
		display: flex;
		justify-content: center;
		align-items: center;
		span {
			width: 100%;
		}
	`;

	export const Button = styled(AntButton)`
		width: 100%;
		height: 36px;
		margin: 0;
		padding: 0;
		line-height: 34px;

		&:disabled {
			.ant-tag {
				background: white;
				border-color: ${COLORS.gray};
				color: ${COLORS.darkGray1};
				box-shadow: none;
				cursor: initial;
			}
		}
	`;

	export const Tag = styled(TagComponent)`
		&.ant-tag {
			width: 100%;
			min-width: 90px;
			height: 36px;
			font-size: 14px;
			font-weight: 600;
			line-height: 34px;
			text-align: center;
			background: none;
			border-style: solid;
			text-transform: lowercase;
			margin-inline-end: 0;
		}

		&.ant-tag:first-letter {
			text-transform: capitalize;
		}
	`;

	export const Select = styled(SelectComponent)`
		&.ant-select {
			.ant-select-selector {
				height: 36px;
				background-color: ${COLORS.lightGray3};
				border: 1 solid ${COLORS.lightGray2};
				border-radius: 4px;
				width: 155px;

				.ant-select-selection-item {
					font-size: 14px;
					font-weight: 700;
					line-height: 34px;
					color: ${COLORS.darkGray2};
				}
			}
		}
	`;

	export const ActionButtons = styled.div`
		display: flex;
		justify-content: center;
		gap: 8px;

		button {
			width: 28px;
			height: 28px;
		}
	`;

	export const SendButtons = styled.div`
		display: flex;
		justify-content: center;
		gap: 8px;
		width: 100%;
	`;

	export const SelectOption = styled(Option)``;

	export const Header = styled.div`
		margin-bottom: 24px;
	`;

	export const Main = styled.div`
		position: relative;
		display: flex;
		flex-direction: column;
		min-height: 400px;
		gap: 20px;
	`;

	export const CollapsePanel = styled.div``;

	export const JobBox = styled.div``;

	export const ShowButton = styled(ButtonComponent)`
		margin-left: auto;
		margin-right: auto;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		color: ${COLORS.darkGray3};
		transition: color 0.2s ease;

		&:hover {
			color: ${COLORS.darkGray1};
		}
	`;

	export const DownloadPopup = styled.div`
		position: relative;
	`;

	export const ModalBox = styled.div`
		position: relative;
	`;

	export const InfoBox = styled.div`
		text-align: center;

		p {
			font-size: 18px;
		}
	`;

	export const CreateButton = styled(Link)`
		position: absolute;
		top: -48px;
		right: 0;
	`;
}
