import React, { type FC, useRef, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { PDFDownloadLink } from '@react-pdf/renderer';
import { Select } from 'antd';

import Button from 'components/Button';
import { ButtonTypes } from 'components/Button/Button.types';
import { useMount } from 'hooks';
import { useScrollToTopOnMount } from 'hooks/useScrollToTopOnMount';
import { InvoiceStatusModal } from 'modules/BackOffice/containers/InvoiceStatusModal/InvoiceStatusModal';
import ViewInvoiceForm from 'modules/BackOffice/containers/ViewInvoiceForm';
import { backOfficeDucks } from 'modules/BackOffice/ducks';
import PageNavTitle from 'modules/Common/components/PageNavTitle';
import Tag from 'modules/Common/components/Tag';
import { FinancePdf } from 'modules/Common/containers/FinancePdf';
import { commonDucks } from 'modules/Common/ducks';
import {
	CreditsType,
	IInvoicesDetailedValues,
	IInvoicesState,
	InvoiceTypeValuesEnum,
	InvoicesStatusColors,
	InvoicesStatusEnum,
	InvoicesStatusStylesData,
	InvoicesStatusTypesFormEnum,
	InvoicesTypesValuesEnum,
	ReceiptTypesEnum,
} from 'modules/Common/types';
import { GenericType, IOption, Routes } from 'types';

import { Styled } from './ViewInvoicePage.styled';

type ViewInvoicePageProps = {
	loadingBO?: GenericType;
	currentInvoice: IInvoicesDetailedValues;
	creditsFields: CreditsType[];
	invoiceStatuses: IInvoicesState[];
	invoicePaymentMethods: IOption[];
	getInvoiceStatusRequested: () => void;
	getInvoicePaymentMethodsRequested: () => void;
	updateInvoiceStatusRequested: (
		invoiceId: number,
		values: { status: InvoicesStatusTypesFormEnum; datePaid?: string; paymentMethod?: number },
		callback?: () => void,
	) => void;
};

const { Option } = Select;

const ViewInvoicePage: FC<ViewInvoicePageProps> = ({
	loadingBO,
	currentInvoice,
	creditsFields,
	invoiceStatuses,
	invoicePaymentMethods,
	getInvoiceStatusRequested,
	getInvoicePaymentMethodsRequested,
	updateInvoiceStatusRequested,
}) => {
	const { clientId = '' } = useParams();
	const [selectedInvoiceId, setSelectedInvoiceId] = useState<null | number>(null);
	const anchorRef = useRef<HTMLDivElement>(null);

	useMount(() => {
		invoiceStatuses?.length || getInvoiceStatusRequested();
		invoicePaymentMethods?.length || getInvoicePaymentMethodsRequested();
	});

	useScrollToTopOnMount(anchorRef);

	const statusName = currentInvoice?.invoiceStatus?.name;
	const isPaid = currentInvoice?.invoiceStatus?.id === InvoicesStatusTypesFormEnum.Paid;
	const isSubscriptionBased =
		(currentInvoice?.invoiceType?.value as unknown as InvoiceTypeValuesEnum) ===
			InvoiceTypeValuesEnum.PremiumSubscritionManual ||
		(currentInvoice?.invoiceType?.value as unknown as InvoiceTypeValuesEnum) ===
			InvoiceTypeValuesEnum.PremiumSubscrition;
	const showTag = isPaid || isSubscriptionBased;
	const styles = statusName && InvoicesStatusStylesData[statusName];

	const handleChangeStatus = useCallback(
		async (statusId: number) => {
			if (InvoicesStatusTypesFormEnum.PaidManually === statusId) {
				handleStatusModalOpen();
			} else {
				updateInvoiceStatusRequested(currentInvoice?.id, { status: statusId });
			}
		},
		[currentInvoice?.id],
	);

	const handleStatusModalOpen = useCallback(() => {
		setSelectedInvoiceId(currentInvoice?.id);
	}, [currentInvoice?.id]);

	const handleStatusModalCancel = () => setSelectedInvoiceId(null);

	const backToInvoicesPath = clientId
		? `${Routes.BOClientContext}/${clientId}${Routes.Invoices}`
		: `${Routes.BackOffice}${Routes.Invoices}`;

	const pdfButtonHidden =
		currentInvoice?.invoiceType?.value === InvoicesTypesValuesEnum.SmsPurchase ||
		currentInvoice?.invoiceType?.value === InvoicesTypesValuesEnum.InterviewPurchase;

	return (
		<Styled.Root ref={anchorRef}>
			<Styled.Head>
				<PageNavTitle title={currentInvoice?.btoReference} navigationLink={backToInvoicesPath} />
				<Styled.ButtonWrap>
					{showTag ? (
						<Tag
							color={String(InvoicesStatusColors[statusName as InvoicesStatusEnum])}
							style={{
								height: '40px',
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
							}}
						>
							{InvoicesStatusEnum[statusName as InvoicesStatusEnum] === 'PaidManually'
								? 'Paid'
								: InvoicesStatusEnum[statusName]}
						</Tag>
					) : (
						<Styled.Select
							value={currentInvoice?.invoiceStatus?.id || null}
							placeholder='Choose ticket status'
							styles={styles}
							onChange={handleChangeStatus}
							disabled={!!loadingBO?.updateInvoiceStatusLoad}
						>
							{invoiceStatuses?.map((option: IOption) => (
								<Option key={option.id} value={option.id}>
									{option.name}
								</Option>
							))}
						</Styled.Select>
					)}
					{currentInvoice && creditsFields && !pdfButtonHidden && (
						<Styled.PdfButtonWrapper>
							<PDFDownloadLink
								document={
									<FinancePdf
										contract={currentInvoice}
										creditsFields={creditsFields}
										type={ReceiptTypesEnum.Invoice}
									/>
								}
								fileName={`${currentInvoice.btoReference}-invoice.pdf`}
							>
								{({ loading }) => {
									const buttonText = loading ? 'Loading document...' : 'Download PDF';

									return <Button buttonType={ButtonTypes.secondary}>{buttonText}</Button>;
								}}
							</PDFDownloadLink>
						</Styled.PdfButtonWrapper>
					)}
				</Styled.ButtonWrap>
			</Styled.Head>
			<ViewInvoiceForm clientContextId={clientId} />
			<InvoiceStatusModal
				invoiceId={selectedInvoiceId}
				onCancel={handleStatusModalCancel}
				invoicePaymentMethods={invoicePaymentMethods}
				updateInvoiceStatusRequested={updateInvoiceStatusRequested}
				loading={!!loadingBO?.updateInvoiceStatusLoad}
				rootClassName='invoice-status-modal'
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		loadingBO: backOfficeDucks.backOfficeSelectors.backOfficeLoading(state),
		currentInvoice: backOfficeDucks.backOfficeSelectors.getCurrentInvoice(state),
		creditsFields: commonDucks.commonSelectors.getCreditFields(state),
		invoiceStatuses: backOfficeDucks.backOfficeSelectors.getInvoiceStatusesForBO(state),
		invoicePaymentMethods: backOfficeDucks.backOfficeSelectors.getInvoicePaymentMethodsForBO(state),
	}),
	{
		getInvoiceStatusRequested: backOfficeDucks.backOfficeActions.getInvoiceStatusesRequested,
		getInvoicePaymentMethodsRequested:
			backOfficeDucks.backOfficeActions.getInvoicePaymentMethodsRequested,
		updateInvoiceStatusRequested: backOfficeDucks.backOfficeActions.updateInvoiceStatusRequested,
	},
)(ViewInvoicePage);
