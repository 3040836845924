import { Key } from 'react';

import { TagColoursType } from './common';

export enum InvoicesStatusEnum {
	Paid = 'Paid',
	Outstanding = 'Outstanding',
	Overdue = 'Overdue',
	PaidManually = 'PaidManually',
}

export enum InvoicesStatusColors {
	Paid = TagColoursType.Green,
	PaidManually = TagColoursType.Green,
	Outstanding = TagColoursType.Blue,
	Overdue = TagColoursType.Orange,
}

export enum InvoicesStatusValuesEnum {
	Paid = 'PAID',
	Outstanding = 'OUTSTANDING',
	Overdue = 'OVERDUE',
	PaidManually = 'PAID_MANUALLY',
}

export enum InvoicesStatusTypesFormEnum {
	Overdue = 1,
	Outstanding = 2,
	Paid = 3,
	PaidManually = 4,
}

export interface IInvoicesState {
	id: number;
	name: InvoicesStatusEnum;
	value: InvoicesStatusValuesEnum;
}

export enum InvoicesTypesEnum {
	ContractAccept = 'ContractAccept',
	CreditsPurchase = 'CreditsPurchase',
	Backoffice = 'Backoffice',
	PremiumSubscriptionManual = 'PremiumSubscriptionManual',
}

export enum InvoicesTypesValuesEnum {
	ContractAccept = 'CONTRACT_ACCEPT',
	CreditsPurchase = 'CREDITS_PURCHASE',
	Backoffice = 'BACKOFFICE',
	PremiumSubscrition = 'PREMIUM_SUBSCRIPTION',
	PremiumSubscritionManual = 'PREMIUM_SUBSCRIPTION_MANUAL',
	InterviewPurchase = 'INTERVIEW_PURCHASE',
	SmsPurchase = 'SMS_PURCHASE',
}

export interface IInvoicesTypes {
	id: number;
	name: InvoicesTypesEnum;
	value: InvoicesTypesValuesEnum;
}

export enum PaymentStatusEnum {
	Pending = 'Pending',
	Success = 'Success',
	Failed = 'Failed',
}

export enum PaymentStatusValuesEnum {
	Pending = 'PENDING',
	Success = 'SUCCESS',
	PreSuccess = 'PRE_SUCCESS',
	Failed = 'FAILED',
}

export interface IPaymentStatus {
	id: number;
	name: PaymentStatusEnum;
	value: PaymentStatusValuesEnum;
}

export enum PaymentMethodEnum {
	Card = 'Card',
}

export enum PaymentMethodValuesEnum {
	Card = 'CARD',
}

export interface IPaymentMethod {
	id: number;
	name: PaymentMethodEnum;
	value: PaymentMethodValuesEnum;
}

export enum InvoiceTypeEnum {
	ContractAccept = 'ContractAccept',
	CreditsPurchase = 'CreditsPurchase',
	PremiumSubscrition = 'PremiumSubscrition',
	PremiumSubscritionManual = 'PremiumSubscritionManual',
}

export enum InvoiceTypeValuesEnum {
	ContractAccept = 'CONTRACT_ACCEPT',
	CreditsPurchase = 'CREDITS_PURCHASE',
	PremiumSubscrition = 'PREMIUM_SUBSCRIPTION',
	PremiumSubscritionManual = 'PREMIUM_SUBSCRIPTION_MANUAL',
}

export interface IInvoiceType {
	id: number;
	name: InvoiceTypeEnum;
	value: InvoiceTypeValuesEnum;
}

export interface IInvoicesTableValues {
	key?: Key;
	id: number;
	clientName: string;
	contract: number;
	dateDue: string;
	currency: string;
	receipt?: string;
	premiumPrice?: string;
	status: InvoicesStatusEnum;
	btoReference?: string;
	paymentStatus: IPaymentStatus;
	value: string;
	invoiceType?: null | IInvoiceType;
	subscriptionPlanType: ISubsPlanType | string;
}

export interface ISubsPlanType {
	id: number;
	interval: 'MONTH' | 'YEAR';
	value: 'Annually' | 'Monthly';
	name: 'MONTHLY' | 'ANNUALLY';
	price: number;
	month: number;
}

export interface IContractObject {
	contractId: number;
	btoReference: string;
}

export interface IInvoicesDetailedValues {
	id: number;
	name: string;
	btoReference: string;
	poNumber: string;
	dateDue: string;
	datePaid?: string;
	dateCreation: string;
	clientCode: string;
	paymentTerm: number;
	paymentTermTimeUnit: string;
	paymentMethod: IPaymentMethod;
	paymentStatus: IPaymentStatus;
	invoiceStatus: IInvoicesState;
	invoiceDate: string;
	invoiceType: IInvoicesTypes;
	subscriptionPlanType?: ISubsPlanType;
	totalPrice: number;
	totalExVat: number;
	discountAmount?: number;
	vat: number;
	vatPrice: number;
	chargeVat: boolean;
	premiumMultisiteCount: number;
	premiumMultisitePrice: number;
	cvFilteringCount: number;
	cvFilteringPrice: number;
	guardianCount: number;
	guardianPrice: number;
	indeedCount: number;
	indeedPrice: number;
	premiumPrice: number;
	contractObj: IContractObject;
	clientName: string;
	clientId: number;
	isManualInvoice?: boolean;
	unitForMonths: { count: number; yearMonth: string }[];
	smsPrice?: number;
	smsCount?: number;
	interviewPrice?: number;
	interviewCount?: number;
	currency?: string;
	invoiceDetailsUrl?: string;
}

export interface IInvoicesValues {
	key?: Key;
	id: number;
}

export interface IInvoicesData {
	data: Partial<IInvoicesTableValues>[];
	pageIndex: number;
	pageSize: number;
	totalCount: number;
}

export const InvoicesStatusStylesData = {
	[InvoicesStatusEnum.Overdue]: {
		color: '#d46b08',
		background: '#fff7e6',
		borderColor: '#ffd591',
	},
	[InvoicesStatusEnum.Outstanding]: {
		color: '#0958d9',
		background: '#e6f4ff',
		borderColor: '#91caff',
	},
	[InvoicesStatusEnum.Paid]: {
		color: '#389e0d',
		background: '#f6ffed',
		borderColor: '#b7eb8f',
	},
	[InvoicesStatusEnum.PaidManually]: {
		color: '#389e0d',
		background: '#f6ffed',
		borderColor: '#b7eb8f',
	},
};
