import { createAsyncConstantsMap, createSyncConstantsMap } from 'utils/reduxHelpers';

export const createActionsTypes = (PREFIX) => {
	const asyncActions = createAsyncConstantsMap(PREFIX, [
		'GET_CONTRACTS',
		'GET_CONTRACT_STATUSES',
		'UPDATE_CONTRACT_STATUS',
		'APPROVE_PENDING_CONTRACT',
		'GET_CLIENT_BRANDING',
		'ACCEPT_CONTRACT_BY_ID',
		'GET_CONTRACTS_BY_CLIENT_ID',
		'GET_CONTRACT_BY_ID',
		'DELETE_CONTRACT_BY_ID',
		'GET_CONTRACT_RECEIPT',
		'GET_CONTRACT_VAT',
		'GET_CLIENT_BY_ID',
		'UPDATE_CLIENT',
		'GET_CREDITS',
		'GET_JOB_BY_ID',
		'UPDATE_APPLICATION_STATUS',
		'GET_OPEN_JOB_BY_ID',
		'SAVE_FREE_JOB',
		'SAVE_PAID_JOB',
		'UPDATE_FREE_JOB',
		'UPDATE_PAID_JOB',
		'GET_CLIENT_JOBS',
		'GET_CLIENT_USERS',
		'GET_CLIENT_COPY_JOBS',
		'GET_OPEN_CLIENT_JOBS',
		'APPLY_ON_JOB',
		'APPLY_ON_COMPANY',
		'GET_JOB_TYPES',
		'GET_JOB_ASSESSMENT',
		'GET_EMPLOYMENT_TYPES',
		'GET_EDUCATION_TYPES',
		'GET_EDUCATION_TYPES_BY_REGION_ID',
		'GET_INTERVIEW_OPEN',
		'RESET_EDUCATION_TYPES',
		'GET_JOB_INDUSTRIES',
		'DECLINE_INTERVIEW_OPEN',
		'GET_ALL_BO_QUESTIONNAIRES',
		'GET_ALL_ATS_QUESTIONNAIRES',
		'GET_QUESTIONNAIRE_BY_ID',
		'GET_QUESTIONNAIRE_USERS',
		'GET_COPY_QUESTIONNAIRES',
		'GET_SEND_QUESTIONNAIRES_LIST',
		'GET_QUESTIONNAIRE_DURATION',
		'SAVE_QUESTIONNAIRES',
		'SEND_CANDIDATE_QUESTIONNAIRE',
		'RESEND_CANDIDATE_QUESTIONNAIRE',
		'RECALL_CANDIDATE_QUESTIONNAIRE',
		'GET_CANDIDATE_BY_ID',
		'UPDATE_CANDIDATE_RANK',
		'GET_VIDEO_QUESTIONNAIRE_BY_CANDIDATE_ID',
		'GET_QUESTIONNAIRE_RESULT',
		'DELETE_QUESTIONNAIRE_BY_ID',
		'ARCHIVE_UNARCHIVE_QUESTIONNAIRE_BY_ID',
		'GET_TICKET_TYPES',
		'GET_TICKET_STATUSES',
		'GET_SMS_COUNT',
		'GET_SMS_ORDER_PRICE',
		'GET_VIDEO_INTERVIEW_ORDER_PRICE',
		'GET_EMAIL_AUTO_FILLED_DATA',
		'GET_EMAIL_DEFAULT_TEMPLATES',
		'UPDATE_EMAIL_DEFAULT_TEMPLATES',
		'GET_PRICES_BY_CLIENT_ID',
	]);

	const syncActions = createSyncConstantsMap(PREFIX, [
		'SET_JOB_APPLY_STATE',
		'RESET_JOB_STATE',
		'RESET_CONTRACTS_STATE',
		'SAVE_SEARCH_QUERY_PARAMS',
	]);

	return {
		...asyncActions,
		...syncActions,
	};
};
