import { combineReducers } from 'redux';

import ats from '../modules/ATS';
import backOffice from '../modules/BackOffice';
import common from '../modules/Common';
import unregistered from '../modules/Unregistered';

export const rootReducerObj = {
	unregisteredReducer: unregistered.reducer,
	backofficeReducer: backOffice.reducer,
	commonReducer: common.reducer,
	atsReducer: ats.reducer,
};

const rootReducer = combineReducers({
	unregisteredReducer: unregistered.reducer,
	backofficeReducer: backOffice.reducer,
	commonReducer: common.reducer,
	atsReducer: ats.reducer,
});

export default rootReducer;
