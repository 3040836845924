import React, { type FC, useState, useMemo, useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { InboxOutlined } from '@ant-design/icons';
import { Input, Select, Tooltip, UploadFile } from 'antd';
import { ContentState, EditorState } from 'draft-js';
import createDecorator from 'final-form-calculate';
import htmlToDraft from 'html-to-draftjs';
import { omit } from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import Spinner from 'components/Spinner';
import DownloadIcon from 'components/SVG/DownloadIcon';
import { useMount } from 'hooks';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import FieldEditorControlled from 'modules/Common/components/FieldEditorControlled';
import FormBlock from 'modules/Common/components/FormBlock';
import { acceptedTicketUploadFormFileFormat } from 'modules/Common/constants';
import AutoFilledInfo from 'modules/Common/containers/EmailTemplates/AutoFilledInfo';
import { FileUploadTypesEnum } from 'modules/Common/types';
import { getMultipleFileUploaderProps } from 'modules/Common/utils/brandingUploader';
import { convertContentFromHtml } from 'modules/Common/utils/editorHelper';
import { COLORS } from 'theme';
import { GenericType } from 'types';
import { convertFieldInputToHtml, handleFileDownload } from 'utils/helpers';
import {
	composeValidators,
	lengthValidator,
	requiredFieldArrayValidator,
	requiredFieldValidator,
} from 'utils/validators';

import { handleCreateEmailCallback } from './PostMessage.helpers';
import { Styled } from './PostMessage.styled';
import {
	CandidateApplication,
	CreateEmailResponseType,
	EmailFormValuesAllFieldsType,
	EmailFormValuesToSendType,
	EmailTemplateType,
} from './PostMessage.types';

type PostMessageProps = {
	candidateApplicationIds?: string[];
	jobsList: { id: number; title: string }[];
	candidateApplicationsList: CandidateApplication[];
	emailTemplates: EmailTemplateType[];
	atsLoading: GenericType;
	emailSignatureData: string;
	getClientEmailSignature: () => void;
	getAtsJobsListRequested: () => void;
	getAllCandidateApplicationByJobIdRequested: (jobId: number) => void;
	getAtsEmailTemplatesRequested: () => void;
	createEmailThreadRequested: (
		values: EmailFormValuesToSendType,
		callback: (response: CreateEmailResponseType) => void,
	) => void;
};

const PostMessage: FC<PostMessageProps> = ({
	candidateApplicationIds,
	jobsList,
	candidateApplicationsList,
	emailTemplates,
	atsLoading,
	emailSignatureData,
	getClientEmailSignature,
	getAtsJobsListRequested,
	getAllCandidateApplicationByJobIdRequested,
	getAtsEmailTemplatesRequested,
	createEmailThreadRequested,
}) => {
	const navigate = useNavigate();

	const { state } = useLocation();
	const [editorState, setEditorState] = useState(EditorState.createEmpty());
	const [fileList, setFileList] = useState<UploadFile[]>([]);
	const [formValidationError, setFormValidationError] = useState<boolean>(false);

	const emailSignatureValid = emailSignatureData && typeof emailSignatureData === 'string';

	const editorInitState = emailSignatureValid
		? convertContentFromHtml(emailSignatureData)
		: EditorState.createEmpty();
	const initialValues: Partial<EmailFormValuesToSendType> = useMemo(
		() => ({
			candidateApplicationIds:
				state?.candidateList.map((item: { name: string; id: number }) => item.id) || [],
			topic: '',
			content: editorInitState ?? '',
		}),
		[state, emailSignatureData],
	);

	const onSubmit = (values: EmailFormValuesAllFieldsType) => {
		if (!values.attachments?.length && !editorState.getCurrentContent().hasText())
			return setFormValidationError(true);

		const filteredFields = omit(values, ['job', 'template']);

		const valuesToSend = {
			...filteredFields,
			content: convertFieldInputToHtml(editorState),
		};

		createEmailThreadRequested(valuesToSend, (response) =>
			handleCreateEmailCallback(response, navigate),
		);
	};

	useMount(() => {
		!jobsList?.length && getAtsJobsListRequested();
		!emailTemplates?.length && getAtsEmailTemplatesRequested();
		getClientEmailSignature();
	});

	useEffect(() => {
		setFormValidationError(false);
	}, [fileList]);

	useEffect(() => {
		editorInitState && setEditorState(editorInitState);
	}, [emailSignatureData]);

	const draggerProps = ({
		onChange,
		value = [],
	}: {
		onChange: (fileIds: string[]) => void;
		value: string[];
	}) =>
		getMultipleFileUploaderProps(
			(url, responseData) => {
				onChange([...value, responseData]);
			},
			{
				fileList,
				accept: acceptedTicketUploadFormFileFormat,
				onRemove: (file: UploadFile) => {
					onChange([...value.filter((item) => item !== file?.response?.data)]);
				},
				onDownload: handleDownload,
				fileType: FileUploadTypesEnum.Email,
				maxCount: undefined,
			},
			setFileList,
		);

	const handleDownload = (file: UploadFile) => {
		const { name } = file;
		const uid = file?.response?.data;
		handleFileDownload(uid, name);
	};

	const handleEditorChange = (newState: EditorState) => {
		setEditorState(newState);
	};

	const submitButtonLoading = atsLoading?.createAtsEmailThreadLoad;

	const formDecorator = useMemo(
		() =>
			createDecorator(
				{
					field: 'job',
					updates: {
						candidateApplicationIds: (jobId) => {
							jobId && getAllCandidateApplicationByJobIdRequested(jobId);

							return [];
						},
					},
				},
				{
					field: 'template',
					updates: {
						content: (templateId, allValues) => {
							const templateContent = emailTemplates.find(
								(template) => template.id === templateId,
							)?.content;

							if (!templateContent) return;

							const blocksFromHTML = htmlToDraft(
								templateContent?.concat(emailSignatureValid ? emailSignatureData : ''),
							);

							const contentFieldState = ContentState.createFromBlockArray(
								blocksFromHTML.contentBlocks,
								blocksFromHTML.entityMap,
							);

							setEditorState(EditorState.createWithContent(contentFieldState));
						},
					},
				},
			),
		[emailTemplates, emailSignatureData],
	) as never;

	const receiversAreNotPreFilled = !state?.candidateList?.length;

	if (atsLoading.getClientEmailSignatureLoad) {
		return <Spinner fullWidth />;
	}

	return (
		<Styled.Root>
			<Form
				onSubmit={onSubmit}
				initialValues={initialValues}
				autoComplete='off'
				decorators={[formDecorator]}
				render={({ handleSubmit, values }) => (
					<form onSubmit={handleSubmit}>
						<FormBlock>
							<Styled.FormBlockContent>
								{receiversAreNotPreFilled ? (
									<Field name='job' validate={requiredFieldValidator}>
										{({ input, meta }) => (
											<FieldWrapper
												name='job'
												label='Job'
												required={receiversAreNotPreFilled}
												errorMessage={meta.submitFailed && meta.touched && meta.error}
											>
												<Select
													{...input}
													value={input.value || null}
													options={jobsList}
													placeholder='Select a Job'
													fieldNames={{ label: 'title', value: 'id' }}
													disabled={!!candidateApplicationIds?.length}
												/>
											</FieldWrapper>
										)}
									</Field>
								) : (
									<></>
								)}
								<Field name='candidateApplicationIds' validate={requiredFieldArrayValidator}>
									{({ input, meta }) => (
										<FieldWrapper
											name='candidateApplicationIds'
											label='Recipients'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Select
												{...input}
												placeholder='Enter recipients'
												mode='multiple'
												options={state?.candidateList || candidateApplicationsList}
												fieldNames={{ label: 'fullName', value: 'id' }}
												disabled={!receiversAreNotPreFilled}
												maxTagCount='responsive'
												maxTagPlaceholder={(omittedValues) => (
													<Tooltip title={omittedValues.map(({ label }) => label).join(', ')}>
														<span>+ Show Rest</span>
													</Tooltip>
												)}
											/>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FormBlockContent>
							<Styled.FormBlockContent>
								<Field
									name='topic'
									validate={composeValidators(lengthValidator(1, 255), requiredFieldValidator)}
								>
									{({ input, meta }) => (
										<FieldWrapper
											name='topic'
											label='Subject'
											required
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Input {...input} placeholder='Enter message subject' autoComplete='off' />
										</FieldWrapper>
									)}
								</Field>
								<Field name='template'>
									{({ input, meta }) => {
										return (
											<FieldWrapper
												name='template'
												label='Template'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												isFixed
											>
												<Select
													{...input}
													value={input.value || null}
													options={emailTemplates || []}
													placeholder='Select a Template'
													fieldNames={{ label: 'name', value: 'id' }}
												/>
											</FieldWrapper>
										);
									}}
								</Field>
							</Styled.FormBlockContent>
							<AutoFilledInfo />
							<Styled.FormBlockContent>
								<Field name='content'>
									{({ input, meta }) => {
										return (
											<FieldWrapper
												name='content'
												label='Message'
												errorMessage={meta.submitFailed && meta.touched && meta.error}
												isFixed
											>
												<>
													<FieldEditorControlled
														editorState={editorState}
														handleChange={handleEditorChange}
														defaultValue={input.value}
														placeholder='Enter your message'
														minRaws={20}
													/>
												</>
											</FieldWrapper>
										);
									}}
								</Field>
							</Styled.FormBlockContent>
							<Styled.FormBlockContent>
								<Field name='attachments'>
									{({ input, meta }) => (
										<FieldWrapper
											isFixed
											name='attachments'
											label='Attachments'
											errorMessage={meta.submitFailed && meta.touched && meta.error}
										>
											<Styled.Dragger
												{...draggerProps(input)}
												listType='text'
												onPreview={handleDownload}
												showUploadList={{
													showDownloadIcon: true,
													downloadIcon: <DownloadIcon fill={COLORS.darkGray2} />,
												}}
											>
												<p className='ant-upload-drag-icon'>
													<InboxOutlined />
												</p>
												<p className='ant-upload-text'>Upload a files or drag and drop it</p>
												<p className='ant-upload-hint'>
													{' '}
													PDF, DOC, DOCX, XLS, XLSX, CSV, JPEG, PNG up to 15MB{' '}
												</p>
											</Styled.Dragger>
										</FieldWrapper>
									)}
								</Field>
							</Styled.FormBlockContent>
							{formValidationError && (
								<Styled.ValidationError>
									You must provide either some content or an attachment.
								</Styled.ValidationError>
							)}
						</FormBlock>
						<Styled.ButtonBox>
							<ThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								loading={false}
								disabled={!!submitButtonLoading}
							>
								{'Send Message'}
							</ThemedButton>
						</Styled.ButtonBox>
					</form>
				)}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		jobsList: atsDucks.atsSelectors.getAllJobsListData(state),
		candidateApplicationsList: atsDucks.atsSelectors.getAllCandidateApplicationByJobData(state),
		emailTemplates: atsDucks.atsSelectors.getEmailTemplates(state),
		emailSignatureData: atsDucks.atsSelectors.getClientEmailSignature(state)?.emailSignatureHtml,
		atsLoading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsJobsListRequested: atsDucks.atsActions.getAtsAllJobsListRequested,
		getAllCandidateApplicationByJobIdRequested:
			atsDucks.atsActions.getAtsAllCandidateApplicationByJobIdRequested,
		getAtsEmailTemplatesRequested: atsDucks.atsActions.getAtsEmailTemplatesRequested,
		createEmailThreadRequested: atsDucks.atsActions.createAtsEmailThreadRequested,
		getClientEmailSignature: atsDucks.atsActions.getClientEmailSignatureRequested,
	},
)(PostMessage);
