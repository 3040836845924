import React, { Key } from 'react';

import { Space } from 'antd';

import { getJobCandidatesCsvBO } from 'api/endpoints/jobs';
import { ButtonTypes } from 'components/Button/Button.types';
import DownloadIcon from 'components/SVG/DownloadIcon';
import EditIcon from 'components/SVG/EditIcon';
import { ColumnsDataType } from 'components/Table/Table.types';
import { JobClassesEnum, JobDataType, PostJobTypeValuesSimpleEnum } from 'modules/Common/types';
import { COLORS } from 'theme';
import { DataFormatEnum, Routes } from 'types';
import { getDateFormat, downloadCsv } from 'utils/helpers';

import { Styled } from './JobsListContainer.styled';

type DataTableType = JobDataType & {
	key?: Key;
};

export const columns = (pageLink: string): ColumnsDataType => [
	{
		title: 'Client',
		dataIndex: 'client',
		key: 'client',
		render: (_: string, { client }: Partial<DataTableType>) => (
			// TODO Will be redirected with a separate view on the client details
			<Styled.PageLink to={`${pageLink}${Routes.Jobs}`}>{client}</Styled.PageLink>
		),
	},
	{
		title: 'Title',
		dataIndex: 'title',
		key: 'title',
		render: (_: string, { title, id }: Partial<DataTableType>) => (
			<Styled.PageLink to={`${pageLink}${Routes.Jobs}`} state={{ contractName: title }}>
				{title}
			</Styled.PageLink>
		),
	},
	{
		title: 'Method',
		dataIndex: 'method',
		key: 'method',
		render: (_: string, { method }: Partial<DataTableType>) => {
			return method;
		},
	},
	{
		title: 'Type',
		dataIndex: 'type',
		key: 'type',
		render: (_: string, { jobClass }: Partial<DataTableType>) => {
			const type =
				jobClass === JobClassesEnum.PaidJob
					? PostJobTypeValuesSimpleEnum.PaidJob
					: PostJobTypeValuesSimpleEnum.FreeJob;

			return <>{type}</>;
		},
	},
	{
		title: 'Questionnaires',
		dataIndex: 'questionnaires',
		key: 'questionnaires',
		align: 'center',
		render: (_: string, { questionnairesNumber }: Partial<DataTableType>) =>
			questionnairesNumber ?? 0,
	},
	{
		title: 'Interview',
		dataIndex: 'interview',
		key: 'interview',
		align: 'center',
		render: (_: string, { interviewsNumber }: Partial<DataTableType>) => interviewsNumber ?? 0,
	},
	{
		title: 'Applications',
		dataIndex: 'numberOfApplications',
		key: 'numberOfApplications',
		align: 'center',
		render: (_: string, { applicationsNumber }: Partial<DataTableType>) => (
			// TODO Will be redirected with a separate view on the application details
			<>
				{applicationsNumber ? (
					<Styled.PageLink to={`${pageLink}${Routes.Jobs}`} style={{ justifyContent: 'center' }}>
						<span>{applicationsNumber}</span>
					</Styled.PageLink>
				) : (
					0
				)}
			</>
		),
	},
	{
		title: 'Date Listed',
		dataIndex: 'startDate',
		key: 'startDate',
		align: 'center',
		render: (_: string, { startDate }: Partial<DataTableType>) => {
			const formattedData = getDateFormat(startDate, DataFormatEnum.Full) ?? '-';

			return <>{formattedData}</>;
		},
	},
	{
		title: 'Action',
		key: 'action',
		align: 'center',
		width: '176px',
		render: (_: string, { id }: Partial<DataTableType>) => (
			<Space size='middle'>
				<Styled.ButtonLink to={`${pageLink}${Routes.EditJob}/${id}`}>
					<Styled.Button buttonType={ButtonTypes.default} icon={<EditIcon fill={COLORS.black} />} />
				</Styled.ButtonLink>
				<Styled.Button
					buttonType={ButtonTypes.default}
					onClick={downloadCsv(() => getJobCandidatesCsvBO(id), `job-${id}-candidates`)}
					icon={<DownloadIcon fill={COLORS.black} width='16' height='16' />}
				>
					CSV
				</Styled.Button>
			</Space>
		),
	},
];
