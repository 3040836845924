import { Link as LinkComponent } from 'react-router-dom';

import styled from 'styled-components/macro';

import ButtonComponent from 'components/Button';
import { COLORS } from 'theme';

export namespace Styled {
	export const Root = styled.section`
		width: 100%;
		margin: 0;
		padding: 0;
	`;

	export const PageLink = styled(LinkComponent)`
		display: flex;
		line-height: 28px;
		min-width: 28px;
		font-weight: 500;
		color: ${COLORS.black};
		gap: 4px;

		span {
			color: ${COLORS.inputColor};
			text-decoration: underline;
			transition: all 0.2s ease;
		}

		&:hover,
		&:focus {
			span {
				text-decoration: none;
			}
		}
	`;

	export const Button = styled(ButtonComponent)`
		line-height: 28px;
		height: 28px;
		min-width: 28px;
		padding: 4px 8px;
		font-size: 14px;
		gap: 8px;

		span {
			display: flex;
			margin: 0;
		}
	`;

	export const ButtonLink = styled(LinkComponent)``;
	export const JLCRoot = styled.div`
		position: relative;
	`;
	export const JLCSearch = styled.div`
		position: absolute;
		top: 0;
		right: 0;
		width: 276px;
		z-index: 2;
	`;
}
