import { FC, useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { SearchOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import { debounce, has } from 'lodash';

import { ButtonTypes } from 'components/Button/Button.types';
import { useMount } from 'hooks';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import { atsDucks } from 'modules/ATS/ducks';
import { DEFAULT_CURRENT_PAGE, DEFAULT_PAGE_SIZE } from 'modules/Common/constants';
import { InboxEmailThreadsType, SentEmailThreadsType } from 'modules/Common/types';
import { GenericType, Routes } from 'types';

import { Styled } from './ATSMessagesList.styled';
import MessageTab from './MessageTab';

type ATSMessagesListProps = {
	emailThreads: InboxEmailThreadsType | SentEmailThreadsType;
	loading: GenericType;
	getAtsEmailInboxRequested: ({
		page,
		size,
		isInbox,
		search,
	}: {
		page: number;
		size: number;
		isInbox: boolean;
		search?: string;
	}) => void;
};

export const enum MessageTitlesEnum {
	Inbox = '1',
	Sent = '2',
}

const ATSMessagesList: FC<ATSMessagesListProps> = ({
	emailThreads,
	loading,
	getAtsEmailInboxRequested,
}) => {
	const navigate = useNavigate();
	const location = useLocation();
	const { activeTab } = location.state || {};
	const [tab, setTab] = useState<MessageTitlesEnum | string>(activeTab || MessageTitlesEnum.Inbox);
	const [search, setSearch] = useState<string>('');
	const [inboxUnreadMessagesCount, setInboxUnreadMessagesCount] = useState<number>(0);

	const handleChangeTabs = (id: MessageTitlesEnum | string) => {
		setTab(id);
	};

	const debouncedSearch = useCallback(
		debounce((searchTerm) => {
			getAtsEmailInboxRequested({
				page: DEFAULT_CURRENT_PAGE,
				size: DEFAULT_PAGE_SIZE,
				isInbox: tab === MessageTitlesEnum.Inbox,
				search: searchTerm,
			});
		}, 500),
		[tab],
	);

	const handleSearch = (value: string) => {
		setSearch(value);
		if (value.length >= 1 || value.length === 0) {
			debouncedSearch(value);
		}
	};

	const handlePaginationChange = useCallback(
		(page: number, size: number) => {
			getAtsEmailInboxRequested({
				page,
				size,
				isInbox: tab === MessageTitlesEnum.Inbox,
				search,
			});
		},
		[tab],
	);

	useEffect(() => {
		getAtsEmailInboxRequested({
			page: DEFAULT_CURRENT_PAGE,
			size: DEFAULT_PAGE_SIZE,
			isInbox: tab === MessageTitlesEnum.Inbox,
			search,
		});
	}, [tab]);

	useMount(() => {
		getAtsEmailInboxRequested({
			page: DEFAULT_CURRENT_PAGE,
			size: DEFAULT_PAGE_SIZE,
			isInbox: true,
		});
	});

	useEffect(() => {
		if (has(emailThreads, 'unreadMessagesCount')) {
			setInboxUnreadMessagesCount((emailThreads as InboxEmailThreadsType).unreadMessagesCount);
		}
	}, [emailThreads]);

	return (
		<Styled.Root>
			<Tabs
				activeKey={tab}
				onChange={handleChangeTabs}
				defaultActiveKey={tab}
				items={[
					{
						label: <>{`INBOX (${inboxUnreadMessagesCount})`}</>,
						key: MessageTitlesEnum.Inbox,
						active: true,
						children: (
							<MessageTab
								emailThreads={emailThreads}
								onChangePage={handlePaginationChange}
								searchTerm={search}
							/>
						),
					},
					{
						label: <>SENT</>,
						key: MessageTitlesEnum.Sent,
						children: (
							<MessageTab
								emailThreads={emailThreads}
								onChangePage={handlePaginationChange}
								searchTerm={search}
							/>
						),
					},
				]}
				tabBarStyle={{ fontFamily: 'Inter' }}
				tabBarExtraContent={
					<Styled.ExtraControls>
						<Styled.Input
							value={search}
							placeholder='Search a message'
							autoComplete='off'
							prefix={<SearchOutlined style={{ color: 'gray' }} />}
							onChange={(e) => handleSearch(e.target.value)}
							allowClear
						/>
						<ThemedButton
							buttonType={ButtonTypes.primary}
							onClick={() => navigate(`${Routes.ATS}${Routes.CreateMessage}`)}
						>
							New Message
						</ThemedButton>
					</Styled.ExtraControls>
				}
			/>
		</Styled.Root>
	);
};

export default connect(
	(state) => ({
		emailThreads: atsDucks.atsSelectors.getEmailInbox(state),
		loading: atsDucks.atsSelectors.getAtsLoading(state),
	}),
	{
		getAtsEmailInboxRequested: atsDucks.atsActions.getAtsEmailInboxRequested,
	},
)(ATSMessagesList);
