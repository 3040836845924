import React, { type FC, memo } from 'react';
import { Field, Form, FormSpy } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';

import { Input, Select } from 'antd';
import arrayMutators from 'final-form-arrays';
import Cookies from 'js-cookie';

import { ButtonTypes } from 'components/Button/Button.types';
import FieldWrapper from 'components/FieldWrapper';
import AlertIcon from 'components/SVG/AlertIcon';
import DeleteIcon from 'components/SVG/DeleteIcon';
import ThemedButton from 'modules/ATS/components/ThemedButton';
import FormBlock from 'modules/Common/components/FormBlock';
import { COLORS } from 'theme';
import { CurrencyType, Fields, CookiesType } from 'types';
import {
	composeValidators,
	positiveIntegersValidator,
	requiredFieldValidator,
} from 'utils/validators';

import { FormValuesType } from '../BuyVideoCredits/BuyVideoCredits.types';

import { calculateDateRange, getMonthOptions } from './BuyCreditsForm.helpers';
import { Styled } from './BuyCreditsForm.styled';

type BuyCreditsFormProps = {
	initialValues: FormValuesType;
	creditTypeLabel: string;
	unitLabel: string;
	bundleSize?: number;
	creditTypeNote?: string;
	addMonthButtonEnabled?: boolean;
	chargeVat: boolean;
	currency: CurrencyType;
	chargeVatPercent: number;
	submitButtonLoading: boolean;
	handleFormSubmit: (values: { unitForMonths: FormValuesType[] }) => void;
};

const { Option } = Select;

const BuyCreditsForm: FC<BuyCreditsFormProps> = ({
	initialValues,
	creditTypeLabel,
	unitLabel,
	bundleSize,
	creditTypeNote,
	addMonthButtonEnabled,
	chargeVat,
	currency,
	chargeVatPercent,
	submitButtonLoading,
	handleFormSubmit,
}) => {
	const monthOptions = getMonthOptions();
	const isImpersonate = !!Cookies.get(CookiesType.boImpersonateJWT);

	return (
		<Form
			onSubmit={handleFormSubmit}
			initialValues={{ unitForMonths: [initialValues] }}
			mutators={{ ...arrayMutators }}
			autoComplete='off'
			render={({ handleSubmit, form }) => (
				<form onSubmit={handleSubmit}>
					<FormBlock>
						<FieldArray name='unitForMonths'>
							{({ fields }) =>
								fields.map((name, index) => (
									<Styled.CreditsFieldWrapper key={name}>
										<Styled.MonthWrapper>
											<Field name={`${name}.yearMonth`} validate={requiredFieldValidator}>
												{({ input, meta }) => (
													<FieldWrapper
														name={`${name}.yearMonth`}
														label='Month'
														required
														errorMessage={meta.error && meta.touched && meta.error}
													>
														<Select
															{...input}
															placeholder='Choose month'
															onChange={(value) => {
																form.change(`unitForMonths.${index}.yearMonth`, value);
															}}
															disabled={!addMonthButtonEnabled}
														>
															{monthOptions.map((option) => (
																<Option
																	key={option.id}
																	value={option.id}
																	disabled={fields.value.some(
																		(month) => month.yearMonth === option.id,
																	)}
																>
																	{option.name}
																</Option>
															))}
														</Select>
														{input.value && (
															<Styled.UsageNote>
																<AlertIcon width={'16'} height={'16'} fill='black' />
																<span>{calculateDateRange(input.value)}</span>
															</Styled.UsageNote>
														)}
													</FieldWrapper>
												)}
											</Field>
										</Styled.MonthWrapper>
										<Styled.QuantityWrapper>
											<Field
												name={`${name}.count`}
												validate={positiveIntegersValidator}
												parse={(value) => Number(value)}
											>
												{({ input, meta }) => (
													<FieldWrapper
														name={`${name}.count`}
														label={creditTypeLabel}
														required
														errorMessage={meta.submitFailed && meta.touched && meta.error}
														isFixed
													>
														<Input
															{...input}
															type={Fields.NUMBER}
															placeholder='e.g. 10'
															minLength={0}
															min={0}
															autoComplete='off'
														/>
														<>
															{creditTypeNote && bundleSize && (
																<Styled.UsageNote>
																	<span>
																		{bundleSize * input.value} {creditTypeNote}
																	</span>
																</Styled.UsageNote>
															)}
														</>
													</FieldWrapper>
												)}
											</Field>
										</Styled.QuantityWrapper>
										<Styled.PricePerUnitWrapper>
											<Field
												name={`${name}.pricePerOne`}
												validate={composeValidators(requiredFieldValidator)}
											>
												{({ input, meta }) => (
													<FieldWrapper
														name={`${name}.pricePerOne`}
														label={unitLabel}
														required
														errorMessage={meta.submitFailed && meta.touched && meta.error}
													>
														<Input
															{...input}
															type={Fields.NUMBER}
															placeholder='5'
															autoComplete='off'
															disabled={true}
														/>
													</FieldWrapper>
												)}
											</Field>
										</Styled.PricePerUnitWrapper>
										<Field name={`${name}.count`} parse={(value) => Number(value)} defaultValue={0}>
											{({ input: interviewCount }) => (
												<Field
													name={`${name}.pricePerOne`}
													parse={(value) => Number(value)}
													defaultValue={50}
												>
													{({ input: pricePerOne }) => {
														const totalPrice =
															(interviewCount.value || 0) * (pricePerOne.value || 0);

														return (
															<Styled.Price>
																{currency}
																{totalPrice.toFixed(2)}
															</Styled.Price>
														);
													}}
												</Field>
											)}
										</Field>

										{index > 0 && (
											<Styled.ButtonIcon
												buttonType={ButtonTypes.default}
												icon={<DeleteIcon fill={COLORS.black} width={'16'} height={'16'} />}
												onClick={() => fields.remove(index)}
											/>
										)}
									</Styled.CreditsFieldWrapper>
								))
							}
						</FieldArray>
						{addMonthButtonEnabled && (
							<ThemedButton
								type='button'
								onClick={() =>
									form.mutators.push('unitForMonths', { ...initialValues, yearMonth: '' })
								}
								disabled={(form.getFieldState('unitForMonths')?.length as number) >= 12}
								buttonType={ButtonTypes.secondary}
							>
								Add Month
							</ThemedButton>
						)}

						<Styled.Line />
						<FormSpy subscription={{ values: true }}>
							{({ values }) => {
								const total = values.unitForMonths.reduce((acc, curr) => {
									const quantity = curr.count || 0;
									const pricePerOne = curr.pricePerOne || 0;

									return acc + quantity * pricePerOne;
								}, 0);
								const vat = total * (chargeVatPercent || 0);

								return (
									<>
										{chargeVat && (
											<>
												<Styled.TotalPriceSmallSize>
													<span>Total Ex VAT:</span>
													<span>
														{currency}
														{total?.toFixed(2)}
													</span>
												</Styled.TotalPriceSmallSize>
												<Styled.TotalPriceSmallSize>
													<span>VAT:</span>
													<span>
														{currency}
														{vat?.toFixed(2)}
													</span>
												</Styled.TotalPriceSmallSize>
												<Styled.Line />
											</>
										)}
										<Styled.TotalPrice>
											<span>Total:</span>
											<span>
												{currency}
												{chargeVat ? (total + vat).toFixed(2) : total.toFixed(2)}
											</span>
										</Styled.TotalPrice>
									</>
								);
							}}
						</FormSpy>
					</FormBlock>
					<Styled.FooterWrap>
						{!isImpersonate && (
							<ThemedButton
								type='submit'
								buttonType={ButtonTypes.primary}
								loading={false}
								disabled={!!submitButtonLoading}
							>
								Go to Checkout
							</ThemedButton>
						)}
					</Styled.FooterWrap>
				</form>
			)}
		/>
	);
};

export default memo(BuyCreditsForm);
